import styled from 'styled-components/macro'
import { Button } from '../../../../../../shared/components/button/Button'

export const PasswordInstructionsContainer = styled.div`
  margin-bottom: 25px;
`

export const PasswordInstructionsTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: #000000;
  margin-bottom: 10px;
`

export const PasswordInstructions = styled.ul`
  margin: 0;
  padding: 0 15px 0 0;
`

export const PasswordInstructionItem = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: #757575;
`

export const ButtonWrapper = styled(Button)`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: #007638;
  color: #fff;
  padding: 20px 0;
  border: none;
  cursor: pointer;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
  }
`

export const BackButtonWrapper = styled(Button)`
  font-family: 'Atlas-AAA', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #000000;
  padding: 20px 0;
  border: none;
  width: 100%;
  background: none;
  cursor: pointer;
`

export const UserExistsError = styled.div`
  display: none;
  color: #ff0000;

  &.error {
    margin-bottom: 10px;
    display: block;
  }
`
