import profileIcon from 'assets/icons/logged-in-profile-green.svg'
import greenCircle from 'assets/icons/green-circle.svg'
import logoutIcon from 'assets/icons/logout.svg'
import { Button, FlexColumn, FlexRow, Image, Text } from 'styles/generics'
import styled from 'styled-components/macro'
import { useAppSelector } from 'store/store'
import { theme } from 'styles/theme'
import { MouseEvent } from 'react'
import { ImageWrapper } from 'shared/components/ImageWrapper'
import { useAppLogout } from 'shared/helpers/useAppLogout'

const LogoutPopoverContent = () => {
  const logout = useAppLogout()

  const userFullName = useAppSelector(
    (state) =>
      state.user.personalDetails?.firstName +
      ' ' +
      state.user.personalDetails?.lastName,
  )

  const onLogout = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation()
    ev.preventDefault()
    logout()
  }

  return (
    <FlexColumn>
      <UserNameRow width="138px" gap={10}>
        <UserIconContainer>
          <ProfileIconContainer>
            <ProfileIcon src={profileIcon} alt="user" />
          </ProfileIconContainer>
          <CircleIcon src={greenCircle} alt="green circle" />
        </UserIconContainer>
        <Text weight={500} color={theme.black} fontSize="16px">
          {userFullName}
        </Text>
      </UserNameRow>
      <LogoutRow onClick={onLogout}>
        <ImageWrapper src={logoutIcon} alt="logout" width="20px" />
        <Text fontSize="14px" weight={400}>
          התנתק
        </Text>
      </LogoutRow>
    </FlexColumn>
  )
}

const UserNameRow = styled(FlexRow)`
  border-bottom: 1px solid ${theme.grayBorder};
  padding-bottom: 5px;
`

const UserIconContainer = styled.div`
  position: relative;
  width: 27px;
`

const ProfileIconContainer = styled.div`
  position: absolute;
  width: 18px;
  top: 57%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const ProfileIcon = styled(Image)`
  width: 100%;
`

const CircleIcon = styled(Image)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  position: absolute;
`

const LogoutRow = styled(Button)`
  gap: 17px;
  justify-content: flex-start;
  margin-top: 14px;
  height: 30px;
  align-items: center;
  padding-inline-start: 4px;
  cursor: pointer;
  border-radius: 3px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: ${theme.lightBlack};
  }
`

export { LogoutPopoverContent }
