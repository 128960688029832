import { FunctionComponent } from 'react'
import whitePlusSvg from 'assets/icons/white-plus.svg'
import { Image } from 'styles/generics'
import styled from 'styled-components/macro'

const PlusWhiteIcon: FunctionComponent = () => {
  return (
    <PlusIconContainer>
      <Image src={whitePlusSvg} alt="add relation" />
    </PlusIconContainer>
  )
}

const PlusIconContainer = styled.div`
  width: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-end: 8px;
`

export { PlusWhiteIcon }
