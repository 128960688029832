import { FunctionComponent } from 'react'
import {
  MobileHeroContainer,
  UserDetailsFormBg,
} from 'pages/user-details/style'

interface Props {
  title: string
  imgUrl?: string
  textAlign?: string
  fontSize?: string
}

const MobileHero: FunctionComponent<Props> = ({
  title,
  imgUrl,
  textAlign,
  fontSize,
}) => {
  return (
    <MobileHeroContainer textAlign={textAlign} fontSize={fontSize}>
      <UserDetailsFormBg imgUrl={imgUrl} />
      <div className="bg-title">{title}</div>
    </MobileHeroContainer>
  )
}

export { MobileHero }
