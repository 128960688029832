import { IChildFan } from './IChildFan'
import { IFriend } from './IFriend'
import { IGreenplusData } from './IGreenplusData'
import { IMembershipData } from './IMembershipData'
import { IPersonalDetails } from './IPersonalDetails'

export interface IUser {
  isAuthenticated: boolean
  email?: string
  password?: string
  terms?: boolean
  newsletter?: boolean
  personalDetails?: IPersonalDetails
  token?: string
  registeredCognito?: boolean
  creditAmount?: number | null

  // Extra Data
  memberships?: IMembershipData[]
  greenpluses?: IGreenplusData[]
  children?: IChildFan[]
  friends?: IFriend[]
}

export enum IUserFrom {
  SIGN_UP = 'SIGN_UP',
  LOGIN = 'LOGIN',
}
