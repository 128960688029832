import { FunctionComponent, useEffect, useState } from 'react'
import { MobileHero } from 'shared/components/mobile-hero/MobileHero'
import { showFooter, showNavbar } from 'store/reducers/layoutReducer'
import { useAppDispatch } from 'store/store'
import styled from 'styled-components/macro'
import { device } from 'constants/media-query'
import profileDesktopBg from 'assets/images/desktop_welcome_bg.png'
import { Screen } from 'shared/components/screen/Screen'
import { Button, FlexColumn, FlexRow } from 'styles/generics'
import { HorizontalLine } from 'shared/components/horizontal-line/HorizontalLine'
import { BackButtonLink } from 'pages/user-details/style'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { theme } from 'styles/theme'
import { ShopifyOrders } from './ShopifyOrders'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import { Tickets } from './Tickets'
import { MembershipsAndGreenpluses } from './MembershipsAndGreenpluses'
import greenplusMobileBg from 'assets/images/greenplus-mobile-bg.png'
import { WrongInfoTooltip } from './WrongInfoTooltip'

const OrderHistory: FunctionComponent = () => {
  const dispatch = useAppDispatch()
  const [tabIndex, setTabIndex] = useState(0)
  const isMobile = useIsMobile()

  useEffect(() => {
    dispatch(showNavbar(true))
    dispatch(showFooter(true))

    return () => {
      dispatch(showNavbar(false))
      dispatch(showFooter(false))
    }
  }, [])

  return (
    <PageMain>
      <Screen />
      <MobileHero
        textAlign={isMobile ? 'center' : 'right'}
        title="היסטוריית הרכישות שלי"
        fontSize="30px"
      />
      <OrderHistoryContainer align="center">
        <TabsContainer>
          <OrderHistoryTab
            onClick={() => setTabIndex(0)}
            isSelected={tabIndex === 0}
          >
            {'החנות הרשמית'}
          </OrderHistoryTab>
          <OrderHistoryTab
            onClick={() => setTabIndex(1)}
            isSelected={tabIndex === 1}
          >
            {'כרטיסים'}
          </OrderHistoryTab>
          <OrderHistoryTab
            onClick={() => setTabIndex(2)}
            isSelected={tabIndex === 2}
          >
            {'מנויים / גרין+'}
          </OrderHistoryTab>
        </TabsContainer>
        <HorizontalLine />
        {tabIndex === 0 && <ShopifyOrders />}
        {tabIndex === 1 && <Tickets />}
        {tabIndex === 2 && <MembershipsAndGreenpluses />}
        <BackButtonLink mt="auto" to={EAppRoutes.PERSONAL_AREA}>
          חזרה לאזור האישי
        </BackButtonLink>
        <WrongInfoTooltip />
      </OrderHistoryContainer>
    </PageMain>
  )
}

const PageMain = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  direction: rtl;
  z-index: 10;
  position: relative;
  font-family: Atlas AAA;
  padding-bottom: 35px;
  background-color: ${({ theme }) => theme.white};
  background-attachment: fixed;
  background-image: url(${greenplusMobileBg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  position: relative;
  min-height: calc(100vh - 80px);

  @media ${device.desktop} {
    background-attachment: unset;
    background: url(${profileDesktopBg}) no-repeat center center fixed;
    background-size: cover;
    padding: 0;
    padding-top: 50px;
    min-height: calc(100vh - 10px);
  }
`

const OrderHistoryContainer = styled(FlexColumn)`
  width: 100%;
  background-color: ${({ theme }) => theme.opacityWhite};
  min-height: 300px;
  font-size: 16px;
  backdrop-filter: blur(10px);
  padding: 10px;
  margin-bottom: 30px;

  @media ${device.desktop} {
    max-width: 720px;
    font-size: 20px;
    border-radius: 10px;
    padding: 0 25px 18px;
    margin-bottom: 50px;
  }
`

const TabsContainer = styled(FlexRow)`
  min-height: 62px;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.025em;
  gap: 20px;

  @media ${device.sm} {
    gap: 50px;
  }

  @media ${device.desktop} {
    gap: 80px;
  }
`

const OrderHistoryTab = styled(Button)<{ isSelected: boolean }>`
  transition: border 0.3s ease-in-out;
  border-bottom: 3px solid transparent;
  font-family: 'Atlas-AAA-Regular';
  font-size: 20px;
  color: ${theme.white};
  ${({ isSelected, theme }) =>
    isSelected &&
    `weight: 500; border-bottom: 3px solid ${theme.white}; font-family: 'Atlas-AAA-Medium';`}
  @media ${device.desktop} {
    font-size: 24px;
  }
`

export { OrderHistory }
