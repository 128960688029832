import React, { useEffect, useRef } from 'react'
import { IInputProps } from '../../../types/interfaces/IInputProps'
import {
  EmailInputContainer,
  Input,
  Label,
  HeightPlaceHolderForError,
} from './style'
import { Error } from '../error/Error'
import { SignupTexts } from '../../../constants/signup-texts'

export const EmailConfirmInput: React.FC<IInputProps> = ({
  callback,
  hasError,
  errorText,
  className,
}) => {
  const regex = RegExp(/^[0-9]{4,9}$/i)

  const handleChange = (e: any) => {
    e.preventDefault()
    const error = !regex.test(e.target.value)
    callback(e.target.value, error)
  }
  const quantityInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const ignoreScroll = (e: Event) => {
      e.preventDefault()
    }
    const currentRef = quantityInputRef.current
    if (currentRef) {
      currentRef.addEventListener('wheel', ignoreScroll)
    }
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('wheel', ignoreScroll)
      }
    }
  }, [])

  return (
    <EmailInputContainer
      className={`email ${className ?? ''} ${hasError ? 'error' : ''}`}
    >
      <Label htmlFor="emailConfirm">{SignupTexts.VerificationCode}</Label>
      <Input
        ref={quantityInputRef}
        type="number"
        name="emailConfirm"
        onChange={(e: any) => handleChange(e)}
      ></Input>
      <HeightPlaceHolderForError>
        <Error hasError={hasError} errorText={errorText}></Error>
      </HeightPlaceHolderForError>
    </EmailInputContainer>
  )
}
