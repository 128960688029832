import React from 'react'
import { RadioButtonContainer, Input, Label } from './style'
import { IRadioButtonProps } from '../../../types/interfaces/IRadioButtonProps'

export const RadioButton: React.FC<IRadioButtonProps> = ({
  callback,
  hasError,
  id,
  label,
  className,
  value,
  isSelected,
  separator,
}) => {
  return (
    <RadioButtonContainer
      className={`radioButton ${className ?? ''} ${hasError ? 'error' : ''} ${
        isSelected ? 'selected' : ''
      }`}
    >
      <Label htmlFor={id}>
        <Input
          type="radio"
          name={id}
          onChange={callback}
          value={value}
          checked={isSelected}
        ></Input>
        {label}
      </Label>
      {separator && <span style={{ marginRight: '10px' }}>{separator}</span>}
    </RadioButtonContainer>
  )
}

export default RadioButton
