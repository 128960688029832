import React from 'react'
import { ICheckboxProps } from '../../../types/interfaces/ICheckboxProps'
import { Error } from '../error/Error'
import { CheckboxContainer, Input, Label } from './style'
import { HeightPlaceHolderForError } from '../email-confirm-input/style'

export const Checkbox: React.FC<ICheckboxProps> = ({
  hasError,
  errorText,
  callback,
  id,
  label,
  linkLabel,
  href,
  checked,
  className,
}) => {
  return (
    <>
      <CheckboxContainer
        className={`checkbox ${className ?? ''} ${hasError ? 'error' : ''}`}
      >
        <Input
          id={id}
          type="checkbox"
          checked={checked}
          onChange={(e: any) => callback(e)}
          className={checked ? 'checked' : ''}
        ></Input>
        <Label htmlFor={id}>
          {label}{' '}
          {linkLabel && (
            <a target="_blank" href={href} rel="noreferrer">
              {linkLabel}
            </a>
          )}
        </Label>
        <HeightPlaceHolderForError>
          <Error hasError={hasError} errorText={errorText}></Error>
        </HeightPlaceHolderForError>
      </CheckboxContainer>
    </>
  )
}
export default Checkbox
