import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'
import { EAppRoutes } from 'types/enums/EAppRoutes'

interface Props {
  linkTo: EAppRoutes
  text: string
  mt?: string
}

const WhiteLink: FunctionComponent<Props> = ({ linkTo, text, mt }) => {
  return (
    <CustomWhiteLink to={linkTo} mt={mt}>
      {text}
    </CustomWhiteLink>
  )
}

const CustomWhiteLink = styled(Link)<{ mt?: string }>`
  ${({ mt }) => mt && `margin-top: ${mt};`}
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.1);
  height: 48px;
  width: 176px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: #000 !important;
  font-family: ${EAppFontFamilies.ATLAS_500};
`
export { WhiteLink }
