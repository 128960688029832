import { Auth } from 'aws-amplify'
import { IUser } from '../types/interfaces/IUser'
import { CognitoIdentityServiceProvider } from 'aws-sdk'
import { AUTH_USER_TOKEN_KEY } from '../constants/auth'
import { cookieService } from './cookie.service'

export const signUp = async (
  email: string,
  password: string,
): Promise<IUser | null | { error: string }> => {
  try {
    await Auth.signUp({
      username: email,
      password,
      autoSignIn: {
        enabled: true,
      },
    })
    return {
      isAuthenticated: false,
      email: email,
    }
  } catch (err: any) {
    console.log('error signing up:', err)
    const error = err as Error
    if (
      error.message.startsWith(
        'An account with the given email already exists.',
      )
    ) {
      return {
        error: 'UserNameExists',
      }
    }
    return null
  }
}

export const resendConfirmSignUp = async (email: string): Promise<void> => {
  try {
    await Auth.resendSignUp(email)
  } catch (err) {
    console.log('error resending code: ', err)
  }
}

export const confirmSignUp = async (
  email: string,
  code: string,
): Promise<boolean> => {
  try {
    await Auth.confirmSignUp(email, code)
    return true
  } catch (error) {
    console.log('error confirming sign up', error)
    return false
  }
}

export const signIn = async (
  email: string,
  password: string,
  shiftLetterCaseTry: boolean = false,
): Promise<IUser | null | { error: string }> => {
  try {
    const signedUser = await Auth.signIn({
      username: email,
      password,
    })
    const user: IUser = {
      isAuthenticated: true,
      email: signedUser.attributes.email,
      token: signedUser.signInUserSession.accessToken.jwtToken,
    }
    return user
  } catch (err) {
    const error = err as Error
    if (error.message.startsWith('User is not confirmed')) {
      return {
        error: 'UserNotConfirmed',
      }
    }
    if (!shiftLetterCaseTry) {
      const capitalizedEmail = `${
        isLowerCase(email[0])
          ? email.charAt(0).toUpperCase()
          : email.charAt(0).toLowerCase()
      }${email.slice(1)}`
      return await signIn(capitalizedEmail, password, true)
    }
    console.log('error signing in: ', err)
    return null
  }
}

const isLowerCase = (letter: string) => {
  if (letter.toLowerCase() === letter && letter !== letter.toUpperCase()) {
    return true
  }
  return false
}

export const resetPassword = (email: string): void => {
  try {
    Auth.forgotPassword(email)
  } catch (error) {
    console.log('error reset password: ', error)
  }
}

export const resetPasswordSubmit = async (
  email: string,
  code: string,
  password: string,
): Promise<boolean> => {
  try {
    await Auth.forgotPasswordSubmit(email, code, password)
    return true
  } catch (error: any) {
    console.log('error forgot password submit: ', error)
    return false
  }
}

export const getCurrentAuthenticatedUserInfo =
  async (): Promise<IUser | null> => {
    try {
      const userInfo = await Auth.currentAuthenticatedUser()
      const user: IUser = {
        isAuthenticated: true,
        email: userInfo.attributes.email.toLowerCase(),
        token: userInfo.signInUserSession.accessToken.jwtToken,
      }
      return user
    } catch (error) {
      console.log('error getting info: ', error)
      return null
    }
  }

export const isUserAuthenticated = async (): Promise<boolean> => {
  const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
    region: process.env.REACT_APP_AWS_REGION,
  })
  const accessToken =
    cookieService.getCookie(AUTH_USER_TOKEN_KEY) ||
    localStorage.getItem(AUTH_USER_TOKEN_KEY)
  try {
    if (!accessToken) {
      return false
    }
    const user = await cognitoidentityserviceprovider
      .getUser({ AccessToken: accessToken })
      .promise()
    if (user) {
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export const logout = async (isCloseWindowOpener: boolean = true) => {
  cookieService.deleteCookie(AUTH_USER_TOKEN_KEY)

  const accessToken = localStorage.getItem(AUTH_USER_TOKEN_KEY)

  if (accessToken) {
    const cognitoidentityserviceprovider = new CognitoIdentityServiceProvider({
      region: process.env.REACT_APP_AWS_REGION,
    })

    try {
      await cognitoidentityserviceprovider
        .globalSignOut({ AccessToken: accessToken })
        .promise()
    } catch (error) {
      console.error('Error invalidating token: ', error)
    }
  }

  try {
    await Auth.signOut()
  } catch (error) {
    console.error('Error signing out: ', error)
  }

  localStorage.removeItem(AUTH_USER_TOKEN_KEY)

  if (window?.opener && isCloseWindowOpener) window.close()
}
