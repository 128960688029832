import { FunctionComponent } from 'react'
import { FlexColumn } from 'styles/generics'
import { CardLink } from './CardLink'
import { personalAreaTexts } from 'constants/personal-area-texts'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import styled, { CSSObject } from 'styled-components/macro'
import fanImg from 'assets/images/fan.png'
import samiOferImg from 'assets/images/sami-ofer.png'
import fanMobileImg from 'assets/images/fan-mobile.png'
import samiOferMobileImg from 'assets/images/sami-ofer-mobile.png'
import { device } from 'constants/media-query'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import { WrongInfoTooltip } from 'pages/order-history/WrongInfoTooltip'

const CardLinks: FunctionComponent = () => {
  const isMobile = useIsMobile()

  const customTooltipStyle: CSSObject = {
    right: 0,
    bottom: '12px',
    marginTop: '10px',
    marginRight: '5px',
  }

  return (
    <CardsContainer align="center" width="100%">
      <CardLink
        title={personalAreaTexts.cardLinks.profileCard.title}
        description={personalAreaTexts.cardLinks.profileCard.description}
        btnText={personalAreaTexts.cardLinks.profileCard.btnText}
        linkTo={EAppRoutes.PROFILE}
        bgImg={isMobile ? fanMobileImg : fanImg}
        mobileBgSize="cover"
      />
      <CardLink
        title={personalAreaTexts.cardLinks.purchasesCard.title}
        description={personalAreaTexts.cardLinks.purchasesCard.description}
        btnText={personalAreaTexts.cardLinks.purchasesCard.btnText}
        linkTo={EAppRoutes.ORDER_HISTORY}
        bgImg={isMobile ? samiOferMobileImg : samiOferImg}
      />
      <WrongInfoTooltip customStyle={customTooltipStyle} />
    </CardsContainer>
  )
}

const CardsContainer = styled(FlexColumn)`
  padding-bottom: 45px;
  gap: 45px;
  position: relative;

  @media ${device.large} {
    gap: 25px;
    flex-direction: row;
    justify-content: space-between;
  }
`

export { CardLinks }
