import { device } from 'constants/media-query'
import { FunctionComponent } from 'react'
import styled from 'styled-components/macro'
import { FlexColumn, Heading2 } from 'styles/generics'
import { theme } from 'styles/theme'

interface Props {
  title: string
  children: React.ReactNode
}

const SectionContainer: FunctionComponent<Props> = ({ title, children }) => {
  return (
    <MainContainer>
      <SectionTitle color={theme.white}>{title}</SectionTitle>
      <BgContainer>{children}</BgContainer>
    </MainContainer>
  )
}

const MainContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 45px;
  color: ${({ theme }) => theme.white};
`

const SectionTitle = styled(Heading2)`
  font-size: 24px;
  align-self: flex-start;
  margin-bottom: 15px;
  font-family: 'Atlas-AAA-medium';
  padding-inline-start: 5px;

  @media ${device.desktop} {
    padding-inline-start: 30px;
    font-size: 32px;
  }
`

const BgContainer = styled(FlexColumn)`
  background: rgba(255, 255, 255, 0.4);
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 15px 8px;

  @media ${device.desktop} {
    padding: 15px 42px;
  }
`

export { SectionContainer }
