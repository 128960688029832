import styled from 'styled-components/macro'
import { Button } from '../../../../../../../../shared/components/button/Button'
import { theme } from 'styles/theme'

export const SubmitButtonWrapper = styled(Button)`
  font-family: 'Atlas-AAA', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: ${theme.mhGreen};
  color: #fff;
  padding: 20px 0;
  border: none;
  width: 100%;
  cursor: pointer;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
  }
`

export const BackButtonWrapper = styled(Button)`
  font-family: 'Atlas-AAA', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #000000;
  padding: 20px 0;
  border: none;
  width: 100%;
  background: none;
  cursor: pointer;
`
