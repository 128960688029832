import styled from 'styled-components/macro'
import { Button } from '../../../../shared/components/button/Button'
import { PasswordInput } from '../../../../shared/components/password-input/PasswordInput'

export const LoginFormBody = styled.form``

export const PasswordInputWrapper = styled(PasswordInput)`
  margin-bottom: 5px;
`

export const ForgotPasswordButton = styled(Button)`
  background: none;
  border: none;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #757575;
  font-family: 'Atlas-AAA', sans-serif;
  padding: 0;
  padding-bottom: 70px;
  padding-top: 5px;
  cursor: pointer;

  &.no-margin {
    margin-bottom: 0px;
  }
`

export const LoginErrorContainer = styled.div`
  margin: 30px 0 40px;
  padding: 10px;
  color: #ff0000;
  border: 1px solid #ff0000;
`

export const LoginErrorText = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.025em;
  color: #ff0000;
`

export const ButtonWrapper = styled(Button)`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: #007638;
  color: #fff;
  padding: 20px 0;
  border: none;
  margin-bottom: 40px;
  cursor: pointer;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
    cursor: default;
  }
`

export const LoginFormFooter = styled.div`
  width: 100%;
  text-align: center;
  font-family: 'Atlas-AAA', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
`

export const SignupLinkButton = styled.span`
  line-height: 30px;
  a {
    display: flex;
    flex-flow: column;
    margin-right: 3px;
    color: #007638;
    text-decoration: underline;
    line-height: 40px;
  }
`
