export const SignupTexts = {
  RegisterToStore: 'הרשמה למועדון',
  EmailConfirmationMessage:
    'לאחר הזנת כתובת הדוא״ל נעביר אותך לעמוד יצירת סיסמה אישית ',
  CreatePassword: 'צור סיסמה',
  PasswordIsUniqueMessage:
    'הסיסמה הינה אישית ותשמש אותך בהתחברויות הבאות שלך לאתר',
  SignupFormPasswordInstructionsTitle: 'כללים לבחירת סיסמה',
  SignupFormPasswordInstructionFirstRule: 'לפחות 8 תווים',
  SignupFormPasswordInstructionSecondRule: 'לפחות אות גדולה אחת',
  SignupFormPasswordInstructionFifthRule: 'לפחות אות קטנה אחת',
  SignupFormPasswordInstructionThirdRule: 'לפחות ספרה אחת',
  SignupFormPasswordInstructionFourthRule: 'לפחות סימן אחד (!@*)',
  SignupFormConfirmAllDetailsAreValid: 'יש לוודא שכל הפרטים נכונים',
  ReadAndConfirmedAllDetails: 'קראתי ומאשר/ת שכל הפרטים נכונים',
  FirstName: 'שם פרטי',
  LastName: 'שם משפחה',
  City: 'עיר',
  PostalCode: 'מיקוד',
  Street: 'רחוב',
  NoStreet: 'ללא רחוב',
  BuildingNumber: 'מס׳ בית',
  PersonalDetails: 'פרטים אישיים',
  GreatFewMoreSteps: 'מעולה! אתם ממש קרובים רק עוד כמה פרטים קטנים',
  TellUsAboutYourself: 'ספרו לנו קצת על עצמכם.',
  ToChangeDetailsContactCustomerService:
    'לשינוי הפרטים יש ליצור קשר עם שירות הלקוחות',
  Next: 'הבא',
  Back: 'חזרה',
  Previous: 'הקודם',
  Join: 'הצטרפות למערכת החדשה',
  JoinTheClub: 'הצטרפו למועדון',
  YayLastPageAndYouAreConnect: 'איזה כיף! עמוד אחרון ואתם מחוברים',
  YayAnotherStep: 'איזה כיף! התקדמנו עוד שלב',
  NoticeToFillAccurateDetails:
    'שימו לב למלא את כל הפרטים העדכניים כדי שנוכל לספק לכם חווית אוהד מדהימה',
  EmailVerification: 'אימות דוא״ל',
  SendAgain: 'שלחו שוב',
  ChangeEmail: 'שנה דוא״ל',
  DidNotReceive: 'לא קיבלתם?',
  ClientNumber: 'מס׳ לקוח',
  PleaseEnterYourClientNumberInCaseYouHave:
    'אנא הזינו את מספר הלקוח שברשותכם, במידה ויש לכם.',
  EnterNumbersOnlyNoPrefix: 'יש להזין מספרים בלבד (ללא קידומת MHF).',
  DontHaveOrDontRememberNoWorries: 'אין לכם או לא זוכרים? לא נורא.',
  LetsKeepGoing: 'בואו נמשיך',
  NotActive: 'לא פעיל',
  WeCouldNotFindWhatYouHaveBeenLookingFor: 'לא מצאנו את מה שחיפשת',
  CellphoneNumber: 'מס׳ נייד',
  CellphoneNumberExample: "(לדוג': 0501234567)",
  VerificationCode: 'קוד אימות',
  DateOfBirth: 'תאריך לידה',
  DateOfBirthComment: ' (ההרשמה מגיל 16 ומעלה)',
  Welcome: 'ברוכים הבאים',
  ForgotPasswordPleaseEnterEmailMessage:
    'שכחתם סיסמה? הזינו כתובת דואר אלקטרוני וקבלו קישור לאיפוס הסיסמה',
  ForgotPassword: 'שכחת סיסמה?',
  IReadAndConfirmThe: 'קראתי ומאשר את ',
  TermsOfAgreement: 'תנאי השימוש',
  ImInterestedInReceivingMarketingMails: 'אני מעוניין לקבל דואר שיווקי',
  LogIn: 'התחבר',
  HaventRegisteredYet: 'עוד לא נרשמת למערכת החדשה?',
  ToLogInPleaseEnterEmailAddress: 'להתחברות יש להזין כתובת דואר אלקטרוני',
  ToLogIn: 'להתחברות',
  Send: 'שלח/י',
  ToPostMailWebsite: '(לאתר הדואר)',
  Email: 'דוא”ל',
  Password: 'סיסמה',
  EnterPasswordOneMoreTime: 'הזן/י סיסמה פעם נוספת',
  YayNewWebsiteMounted: 'האתר החדש עלה לאוויר!',
  InOrderToGiveTheBestExperiencePleaseFillCertainDetails:
    'על-מנת שנוכל לספק לכם את החוויה הטובה ביותר, עליכם למלא בקצרה כמה פרטים.',
  ShallWeBegin: 'שנתחיל?',
  ToRegister: 'להרשמה',
  MisparZehutNumber: 'מס׳ תעודת זהות',
  Save: 'שמור',
  Gender: 'מגדר',
  MaleGenderOption: 'ז',
  FemaleGenderOption: 'נ',
  Other: 'אחר',
  GenderSeparator: '/',
  PersonalDetailsModification: 'עריכת פרטים אישיים',
  WelcomeToWinningFamily: 'ברוכים הבאים למשפחה מנצחת!',
  ToTheOfficialStore: 'לכניסה',
  ToTheOfficialWebsite: 'לאתר הרשמי',
  InAppBrowserModal: {
    Title: 'ה-VAR זיהה בעיה',
    Description1: 'שמנו לב כי אתם גולשים בתוך אפליקציה Meta.',
    Description2:
      'לחוויה הטובה ביותר, יש להתחבר באמצעות העתקת את הקישור לדפדפן האישי שלכם (Chrome, Safari).',
    Tickets: 'אתר הכרטיסים',
    Shop: 'חנות',
    OfficialWebsite: 'אתר הרשמי',
  },
  AlreadyAUser: 'כבר חלק מהמשפחה?',
  MhfcIdToolTip: 'מופיע כמס׳ לקוח על גבי כרטיס משחק',
  Exit: 'יציאה',
}

export const ErrorTexts = {
  PhoneNumberIsNotValid: 'מספר נייד לא תקין',
  IdIsAlreadyInSystem: 'תעודת הזהות כבר קיימת במערכת',
  FirstNameIsNotValid: 'שם פרטי לא תקין',
  LastNameIsNotValid: 'שם משפחה לא תקין',
  MisparZehutIsNotValid: 'תעודת זהות לא תקינה',
  MustChooseCityFromTheGivenList: 'חובה לבחור עיר מהרשימה',
  MustChooseStreetFromTheGivenList: 'חובה לבחור רחוב מהרשימה',
  PostalCodeIsNotValid: 'מיקוד לא תקין',
  MustApproveAllDetails: 'חובה לאשר את הפרטים',
  CodeIsNotValid: 'קוד לא תקין',
  EmailIsNotValid: 'אימייל לא תקין',
  MustAcceptTermsOfAgreement: 'חייב לסמן תנאי שימוש',
  PasswordIsNotValid: 'סיסמה לא תקינה',
  DetailsAreIncorrectOrNotInSystemPleaseCreateANewUser:
    'הפרטים שהזנת אינם נכונים או שלא נמצאו במערכת. במידה ועוד לא הצטרפת למערכת החדשה, אנא צור משתמש חדש',
  PasswordIsNotMatch: 'סיסמה לא תואמת',
  EmailIsAlreadyInSystem: 'המייל כבר קיים במערכת',
  NotFoundMessage: "There's nothing here: 404!",
  MustChooseDateOfBirth: 'חובה לבחור תאריך לידה',
  doNotHaveToptixId: 'אין ברשותי את מספר הלקוח',
  toptixIdConflictMsg: 'ישנה אי התאמה במספר הלקוח',
}

export const pendingPageTitle = (firstName = 'אוהד/ת', lastName = 'יקר/ה') => {
  return `שלום ${firstName} ${lastName},`
}

export const successPageTitle = (firstName = 'אוהד/ת') => {
  return `שלום ${firstName},`
}

export const comingSoonPageTitle = () => {
  return `בקרוב! 

  האיזור האישי החדש של מכבי חיפה!`
}

export const pendingPageTextMobile = () => {
  return `פרטי המשתמש שלך הועברו למועדון לצורך אימות החשבון והשלמת תהליך ההרשמה.
  לכשיסתיים התהליך או במידה ויתגלו בעיות אנו ניצור איתך קשר, במייל או בטלפון, על מנת לאפשר את שימושך במערכות המועדון החדשות.


  תודה על הסבלנות ונשתמע בקרוב,

  maccabi connect.`
}

export const pendingPageTextDesktop = () => {
  return `פרטי המשתמש שלך הועברו למועדון לצורך אימות החשבון והשלמת תהליך ההרשמה.

  לכשיסתיים התהליך או במידה ויתגלו בעיות אנו ניצור איתך קשר, במייל או בטלפון, על מנת לאפשר את שימושך במערכות המועדון החדשות.


  תודה על הסבלנות ונשתמע בקרוב,

  maccabi connect.`
}
export const successPageTextMobile = (gender: string | undefined) => {
  const welcomeText = gender === 'Female' ? 'ברוכה הבאה' : 'ברוך הבא'
  const renewText = gender === 'Female' ? 'תתחדשי' : 'תתחדש'

  return `${welcomeText} למשפחה!

  ${renewText}! מעתה משתמש זה ישמש אותך לכלל המערכות הטכנולוגיות החדשות של המועדון.

אנו מזמינים אותך להתרשם ואנחנו כאן בשבילך לכל בעיה.

נתראה במגרש, מכבי קונקט`
}
export const comingPageTextMobile = () => {
  return `
  האיזור האישי יעלה בזמן הקרוב, 
  בו תוכלו לראות את כל המידע האישי שלכם, מרוכז במקום אחד!

השארו מעודכנים!`
}

export const successPageTextDesktop = (gender: string | undefined) => {
  const welcomeText = gender === 'Female' ? 'ברוכה הבאה' : 'ברוך הבא'
  const renewText = gender === 'Female' ? 'תתחדשי' : 'תתחדש'

  return `${welcomeText} למשפחה!

  ${renewText}! מעתה משתמש זה ישמש אותך לכלל המערכות הטכנולוגיות החדשות של המועדון.

אנו מזמינים אותך להתרשם ואנחנו כאן בשבילך לכל בעיה.

נתראה במגרש, מכבי קונקט`
}

export const comingPageTextDesktop = () => {
  return `
  האיזור האישי יעלה בזמן הקרוב, בו תוכלו לראות את כל המידע האישי שלכם, מרוכז במקום אחד!

השארו מעודכנים!`
}

export const getEmailVerificationMessage = (email: string) => {
  return `${email}`
}

export const getMHFCIdMessage = (mhfcId: string | undefined): string => {
  return `מספר הלקוח שלך הוא ${mhfcId ?? ''}`
}

export const getWelcomeUserMessage = (
  firstName: string | undefined,
): string => {
  return `היי ${firstName ?? ''}`
}

export const getWelcomeToWinningFamilyMessage = (
  gender: 'Male' | 'Female' | 'Other' | undefined,
): string => {
  switch (gender) {
    case 'Female':
      return 'ברוכה הבאה למשפחה מנצחת!'
    case 'Male':
      return 'ברוך הבא למשפחה מנצחת!'
    default:
      return 'ברוכים הבאים למשפחה מנצחת!'
  }
}
