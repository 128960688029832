import React, { useState } from 'react'
import { IInputProps } from '../../../types/interfaces/IInputProps'
import { DatePickerContainer, Label } from './style'
import { Error } from '../error/Error'
import { DatePicker } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import locale from 'antd/es/date-picker/locale/he_IL'
import { SignupTexts } from '../../../constants/signup-texts'
import calendarSVg from '../../../assets/icons/calendar.svg'
import { Span } from 'styles/generics'
import { HeightPlaceHolderForError } from '../email-confirm-input/style'

dayjs.extend(utc)
locale.lang.shortWeekDays = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ש']
locale.lang.shortMonths = [
  'ינואר',
  'פברואר',
  'מרץ',
  'אפריל',
  'מאי',
  'יוני',
  'יולי',
  'אוגוסט',
  'ספטמבר',
  'אוקטובר',
  'נובמבר',
  'דצמבר',
]

export const DatePickerWrapper: React.FC<IInputProps> = ({
  callback,
  hasError,
  errorText,
  className,
  value,
  disabled,
  disabledOpacityStyle,
  color,
  bgColor,
  boxShadow,
  isCancelMb,
}) => {
  const checkAge = (date: dayjs.Dayjs) => dayjs().diff(date, 'year') >= 16

  // Set the initial date if valid, otherwise null
  const initialDate =
    value && checkAge(dayjs(value, 'YYYY-MM-DD'))
      ? dayjs(value, 'YYYY-MM-DD')
      : null

  const [date, setDate] = useState<dayjs.Dayjs | null>(initialDate)
  const handleChange = (newDate: dayjs.Dayjs | null) => {
    setDate(newDate)
    if (newDate) {
      const adjustedDate = newDate.add(newDate.utcOffset(), 'minute')
      callback(adjustedDate, false)
    } else {
      callback('', true)
    }
  }

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.blur()
  }

  const disabledDate = (current: dayjs.Dayjs): boolean => {
    const sixteenYearsAgo = dayjs().subtract(16, 'years')
    return (
      current &&
      (current.isAfter(dayjs().endOf('day')) ||
        current.isAfter(sixteenYearsAgo.endOf('day')))
    )
  }

  return (
    <DatePickerContainer
      color={color}
      bgColor={bgColor}
      boxShadow={boxShadow}
      className={`date-picker-wrapper ${className ?? ''} ${
        hasError ? 'error' : ''
      }`}
      disabledOpacityStyle={disabledOpacityStyle}
      isCancelMb={isCancelMb}
    >
      <Label color={color}>
        {SignupTexts.DateOfBirth}
        <Span>{SignupTexts.DateOfBirthComment}</Span>
      </Label>
      <DatePicker
        suffixIcon={<img src={calendarSVg} alt="calendar" />}
        locale={locale}
        direction="ltr"
        disabled={disabled ?? false}
        className="date-picker"
        value={date}
        placeholder="DD/MM/YYYY"
        format="DD-MM-YYYY"
        onChange={handleChange}
        showTime={false}
        picker="date"
        allowClear={false}
        placement="bottomRight"
        popupClassName="date-picker-popup"
        disabledDate={disabledDate}
        onFocus={handleFocus}
      />
      <HeightPlaceHolderForError>
        <Error hasError={hasError} errorText={errorText}></Error>
      </HeightPlaceHolderForError>
    </DatePickerContainer>
  )
}
