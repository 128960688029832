import { FunctionComponent } from 'react'
import { FlexColumn, Heading3 } from 'styles/generics'
import CheckMarkSvg from 'assets/images/check-mark.svg'
import styled from 'styled-components/macro'

interface Props {
  title?: string
}

const SuccessCheckmark: FunctionComponent<Props> = ({ title }) => {
  return (
    <FlexColumn align="center">
      <CheckMarkImg src={CheckMarkSvg} alt="checkmark" />
      {title && <Heading3>{title}</Heading3>}
    </FlexColumn>
  )
}

const CheckMarkImg = styled.img`
  width: 60px;
  margin-bottom: 10px;
`

export { SuccessCheckmark }
