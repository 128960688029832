import React, { useState } from 'react'
import { IInputProps } from '../../../types/interfaces/IInputProps'
import { GenderInputContainer, Label, OptionsWrapper } from './style'
import { Error } from '../error/Error'
import RadioButton from '../radio-button/RadioButton'
import { SignupTexts } from '../../../constants/signup-texts'
import { HeightPlaceHolderForError } from '../email-confirm-input/style'

export const GenderInput: React.FC<IInputProps> = ({
  callback,
  hasError,
  errorText,
  value,
  className,
}) => {
  const [selected, setSelected] = useState(value ?? '')

  const handleChange = (e: any) => {
    setSelected(e.target.value)
    callback(e.target.value, false)
  }

  return (
    <GenderInputContainer className={className ?? 'genderInput'}>
      <Label htmlFor="genderSelect">{SignupTexts.Gender}</Label>
      <OptionsWrapper>
        <RadioButton
          id={'male'}
          label={SignupTexts.MaleGenderOption}
          value={'Male'}
          isSelected={selected === 'Male'}
          callback={handleChange}
          separator={SignupTexts.GenderSeparator}
        ></RadioButton>
        <RadioButton
          id={'female'}
          label={SignupTexts.FemaleGenderOption}
          value={'Female'}
          isSelected={selected === 'Female'}
          callback={handleChange}
          separator={SignupTexts.GenderSeparator}
        ></RadioButton>
        <RadioButton
          id={'other'}
          label={SignupTexts.Other}
          value={'Other'}
          isSelected={selected === 'Other'}
          callback={handleChange}
        ></RadioButton>
      </OptionsWrapper>
      <HeightPlaceHolderForError>
        <Error hasError={hasError} errorText={errorText}></Error>
      </HeightPlaceHolderForError>
    </GenderInputContainer>
  )
}
