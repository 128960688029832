import styled from 'styled-components'
import personalAreaBgMobile from 'assets/images/personal-area-bg-mobile.png'
import { device } from 'constants/media-query'
import { Button } from 'shared/components/button/Button'
export const PageContainer = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  background: url(${personalAreaBgMobile});
  background-position: center;
  background-size: cover;
  position: relative;
  gap: 48px;
  padding: 24px 0;

  @media ${device.desktop} {
    gap: 60px;
  }
`

export const OverlayShadow = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  z-index: 1;
  height: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 16px);
  height: 410px;
  background: rgba(255, 255, 255, 0.4);
  z-index: 2;
  position: relative;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: white;

  @media ${device.large} {
    width: calc(100% - 432px);
  }
`

export const MaccabiConnect = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;

  @media ${device.desktop} {
    left: 10px;
  }
`

export const HeaderIcon = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Title = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  text-align: right;

  @media ${device.desktop} {
    font-size: 24px;
  }
`
export const TitleComingSoon = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  text-align: right;
  white-space: pre-line;

  @media ${device.desktop} {
    font-size: 24px;
  }
`

export const Content = styled.div`
  white-space: pre-line;
  width: 100%;
  font-size: 16px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: -0.025em;
  text-align: right;

  @media ${device.desktop} {
    font-size: 20px;
  }
`

export const Description = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: calc(100% - 44px);
  height: 100%;
  gap: 28px;
  padding-top: 35px;

  @media ${device.desktop} {
    width: calc(100% - 112px);
  }
`

export const Icon = styled.img`
  width: 55px;
  height: 55px;

  @media ${device.desktop} {
    width: 75px;
    height: 75px;
  }
`

export const MhfcLogo = styled.img`
  z-index: 2;
`
export const ButtonWrapper = styled(Button)`
  width: calc(100% - 16px);
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: #007638;
  color: #fff;
  padding: 20px 0;
  border: none;
  cursor: pointer;
  z-index: 2;
  border-radius: 5px;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
    cursor: default;
  }

  @media ${device.desktop} {
    width: calc(100% - 432px);
  }
`
