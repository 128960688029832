import { useEffect } from 'react'

function LoadAccess4uScript() {
  useEffect(() => {
    if (
      !process.env.REACT_APP_ACCESS4U_SITEKEY ||
      !process.env.REACT_APP_ACCESS4U_USER_ID ||
      process.env.REACT_APP_NODE_ENV === 'local'
    ) {
      console.log('Access4u script not loaded')
      return
    }
    window.access4u = {
      sitekey: process.env.REACT_APP_ACCESS4U_SITEKEY,
      userId: process.env.REACT_APP_ACCESS4U_USER_ID,
      feature: 'ALL_FEATURES',
      Language: 'iw',
      Menulang: 'EN',
      domains: { js: '' },
    }

    const script = document.createElement('script')
    script.defer = true
    script.src = 'https://plugin.access4u.co.il/features_new.js'
    script.setAttribute('data-cfasync', 'true')

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return null
}

export default LoadAccess4uScript
