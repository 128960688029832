const purchaseHistoryText = {
  points: "נק'",
  wrongInfoTooltip: {
    wrongInfoCTA: 'מידע שגוי\\חסר? מצאת טעות?',
    dearFans: 'אוהדים יקרים,',
    identifiedWrongInfo: 'זיהיתם מידע חלקי או שגוי?',
    tooltipDescription:
      'אנו פועלים לעדכן ולשפר את התוכן באופן שוטף, אך יתכן שבגרסאות הנוכחיות של האתר יימצאו פרטים שאינם עדכניים. במידה ומצאתם טעות - אנא פנו אלינו למייל בכתובת',
    contactEmail: 'tickets4@maccabihaifafc.com',
    handleProblem: 'אנו נטפל בבעיה ונעדכן אתכם במייל כשהנושא טופל.',
    thanksFor: 'תודה על ההבנה והסבלנות שלכם!',
    mhfcConnect: 'מכבי קונקט',
  },
  shopifyOrders: {
    orderNumber: "מס' הזמנה",
    date: 'תאריך',
    store: 'חנות',
    creditAccumulation: "צבירת נק'",
    creditAccumulationMobile: 'צבירה',
    creditSpent: "מימוש נק'",
    creditSpentMobile: 'מימוש',
    total: 'סה"כ',
  },
  tickets: {
    listHeaders: {
      opponent: 'יריבה',
      date: 'תאריך',
      stadium: 'אצטדיון',
      creditAccumulation: "צבירת נק'",
      creditSpent: "מימוש נק'",
      total: 'סה"כ',
    },
    noAvailableTickets: 'אין כרטיסים זמינים',
  },
  membershipsAndGreenpluses: {
    listHeaders: {
      productType: 'סוג מנוי',
      buyDate: 'תאריך רכישה',
      expirationDate: 'תאריך תפוגה',
      creditAccumulated: "צבירת נק'",
      creditSpent: "מימוש נק'",
      totalPrice: 'סה"כ',
    },
    noAvailableMembershipsAndGreenpluses: 'אין מנויים או גרין פלוסים זמינים',
    seasonalMembership: 'מנוי עונתי',
    greenplus: 'GREEN+',
    area: 'אזור',
    row: 'שורה',
    seat: 'כסא',
  },
}

export { purchaseHistoryText }
