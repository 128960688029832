import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { RootState, useAppDispatch } from '../store/store'
import { useSelector } from 'react-redux'
import { Navbar } from '../shared/components/navbar/navabr'
import { Footer } from '../shared/components/footer/footer'
import { Login } from '../pages/login/Login'
import { Signup } from '../pages/signup/Signup'
import { useIsAuthecticated } from '../shared/helpers/useIsAuthenticated'
import { updateUser, userAuth } from '../store/reducers/userReducer'
import { useEffect, useLayoutEffect } from 'react'
import { ResetPassword } from '../pages/login/components/reset-password/ResetPassword'
import { Logout } from '../pages/logout/Logout'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { AuthRedirectRoute } from './AuthRedirectRoute'
import { getCurrentAuthenticatedUserInfo } from 'services/AuthService'
import { AUTH_USER_TOKEN_KEY } from 'constants/auth'
import { UnderConstruction } from 'pages/under-construction/UnderConstruction'
import PendingPage from 'pages/pending-page/PendingPage'
import { ProtectedRoute } from './ProtectedRoute'
import { PersonalArea } from 'pages/personal-area/PersonalArea'
import { OrderHistory } from 'pages/order-history/OrderHistory'
import UserDetails from 'pages/user-details/UserDetails'

export const AppRouter = () => {
  const isAuthenticated = useIsAuthecticated()
  const dispatch = useAppDispatch()
  const user = useSelector((state: RootState) => state.user)
  useEffect(() => {
    dispatch(userAuth({ isAuthenticated: isAuthenticated ?? false }))
  }, [])
  const showNavbar = useSelector((state: RootState) => state.layout.showNavbar)
  const showFooter = useSelector((state: RootState) => state.layout.showFooter)
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  useEffect(() => {
    // eslint-disable-next-line no-extra-semi
    ;(async () => {
      const userInfo = await getCurrentAuthenticatedUserInfo()

      if (userInfo?.email && userInfo?.token) {
        localStorage.setItem(AUTH_USER_TOKEN_KEY, userInfo.token)
        dispatch(
          updateUser({
            ...user,
            email: userInfo.email,
            token: userInfo.token,
            isAuthenticated: true,
          }),
        )
      }
    })()
  }, [dispatch])

  return (
    <>
      {showNavbar && <Navbar />}
      <Routes>
        <Route
          index
          path="/"
          element={
            <AuthRedirectRoute>
              <Login />
            </AuthRedirectRoute>
          }
        />
        <Route
          path="login"
          element={
            <AuthRedirectRoute>
              <Login />
            </AuthRedirectRoute>
          }
        />
        <Route path={EAppRoutes.SIGNUP} element={<Signup />} />
        <Route path={EAppRoutes.LOGOUT} element={<Logout />} />
        <Route path={EAppRoutes.RESET_PASSWORD} element={<ResetPassword />} />
        <Route
          path={EAppRoutes.PERSONAL_AREA}
          element={
            <ProtectedRoute>
              <PersonalArea />
            </ProtectedRoute>
          }
        />
        <Route
          path={EAppRoutes.PROFILE}
          element={
            <ProtectedRoute>
              <UserDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={EAppRoutes.ORDER_HISTORY}
          element={
            <ProtectedRoute>
              <OrderHistory />
            </ProtectedRoute>
          }
        />
        {/* <Route
          path={EAppRoutes.GREENPLUS}
          element={
            <GreenplusProtectedRoute>
              <Greenplus />
            </GreenplusProtectedRoute>
          }
        >
          <Route path="credit" element={<h1>Waiting to be developed</h1>} />
          <Route path="discounts" element={<h1>Waiting to be developed</h1>} />
          <Route path="orders" element={<OrderList />} />
        </Route> */}
        <Route path={EAppRoutes.PENDING_PAGE} element={<PendingPage />} />
        <Route
          path={EAppRoutes.UNDER_CONSTRUCTION}
          element={<UnderConstruction />}
        />
        <Route
          path={EAppRoutes.WILD_CARD_PATH}
          element={<Navigate to={EAppRoutes.LOGIN} replace />}
        />
        <Route path={EAppRoutes?.PENDING_PAGE} element={<PendingPage />} />
      </Routes>
      {showFooter && <Footer />}
    </>
  )
}
