/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/store'
import {
  LoginContainer,
  LoginFormBg,
  LoginFormContainer,
  LoginFormHeader,
  LoginFormSubtitle,
  LoginFormTitle,
} from './style'
import { LoginForm } from './components/login-form/LoginForm'
import { ForgotPassword } from './components/forgot-password/ForgotPassword'
import { useNavigate } from 'react-router-dom'
import { LogoContainer } from '../signup/components/success/style'
import logo from '../../assets/images/logo_mhfc.png'
import { SignupTexts } from '../../constants/signup-texts'
import { useGetSfUserByEmailQuery } from '../../store/api-slices/userSlice'
import { IUserFrom } from '../../types/interfaces/IUser'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { updateUser } from 'store/reducers/userReducer'
import {
  AUTH_USER_TOKEN_KEY,
  expirationTimeInMinutes,
} from '../../constants/auth'
import { cookieService } from '../../services/cookie.service'
import { useAppLogout } from 'shared/helpers/useAppLogout'
import { ESignupForms } from 'types/enums/ESignupForms'
import { ESignupFormSteps } from 'types/enums/ESignupFormSteps'
import { errorStatusCodes } from 'constants/errors'
import { AppModal } from 'shared/components/modal/AppModal'
import { FlexRow, GreenButton, Heading3, Text } from 'styles/generics'
import styled from 'styled-components/macro'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'
import { LoginTexts } from 'constants/login-texts'
import { userService } from 'services/user.service'

export const Login: React.FC<any> = () => {
  const user = useAppSelector((state) => state.user)
  const { isAuthenticated, token } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const logout = useAppLogout()
  const [isUserMissingDetailsModalOpen, setIsUserMissingDetailsModalOpen] =
    useState(false)

  const [form, setForm] = useState<
    'loginForm' | 'forgotPassword' | 'resetPassword'
  >('loginForm')

  const [loginTexts, setLoginTexts] = useState<{
    title: string
    subtitle: string
  }>({
    title: SignupTexts.Welcome,
    subtitle: SignupTexts.ToLogInPleaseEnterEmailAddress,
  })

  const { data, isFetching, isError, error } = useGetSfUserByEmailQuery(
    { shouldValidateUser: true },
    {
      skip: !token || !isAuthenticated,
    },
  )

  useEffect(() => {
    if (error) {
      switch ((error as any).status) {
        case errorStatusCodes.UNAUTHORIZED:
          logout(0, false)
          return
        case errorStatusCodes.NOT_ACCEPTABLE:
          logout(EAppRoutes.PENDING_PAGE, false, {
            firstName: user?.personalDetails?.firstName,
            lastName: user?.personalDetails?.lastName,
          })
          return
        case errorStatusCodes.PRECONDITION_REQUIRED:
          setIsUserMissingDetailsModalOpen(true)
          return
      }
    }

    if (isAuthenticated && token) {
      if (!isFetching && !isError) {
        cookieService.setCookie(
          AUTH_USER_TOKEN_KEY,
          token,
          expirationTimeInMinutes,
        )
        dispatch(
          updateUser({
            ...user,
            email: data?.email,
            token: token,
            isAuthenticated: true,
          }),
        )
        if (!userService.isUserMissingDetails(data)) {
          if (window?.opener && token) {
            window.opener.postMessage(
              {
                cognitoToken: token,
                email: data?.email,
                from: IUserFrom.LOGIN,
              },
              '*',
            )
            window.close()
            return
          } else {
            navigate(EAppRoutes.PERSONAL_AREA)
          }
        } else {
          return setIsUserMissingDetailsModalOpen(true)
        }
      }
    }
  }, [isAuthenticated, dispatch, form, data, navigate, token, error])

  const setFormType = (
    form: 'loginForm' | 'forgotPassword' | 'resetPassword',
  ) => {
    setForm(form)
  }

  const renderForm = () => {
    switch (form) {
      case 'loginForm':
        return (
          <LoginForm
            loginTexts={loginTexts}
            setLoginTexts={setLoginTexts}
            setFormType={setFormType}
          />
        )
      case 'forgotPassword':
        return (
          <ForgotPassword
            loginTexts={loginTexts}
            setLoginTexts={setLoginTexts}
            setFormType={setFormType}
          />
        )
      case 'resetPassword':
        return (
          <ForgotPassword
            loginTexts={loginTexts}
            setLoginTexts={setLoginTexts}
            setFormType={setFormType}
          />
        )
      default:
        return <LoginForm setFormType={setFormType} />
    }
  }

  return (
    <>
      <AppModal isModalOpen={isUserMissingDetailsModalOpen}>
        <ModalHeading fontFamily={EAppFontFamilies.ATLAS_REGULAR}>
          {LoginTexts.userMissingDetailsModal.title}
        </ModalHeading>
        <Text mb="20px">{LoginTexts.userMissingDetailsModal.description}</Text>
        <FlexRow gap={50} align="center" justify="center">
          <GreenButton
            onClick={() => {
              navigate(EAppRoutes.SIGNUP, {
                state: {
                  signupStep: ESignupForms.signupForm,
                  signupFormStep: ESignupFormSteps.personalDetails,
                  isEditDetails: true,
                },
              })
            }}
          >
            {LoginTexts.userMissingDetailsModal.button}
          </GreenButton>
        </FlexRow>
      </AppModal>
      <LoginContainer>
        <LoginFormContainer>
          <LoginFormBg>
            <LogoContainer>
              <img
                style={{ width: '110px', height: 'auto' }}
                src={logo}
                alt="Logo"
              />
            </LogoContainer>
            <LoginFormHeader>
              <LoginFormTitle>{loginTexts.title}</LoginFormTitle>
              <LoginFormSubtitle>{loginTexts.subtitle}</LoginFormSubtitle>
            </LoginFormHeader>
            {renderForm()}
          </LoginFormBg>
        </LoginFormContainer>
      </LoginContainer>
    </>
  )
}

const ModalHeading = styled(Heading3)`
  margin-bottom: 10px;
`
