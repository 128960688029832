import { useNavigate } from 'react-router-dom'
import { mhfcAuthApiSlice } from 'store/apiSlice'
import { userLogout } from 'store/reducers/userReducer'
import { useAppDispatch } from 'store/store'
import { logout as cognitoLogout } from 'services/AuthService'
import { EAppRoutes } from 'types/enums/EAppRoutes'

export const useAppLogout = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const logout = async (
    redirectTo: EAppRoutes | 0 = EAppRoutes.LOGIN,
    isCloseWindowOpener: boolean = true,
    state: object = {},
  ) => {
    dispatch(mhfcAuthApiSlice.util.resetApiState())
    dispatch(userLogout())
    if (redirectTo === 0) {
      await cognitoLogout(isCloseWindowOpener)
      navigate(0)
    } else if (redirectTo) {
      cognitoLogout(isCloseWindowOpener)
      navigate(redirectTo, { state })
    }
  }

  return logout
}
