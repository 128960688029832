import { FunctionComponent } from 'react'
import { Modal } from 'antd'
import styled from 'styled-components/macro'

interface Props {
  isModalOpen: boolean
  children: React.ReactNode | React.ReactNode[]
  background?: string
  color?: string
}

const AppModal: FunctionComponent<Props> = ({
  isModalOpen,
  children,
  background,
  color,
}) => {
  return (
    <Modal
      open={isModalOpen}
      centered
      closable={false}
      modalRender={(modal) => (
        <ModalWrapper background={background} color={color}>
          {modal}
        </ModalWrapper>
      )}
      bodyStyle={{ textAlign: 'center' }}
      footer={null}
    >
      {children}
    </Modal>
  )
}

const ModalWrapper = styled.div<{ background?: string; color?: string }>`
  .ant-modal-content {
    ${({ background }) => background && `background: ${background};`}
    ${({ color }) => color && `color: ${color};`}
  }
`

export { AppModal }
