/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent } from 'react'
import { Select } from 'antd'
import { FlexColumn } from 'styles/generics'
import styled from 'styled-components/macro'
import SelectArrow from 'assets/images/select-arrow.svg'

interface Props {
  title: string
  callback: (value: any, error?: boolean) => void
  options: { value: string; label: string }[]
  value?: string
  bgColor?: string
  color?: string
}

const SelectInput: FunctionComponent<Props> = ({
  title,
  callback,
  options,
  value,
  bgColor,
  color,
}) => {
  const handleChange = (value: any) => {
    callback(value, false)
  }

  return (
    <FlexColumn width={'100%'}>
      <SelectTitle>{title}</SelectTitle>
      <CustomSelect
        color={color}
        bgColor={bgColor}
        className="select-input"
        labelInValue
        defaultValue={{ value }}
        onChange={handleChange}
        options={options}
        suffixIcon={<img src={SelectArrow} alt="down-arrow" />}
      />
    </FlexColumn>
  )
}

const SelectTitle = styled.label`
  font-size: 18px;
`

const CustomSelect = styled(Select)<{ bgColor?: string; color?: string }>`
  .ant-select-selector {
    ${({ bgColor }) => bgColor && `background-color: ${bgColor} !important;`}
    ${({ color }) => color && `color: ${color} !important;`}
  }
`

export { SelectInput }
