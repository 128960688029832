/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { IInputProps } from '../../../types/interfaces/IInputProps'
import { Error } from '../error/Error'
import { PhoneInputContainer, Input, Label, PhoneExample } from './style'
import { PhoneNumberRegex } from '../../helpers/regex'
import { SignupTexts } from '../../../constants/signup-texts'
import { HeightPlaceHolderForError } from '../email-confirm-input/style'

export const PhoneInput: React.FC<IInputProps> = ({
  callback,
  hasError,
  errorText,
  className,
  value,
  disabled,
  disabledOpacityStyle,
  labelText,
  color,
}) => {
  useEffect(() => {
    if (value) validatePhoneNumber(value)
  }, [])

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const newValue = e.target.value
    validatePhoneNumber(newValue)
  }

  const validatePhoneNumber = (phoneNumber: string) => {
    const error = !PhoneNumberRegex.test(phoneNumber)
    callback(phoneNumber, error)
  }

  const [initialDisabled, setInitialDisabled] = useState<boolean>(
    disabled ?? false,
  )

  useEffect(() => {
    if (value && !initialDisabled) {
      setInitialDisabled(false)
    }
  }, [value, initialDisabled])

  return (
    <PhoneInputContainer
      className={`phone ${className ?? ''} ${hasError ? 'error' : ''}`}
    >
      <Label color={color} htmlFor="phone">
        {labelText || SignupTexts.CellphoneNumber}
      </Label>
      <PhoneExample className="phone-example" color={color}>
        {SignupTexts.CellphoneNumberExample}
      </PhoneExample>
      <Input
        color={color}
        title={disabled ? 'לא ניתן לערוך שדה זה' : ''}
        disabled={initialDisabled ?? false}
        value={value}
        type="tel"
        name="phone"
        dir="rtl"
        onChange={(e: any) => handleChange(e)}
        disabledOpacityStyle={disabledOpacityStyle}
      ></Input>
      <HeightPlaceHolderForError>
        <Error hasError={hasError} errorText={errorText}></Error>
      </HeightPlaceHolderForError>
    </PhoneInputContainer>
  )
}
