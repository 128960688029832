import React from 'react'
import {
  Container,
  Content,
  Description,
  HeaderIcon,
  Icon,
  MaccabiConnect,
  MhfcLogo,
  OverlayShadow,
  PageContainer,
  Title,
} from './style'
import { Image } from 'styles/generics'
import maccabiConnectSvg from 'assets/icons/maccabi-connect.svg'
import pendingSearchSvg from 'assets/icons/pending-search.svg'
import mhfcLogoSvg from 'assets/icons/mhfc-logo.svg'
import {
  pendingPageTextDesktop,
  pendingPageTextMobile,
  pendingPageTitle,
} from '../../constants/signup-texts'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import { useLocation } from 'react-router-dom'

const PendingPage: React.FC = () => {
  const isMobile = useIsMobile()
  const location = useLocation()

  return (
    <PageContainer>
      <OverlayShadow />
      <MhfcLogo src={mhfcLogoSvg} alt="mhfc-logo" />
      <Container>
        <Description>
          <Title dir="rtl">
            {pendingPageTitle(
              location?.state?.firstName,
              location?.state?.lastName,
            )}
          </Title>
          <Content dir="rtl">
            {isMobile ? pendingPageTextMobile() : pendingPageTextDesktop()}
          </Content>
        </Description>
        <MaccabiConnect>
          <Image src={maccabiConnectSvg} alt="maccabi-connect" />
        </MaccabiConnect>
        <HeaderIcon>
          <Icon src={pendingSearchSvg} alt="icon" />
        </HeaderIcon>
      </Container>
    </PageContainer>
  )
}

export default PendingPage
