/* eslint-disable @typescript-eslint/no-explicit-any */
import { FunctionComponent } from 'react'
import { PhoneInput } from 'shared/components/phone-input/PhoneInput'
import { useAppDispatch, useAppSelector } from 'store/store'
import { updateUser } from '../../../../store/reducers/userReducer'
import { TextInput } from 'shared/components/text-input/TextInput'
import { theme } from 'styles/theme'

interface Props {
  setErrors: React.Dispatch<
    React.SetStateAction<{
      id?: boolean | undefined
      dob?: boolean | undefined
      phoneNum?: boolean | undefined
      firstName?: boolean | undefined
      lastName?: boolean | undefined
      address?:
        | {
            city?: boolean | undefined
            street?: boolean | undefined
            postalCode?: boolean | undefined
          }
        | undefined
      gender: boolean
    }>
  >
  errors: {
    id?: boolean | undefined
    dob?: boolean | undefined
    phoneNum?: boolean | undefined
    firstName?: boolean | undefined
    lastName?: boolean | undefined
    address?:
      | {
          city?: boolean | undefined
          street?: boolean | undefined
          postalCode?: boolean | undefined
        }
      | undefined
    gender: boolean
  }
}

const ContactDetailsForm: FunctionComponent<Props> = ({
  errors,
  setErrors,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const formData = useAppSelector((state) => state.user.personalDetails)

  const handlePhoneChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, phoneNum: error })
    dispatch(
      updateUser({
        ...user,
        personalDetails: { ...formData, phoneNum: value },
      }),
    )
  }

  return (
    <>
      <PhoneInput
        value={formData?.phoneNum}
        hasError={errors.phoneNum}
        errorText={'מספר נייד לא תקין'}
        callback={handlePhoneChange}
        disabled={true}
        labelText={'מספר טלפון נייד'}
        color={theme.white}
        disabledOpacityStyle
      />
      <TextInput
        color={theme.white}
        value={user?.email || ''}
        minLength={2}
        callback={() => console.log('callback')}
        label={'אימייל'}
        id={'email'}
        disabled={true}
        disabledOpacityStyle
      />
    </>
  )
}
export { ContactDetailsForm }
