import {
  Fragment,
  FunctionComponent,
  useState,
  useRef,
  useEffect,
  useMemo,
} from 'react'
import styled from 'styled-components/macro'
import { SectionContainer } from './SectionContainer'
import { personalAreaTexts } from 'constants/personal-area-texts'
import { useAppSelector } from 'store/store'
import friendsSvg from 'assets/icons/friends.svg'
import { WhiteCircle } from '../style'
import { Button, FlexRow, Image, Text } from 'styles/generics'
import { HorizontalLine } from 'shared/components/horizontal-line/HorizontalLine'
import { theme } from 'styles/theme'
import { AddRelationshipLink } from './AddRelationshipLink'

const MyFriends: FunctionComponent = () => {
  const user = useAppSelector((state) => state.user)
  const [showAll, setShowAll] = useState<boolean>(false)
  const [maxHeight, setMaxHeight] = useState<string>('350px')
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (containerRef.current) {
      setMaxHeight(`${containerRef.current.scrollHeight}px`)
    }
  }, [user.friends])

  useEffect(() => {
    if (containerRef.current) {
      if (showAll) {
        setMaxHeight(`${containerRef.current.scrollHeight}px`)
      } else {
        setMaxHeight('350px')
      }
    }
  }, [showAll])

  const displayedFriends = useMemo(
    () => (showAll ? user.friends : user.friends?.slice(0, 3)),
    [user.friends, showAll],
  )

  if (!user?.friends?.length) return null

  return (
    <SectionContainer title={personalAreaTexts.myFriends.title}>
      <FriendsContainer ref={containerRef} style={{ maxHeight }}>
        {displayedFriends &&
          displayedFriends.map((friend) => (
            <Fragment key={friend.id}>
              <FlexRow align="center" gap={18}>
                <WhiteCircle padding={'20px'}>
                  <Image src={friendsSvg} alt="friend-icon" />
                </WhiteCircle>
                <Text fontSize="24px" fontFamily="Atlas-AAA-Medium">
                  {friend.firstName + ' ' + friend.lastName}
                </Text>
              </FlexRow>
              <HorizontalLine mb="15px" mt="15px" bgColor={theme.white} />
            </Fragment>
          ))}
      </FriendsContainer>
      <AddRelationshipLink url={process.env.REACT_APP_BASE_ROBOTICKET_URL} />
      {user.friends.length > 3 && (
        <ShowMoreButton onClick={() => setShowAll(!showAll)}>
          <Text color={theme.white}>
            {showAll
              ? personalAreaTexts.myFriends.showLess
              : personalAreaTexts.myFriends.showMore}
          </Text>
        </ShowMoreButton>
      )}
    </SectionContainer>
  )
}

const FriendsContainer = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease;
`

const ShowMoreButton = styled(Button)`
  margin-top: 30px;
  text-decoration: underline;
  color: ${({ theme }) => theme.white};
`

export { MyFriends }
