/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { PersonalDetailsFormContainer } from './style'
import { EPersonalDetailsFormSteps } from '../../../../../../types/enums/EPersonalDetailsFormSteps'
import { ESignupFormSteps } from '../../../../../../types/enums/ESignupFormSteps'
import { useAppDispatch, useAppSelector } from '../../../../../../store/store'
import { useGetSfUserByEmailQuery } from '../../../../../../store/api-slices/userSlice'
import { PersonalDetailsFormPersonalInfoFirstStep } from './components/personal-details-form-personal-info/PersonalDetailsFormPersonalInfoFirstStep'
import { PersonalDetailsFormExtraPersonalInfoSecondStep } from './components/personal-details-form-extra-personal-info/PersonalDetailsFormExtraPersonalInfoSecondStep'
import { PersonalDetailsFormThirdStep } from './components/personal-details-form-mhfc-id/PersonalDetailsFormThirdStep'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { useAppLogout } from 'shared/helpers/useAppLogout'
import { errorStatusCodes } from 'constants/errors'
import { Loader } from 'shared/components/loader/loader'
import { updateUser } from 'store/reducers/userReducer'

interface Props {
  setSignupFormTexts: any
  onFinishStep: (step: ESignupFormSteps) => void
}

export const PersonalDetailsForm: React.FC<Props> = ({
  setSignupFormTexts,
  onFinishStep,
}) => {
  const user = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const logout = useAppLogout()
  const getSfUser = useGetSfUserByEmailQuery({})
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [step, setStep] = useState<EPersonalDetailsFormSteps>(
    EPersonalDetailsFormSteps.firstStep,
  )
  const { data, error, isFetching, isError } = getSfUser

  useEffect(() => {
    if (error && 'status' in error) {
      const statusCode = error?.status
      if (statusCode === errorStatusCodes.NOT_ACCEPTABLE)
        logout(EAppRoutes.PENDING_PAGE, false, {
          firstName: user?.personalDetails?.firstName,
          lastName: user?.personalDetails?.lastName,
        })
    }
  }, [error])

  const updateUserData = async () => {
    if (!isFetching && !isError) {
      if (data?.personalDetails) {
        setIsLoading(false)
        dispatch(
          updateUser({ ...user, personalDetails: data?.personalDetails }),
        )
      }
    } else if (isError) {
      setIsLoading(false)
      console.log('[PersonalDetailsForm] error getSfUser: ', error)
    }
  }

  useEffect(() => {
    updateUserData()
  }, [isFetching, isError, data])

  const personalDetailsStep = () => {
    switch (step) {
      case EPersonalDetailsFormSteps.firstStep:
        return (
          <PersonalDetailsFormPersonalInfoFirstStep
            setSignupFormTexts={setSignupFormTexts}
            onNextClicked={onNextClicked}
          />
        )
      case EPersonalDetailsFormSteps.secondStep:
        return (
          <PersonalDetailsFormExtraPersonalInfoSecondStep
            setSignupFormTexts={setSignupFormTexts}
            onNextClicked={onNextClicked}
          />
        )
      case EPersonalDetailsFormSteps.thirdStep:
        return (
          <PersonalDetailsFormThirdStep
            setSignupFormTexts={setSignupFormTexts}
            onNextClicked={onNextClicked}
          />
        )
      default:
        return (
          <PersonalDetailsFormPersonalInfoFirstStep
            setSignupFormTexts={setSignupFormTexts}
            onNextClicked={onNextClicked}
          />
        )
    }
  }

  const onNextClicked = async (nextStep: EPersonalDetailsFormSteps) => {
    if (nextStep === EPersonalDetailsFormSteps.last) {
      onFinishStep(ESignupFormSteps.last)
    }
    setStep(nextStep)
  }

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <PersonalDetailsFormContainer>
      {personalDetailsStep()}
    </PersonalDetailsFormContainer>
  )
}
