import styled from 'styled-components/macro'

export const ErrorIndicator = styled.div`
  display: none;
  color: #ff0000;

  &.error {
    display: block;
  }
`
