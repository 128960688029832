import React, { useEffect, useState } from 'react'
import { EmailInput } from '../../../../../../shared/components/email-input/EmailInput'
import { ESignupFormSteps } from '../../../../../../types/enums/ESignupFormSteps'
import Checkbox from '../../../../../../shared/components/checkbox/Checkbox'
import { ButtonWrapper } from './style'
import { useAppDispatch } from '../../../../../../store/store'
import { updateUser } from '../../../../../../store/reducers/userReducer'
import {
  ErrorTexts,
  SignupTexts,
} from '../../../../../../constants/signup-texts'
import { AppLinks } from '../../../../../../constants/links'
import { Loader } from '../../../../../../shared/components/loader/loader'

export const SignupFormEmail: React.FC<any> = ({
  setSignupFormTexts,
  onFinishStep,
}) => {
  const dispatch = useAppDispatch()
  const [signupFormEmailData, setSignupFormEmailData] = useState<{
    email: string
    terms: boolean
    newsletter: boolean
  }>({ email: '', terms: false, newsletter: false })
  const [errors, setErrors] = useState<{ email?: boolean; terms?: boolean }>({
    email: false,
    terms: false,
  })
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() => {
    setSignupFormTexts({
      title: SignupTexts.RegisterToStore,
      subtitle: SignupTexts.EmailConfirmationMessage,
    })
  }, [])

  useEffect(() => {
    if (
      signupFormEmailData.email &&
      signupFormEmailData.terms &&
      !errors.email &&
      !errors.terms
    ) {
      setIsDisabled(false)
    } else if (!isDisabled) {
      setIsDisabled(true)
    }
  }, [signupFormEmailData])

  const handleEmailChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, email: error })
    setSignupFormEmailData({ ...signupFormEmailData, email: value })
  }

  const handleTermsChange = () => {
    setErrors({ ...errors, terms: signupFormEmailData.terms })
    setSignupFormEmailData({
      ...signupFormEmailData,
      terms: !signupFormEmailData.terms,
    })
  }

  const handleNewsletterChange = () => {
    setSignupFormEmailData({
      ...signupFormEmailData,
      newsletter: !signupFormEmailData.newsletter,
    })
  }

  const nextButtonClicked = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    dispatch(updateUser({ ...signupFormEmailData, isAuthenticated: false }))
    onFinishStep(ESignupFormSteps.password)
    setIsLoading(false)
  }

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <>
      <EmailInput
        hasError={errors.email}
        errorText={ErrorTexts.EmailIsNotValid}
        callback={handleEmailChange}
      ></EmailInput>
      <Checkbox
        hasError={errors.terms}
        errorText={ErrorTexts.MustAcceptTermsOfAgreement}
        callback={handleTermsChange}
        id={'terms'}
        label={SignupTexts.IReadAndConfirmThe}
        linkLabel={SignupTexts.TermsOfAgreement}
        href={AppLinks.TermsOfAgreementLink}
        checked={signupFormEmailData.terms}
      ></Checkbox>
      <Checkbox
        callback={handleNewsletterChange}
        id={'newsletter'}
        label={SignupTexts.ImInterestedInReceivingMarketingMails}
        checked={signupFormEmailData.newsletter}
      ></Checkbox>
      <ButtonWrapper
        type="submit"
        disabled={isDisabled}
        className={`buttonWrapper ${isDisabled ? 'disabled' : ''}`}
        text={SignupTexts.Next}
        callback={(e) => nextButtonClicked(e)}
      />
    </>
  )
}
