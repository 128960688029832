import { device } from 'constants/media-query'
import styled from 'styled-components/macro'
import { theme } from 'styles/theme'

const WhiteCircle = styled.div<{ padding?: string; iconName?: string }>`
  border-radius: 50%;
  background-color: ${theme.bgWhite};
  width: 46px;
  height: 46px;
  padding: 7px;

  ${({ iconName }) =>
    iconName === 'Other' && 'padding: 10px; padding-top: 13px;'}

  @media ${device.desktop} {
    padding: 13px;
    width: 85px;
    height: 85px;
    ${({ padding }) => padding && `padding: ${padding};`}
    ${({ iconName }) =>
      iconName === 'Other' && 'padding: 15px; padding-top: 18px;'}
  }
`

export { WhiteCircle }
