import styled from 'styled-components/macro'
import { Button } from '../../../../shared/components/button/Button'

export const ForgotFormBody = styled.form``

export const ButtonWrapper = styled(Button)`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: #007638;
  color: #fff;
  padding: 20px 0;
  border: none;
  margin-bottom: 40px;
  cursor: pointer;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
  }
`

export const ResendButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ResendButtonText = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
`

export const ResendButton = styled(Button)`
  border: none;
  background: none;
  font-weight: 700;
  text-decoration: underline;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #007638;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }
`
