import styled from 'styled-components/macro'
import mobileLoginBackground from '../../assets/images/mobile_login_bg.png'
import desktopLoginBackground from '../../assets/images/desktop_login_bg.jpg'
import { device } from '../../constants/media-query'

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url(${mobileLoginBackground}),
    linear-gradient(0deg, rgba(0, 0, 0, 0.76) 15.55%, rgba(0, 0, 0, 0) 56.53%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 25px;
  flex: 1;

  @media ${device.desktop} {
    background: url(${desktopLoginBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
    width: 100vw;
  }
`

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  direction: rtl;
  margin-top: 50px;

  @media ${device.desktop} {
    margin-top: 0;
    padding: 0;
    width: 33%;
  }
`

export const LoginFormBg = styled.div`
  background: #fff;
  padding: 60px 26px 40px;

  @media ${device.desktop} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const LoginFormHeader = styled.div`
  padding: 0 0 35px;
  text-align: center;
  border-bottom: 1px solid #00000033;
  margin-bottom: 32px;
`

export const LoginFormTitle = styled.div`
  font-size: 36px;
  line-height: 41px;
  letter-spacing: -0.025em;
  color: #000000;
  margin-bottom: 8px;
`

export const LoginFormSubtitle = styled.div`
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: #757575;
`
