import { device } from 'constants/media-query'
import { FunctionComponent } from 'react'
import styled from 'styled-components/macro'

interface Props {
  zIndex?: number
  borderRadius?: string
  isHideMobile?: boolean
}

const Screen: FunctionComponent<Props> = ({
  zIndex,
  borderRadius,
  isHideMobile,
}) => {
  return (
    <ScreenContainer
      zIndex={zIndex}
      borderRadius={borderRadius}
      isHideMobile={isHideMobile}
    />
  )
}

const ScreenContainer = styled.div<{
  zIndex?: number
  borderRadius?: string
  isHideMobile?: boolean
}>`
  ${({ isHideMobile }) =>
    isHideMobile &&
    ` display: none; @media (${device.desktop}) { display: block;}`}
  position: absolute;
  top: 0;
  z-index: -10;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  ${({ zIndex }) => zIndex && `z-index: ${zIndex};`}
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius};`}
`

export { Screen }
