import styled from 'styled-components/macro'
import checkmark from '../../../assets/images/checkmark.svg'

export const CheckboxContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  &.error {
    input {
      border: 1px solid #ff0000;
    }
  }
  .error {
    margin-right: 10px;
  }
`

export const Label = styled.label`
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
`

export const Input = styled.input`
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none;
  /* creating a custom design */
  width: 18px;
  height: 18px;
  border-radius: 2px;
  border: 1px solid #000000;
  margin-left: 10px;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &.checked {
    position: relative;
    &::before {
      content: '';
      background: url(${checkmark});
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      width: 18px;
      height: 18px;
    }
  }
`
