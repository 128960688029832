import Cookies from 'universal-cookie'
const cookies = new Cookies()

const setCookie = async (name: string, value: any, minutes: number) => {
  cookies.set(name, value, {
    expires: new Date(new Date().getTime() + 1000 * 60 * minutes),
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
    sameSite: 'none',
    secure: true,
  })
}

const getCookie = (name: string) => {
  return cookies.get(name)
}

const deleteCookie = async (name: string) => {
  cookies.remove(name, {
    path: '/',
    domain: process.env.REACT_APP_COOKIE_DOMAIN,
  })
}

export const cookieService = {
  setCookie,
  deleteCookie,
  getCookie,
}
