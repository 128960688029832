enum EExternalLinks {
  GREENPLUS_SIGNUP = 'https://mhaifafc.com/greenPlus',
  MHFC = 'https://mhaifafc.com/',
  MHFC_SHOP = 'https://shop.mhaifafc.com/',
  ADIDAS = 'https://www.adidas.co.il/he?cm_mmc=AdiPartner_MacabbiHaifa-_-partner-_-logo-_-logo-_-dv:Brand-_-cn:adidas-EMEA-Brand-Partner-website-football-Prospecting-IL-NA-2306-MacabbiHaifa-logo-_-pc:football&cm_mmc1=IL&cm_mmc2=adidas-EMEA-Brand-Partner-website-football-Prospecting-IL-NA-2306-MacabbiHaifa-logo',
  VOLVO = 'https://www.volvocars.com/',
  HERTZ = 'https://www.hertz.co.il/he/HomePage/.aspx?utm_source=website&utm_medium=banner&utm_campaign=macabi_haifa',
  VARIETY = 'https://www.variety.co.il/',
  AMRAM = 'https://www.amramltd.co.il/',
  MOVEO = 'https://www.moveo.group/',
  AUTO_CENTER = 'https://www.autocenter.co.il/',
}

export { EExternalLinks }
