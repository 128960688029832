import React, { useEffect } from 'react'
import underConstructionImage from '../../assets/images/under-construction.png'
import styled from 'styled-components/macro'
import { showFooter, showNavbar } from 'store/reducers/layoutReducer'
import { useAppDispatch } from 'store/store'
import { SignupTexts } from 'constants/signup-texts'
import { ImageWrapper } from 'shared/components/ImageWrapper'

export const UnderConstruction: React.FC = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(showNavbar(true))
    dispatch(showFooter(true))

    return () => {
      dispatch(showNavbar(false))
      dispatch(showFooter(false))
    }
  }, [])

  return (
    <Container>
      <h1>{SignupTexts.InAppBrowserModal.Title}</h1>
      <h2>אנא פנה לתמיכה בטלפון 04-7704444</h2>
      <ImageWrapper
        width="250px"
        desktopWidth="400px"
        alt="under-construction"
        src={underConstructionImage}
      />
    </Container>
  )
}

const Container = styled.div`
  direction: rtl;
  margin-top: 80px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
