/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FormEvent, useEffect, useState } from 'react'
import {
  ResetFormBody,
  ButtonWrapper,
  ResendButtonWrapper,
  ResendButton,
  ResendButtonText,
  LoginContainer,
  LoginFormContainer,
  LoginFormBg,
  LoginFormHeader,
  LoginFormTitle,
  LoginFormSubtitle,
  PasswordInstructionsContainer,
  PasswordInstructionsTitle,
  PasswordInstructions,
  PasswordInstructionItem,
  Email,
} from './style'
import {
  resetPassword,
  resetPasswordSubmit,
} from '../../../../services/AuthService'
import { EmailConfirmInput } from '../../../../shared/components/email-confirm-input/EmailConfirmInput'
import { PasswordInput } from '../../../../shared/components/password-input/PasswordInput'
import { PasswordConfirmInput } from '../../../../shared/components/password-confirm-input/PasswordConfirmInput'
import { useLocation, useNavigate } from 'react-router-dom'
import { ErrorTexts, SignupTexts } from '../../../../constants/signup-texts'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { LoginTexts } from 'constants/login-texts'

export const ResetPassword: React.FC<any> = () => {
  const { state } = useLocation()
  const navigate = useNavigate()
  const { email } = state // Read values passed on state

  const [resetFormData, setResetFormData] = useState<{
    cofirmationCode: string
    password: string
    passwordConfirm: string
  }>({
    cofirmationCode: '',
    password: '',
    passwordConfirm: '',
  })

  const [errors, setErrors] = useState<{
    cofirmationCode?: boolean
    password?: boolean
    passwordConfirm?: boolean
  }>({
    cofirmationCode: false,
    password: false,
    passwordConfirm: false,
  })

  const [loginTexts] = useState<{
    title: string
    email: string
  }>({
    title: SignupTexts.Welcome,
    email: email,
  })

  const [isDisabled, setIsDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (
      resetFormData.cofirmationCode &&
      resetFormData.password &&
      resetFormData.passwordConfirm &&
      !errors.cofirmationCode &&
      !errors.password &&
      !errors.passwordConfirm
    ) {
      setIsDisabled(false)
    } else if (!isDisabled) {
      setIsDisabled(true)
    }
  }, [resetFormData])

  const handleConfirmationCodeChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, cofirmationCode: error })
    setResetFormData({ ...resetFormData, cofirmationCode: value })
  }

  const handlePasswordChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, password: error })
    setResetFormData({ ...resetFormData, password: value })

    const confirmPasswordError = !(
      (value === resetFormData.passwordConfirm &&
        resetFormData.passwordConfirm !== '') ||
      (value !== resetFormData.passwordConfirm &&
        (resetFormData.passwordConfirm === '' || resetFormData.password === ''))
    )
    setErrors({ ...errors, passwordConfirm: confirmPasswordError })
  }

  const handlePasswordConfirmChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, passwordConfirm: error })
    setResetFormData({ ...resetFormData, passwordConfirm: value })
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    await resetPasswordSubmit(
      email.toLowerCase(),
      resetFormData.cofirmationCode,
      resetFormData.password,
    )
    return navigate(EAppRoutes.LOGIN)
  }

  const handleResendClick = async (event: FormEvent) => {
    event.preventDefault()
    resetPassword(email.toLowerCase())
  }

  const renderForm = () => {
    return (
      <ResetFormBody>
        <EmailConfirmInput
          hasError={errors.cofirmationCode}
          errorText={ErrorTexts.CodeIsNotValid}
          callback={handleConfirmationCodeChange}
        ></EmailConfirmInput>
        <PasswordInstructionsContainer>
          <PasswordInstructionsTitle>
            {SignupTexts.SignupFormPasswordInstructionsTitle}
          </PasswordInstructionsTitle>
          <PasswordInstructions>
            <PasswordInstructionItem>
              {SignupTexts.SignupFormPasswordInstructionFirstRule}
            </PasswordInstructionItem>
            <PasswordInstructionItem>
              {SignupTexts.SignupFormPasswordInstructionFifthRule}
            </PasswordInstructionItem>
            <PasswordInstructionItem>
              {SignupTexts.SignupFormPasswordInstructionSecondRule}
            </PasswordInstructionItem>
            <PasswordInstructionItem>
              {SignupTexts.SignupFormPasswordInstructionThirdRule}
            </PasswordInstructionItem>
            <PasswordInstructionItem>
              {SignupTexts.SignupFormPasswordInstructionFourthRule}
            </PasswordInstructionItem>
          </PasswordInstructions>
        </PasswordInstructionsContainer>
        <PasswordInput
          hasError={errors.password}
          errorText={ErrorTexts.PasswordIsNotValid}
          callback={handlePasswordChange}
        ></PasswordInput>
        <PasswordConfirmInput
          hasError={errors.passwordConfirm}
          errorText={ErrorTexts.PasswordIsNotMatch}
          password={resetFormData.password}
          callback={handlePasswordConfirmChange}
        ></PasswordConfirmInput>
        <ButtonWrapper
          type="submit"
          disabled={isDisabled}
          className={`buttonWrapper ${isDisabled ? 'disabled' : ''}`}
          text={SignupTexts.Send}
          callback={handleSubmit}
        />
        <ResendButtonWrapper>
          <ResendButtonText>{SignupTexts.DidNotReceive}</ResendButtonText>
          <ResendButton
            disabled={false}
            className={`resendButton`}
            text={SignupTexts.SendAgain}
            callback={handleResendClick}
          ></ResendButton>
        </ResendButtonWrapper>
      </ResetFormBody>
    )
  }

  return (
    <LoginContainer>
      <LoginFormContainer>
        <LoginFormBg>
          <LoginFormHeader>
            <LoginFormTitle>{loginTexts.title}</LoginFormTitle>
            <LoginFormSubtitle>
              {LoginTexts.verificationCodeSent}
            </LoginFormSubtitle>
            <Email>{loginTexts.email}</Email>
            <LoginFormSubtitle>
              {LoginTexts.pleaseEnterTheCode}
            </LoginFormSubtitle>
          </LoginFormHeader>
          {renderForm()}
        </LoginFormBg>
      </LoginFormContainer>
    </LoginContainer>
  )
}
