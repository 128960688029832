import { device } from 'constants/media-query'
import { FunctionComponent } from 'react'
import styled from 'styled-components/macro'
import { IconContainer } from 'styles/generics'

interface Props {
  onClick?: () => void
  icon?: string
  children?: React.ReactNode
  bgColor?: string
  color?: string
  borderColor?: string
  mobileWidth?: string
  href?: string
  target?: string
  justify?: string
  gap?: string
}

const IconLink: FunctionComponent<Props> = ({
  onClick,
  icon,
  children,
  bgColor,
  color,
  borderColor,
  mobileWidth,
  href,
  target,
  justify,
  gap,
}) => {
  return (
    <Button
      onClick={onClick}
      href={href}
      bgColor={bgColor}
      borderColor={borderColor}
      color={color}
      mobileWidth={mobileWidth}
      target={target}
      justify={justify}
      gap={gap}
    >
      {icon && (
        <IconContainer>
          <Icon src={icon} alt="icon" />
        </IconContainer>
      )}
      {children}
    </Button>
  )
}

const Button = styled.a<{
  bgColor?: string
  borderColor?: string
  color?: string
  mobileWidth?: string
  justify?: string
  gap?: string
}>`
  @media ${device.mobileOnly} {
    width: 300px;
  }

  display: flex;
  gap: 28px;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: 'Atlas-AAA-Medium';
  width: 380px;
  height: 48px;
  background-color: #fff;
  border: none;
  border-radius: 2px;
  color: #000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
  ${({ color }) => color && `color: ${color} !important;`}
  ${({ borderColor }) => borderColor && `outline: 1px solid ${borderColor};`}
  ${({ mobileWidth }) => mobileWidth && `width: ${mobileWidth};`}
  ${({ gap }) => gap && `gap: ${gap};`}

  @media ${device.desktop} {
    justify-content: flex-start;
    padding-inline-start: 30px;
    width: 380px;
    ${({ justify }) =>
      justify &&
      `justify-content: ${justify}; padding-inline-start: 0; width: auto; padding: 0 20px;`}
  }
`

const Icon = styled.img``

export { IconLink }
