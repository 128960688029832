/* eslint-disable no-useless-escape */
export const postalCodeRegex = RegExp(/^[0-9]{7,9}$/i)
export const PhoneNumberRegex = RegExp(/^05\d\d{7}$/)
export const EmailConfirmationCodeRegex = RegExp(/^[0-9]{4,9}$/i)
export const EmailFormatRegex = RegExp(
  /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/i,
)
export const PasswordFormatRegex = RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/i,
)
export const ClientNumberRegex = RegExp(/^[0-9]{4,9}$/i)

export const isIsraeliIdNumber = (id: any) => {
  id = String(id).trim()
  if (id.length !== 9 || isNaN(id)) {
    return false
  }
  id = id.length < 9 ? ('00000000' + id).slice(-9) : id
  return (
    Array.from(id, Number).reduce((counter, digit, i) => {
      const step = digit * ((i % 2) + 1)
      return counter + (step > 9 ? step - 9 : step)
    }) %
      10 ===
    0
  )
}
