import styled from 'styled-components/macro'
import { Button } from '../../../../shared/components/button/Button'
import mobileLoginBackground from '../../../../assets/images/mobile_login_bg.png'
import desktopLoginBackground from '../../../../assets/images/desktop_login_bg.jpg'
import { device } from '../../../../constants/media-query'

export const LoginContainer = styled.div`
  width: 100%;
  height: 100%;
  background: url(${mobileLoginBackground}),
    linear-gradient(0deg, rgba(0, 0, 0, 0.76) 15.55%, rgba(0, 0, 0, 0) 56.53%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 25px;

  @media ${device.desktop} {
    background: url(${desktopLoginBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: row-reverse;
    padding: 0;
    width: 100vw;
    flex: 1;
  }
`

export const LoginFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  direction: rtl;

  @media ${device.desktop} {
    padding: 0;
    width: 33%;
  }
`

export const LoginFormBg = styled.div`
  background: #fff;
  padding: 60px 26px 40px;

  @media ${device.desktop} {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const LoginFormHeader = styled.div`
  padding: 0 0 35px;
  text-align: center;
  border-bottom: 1px solid #00000033;
  margin-bottom: 32px;
`

export const LoginFormTitle = styled.div`
  font-size: 36px;
  line-height: 41px;
  letter-spacing: -0.025em;
  color: #000000;
  margin-bottom: 8px;
`

export const LoginFormSubtitle = styled.div`
  font-size: 18px;
  line-height: 28px;
  letter-spacing: -0.025em;
  color: #757575;
`
export const Email = styled.div`
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.025em;
  font-weight: 600;
  color: black;
`

export const ResetFormBody = styled.form``

export const PasswordInstructionsContainer = styled.div`
  margin-bottom: 25px;
`

export const PasswordInstructionsTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: #000000;
  margin-bottom: 10px;
`

export const PasswordInstructions = styled.ul`
  margin: 0;
  padding: 0 15px 0 0;
`

export const PasswordInstructionItem = styled.li`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: #757575;
`

export const ButtonWrapper = styled(Button)`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: #007638;
  color: #fff;
  padding: 20px 0;
  border: none;
  margin-bottom: 40px;
  cursor: pointer;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
  }
`

export const ResendButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ResendButtonText = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
`

export const ResendButton = styled(Button)`
  border: none;
  background: none;
  font-weight: 700;
  text-decoration: underline;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #007638;
  cursor: pointer;

  &:disabled {
    opacity: 0.3;
  }
`
