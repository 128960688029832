export const DUPLICATE_ID = 'Duplicate Id'

export const errorStatusCodes = {
  CONFLICT: 409,
  I_AM_A_TEA_POT: 418,
  NOT_ACCEPTABLE: 406,
  UNAUTHORIZED: 401,
  PRECONDITION_REQUIRED: 428,
  INTERNAL_SERVER_ERROR: 500,
}
