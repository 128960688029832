import {
  Dispatch,
  FunctionComponent,
  MouseEvent,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import citiesJson from 'assets/data/cities.json'
import { useAppDispatch, useAppSelector } from '../../../../store/store'
import Dropdown from '../../../../shared/components/dropdown/Dropdown'
import { NumberInput } from '../../../../shared/components/number-input/NumberInput'
import { updateUser } from '../../../../store/reducers/userReducer'
import { FormRow } from 'pages/user-details/style'
import { IAddress } from 'types/interfaces/IAddress'
import { AddressesList } from '../addresses-list/AddressesList'
import { userService } from 'services/user.service'
import { Button } from 'styles/generics'
import styled from 'styled-components/macro'
import { theme } from 'styles/theme'

interface Props {
  setErrors: Dispatch<
    SetStateAction<{
      id?: boolean | undefined
      dob?: boolean | undefined
      phoneNum?: boolean | undefined
      firstName?: boolean | undefined
      lastName?: boolean | undefined
      address?:
        | {
            city?: boolean | undefined
            street?: boolean | undefined
            postalCode?: boolean | undefined
          }
        | undefined
      gender: boolean
    }>
  >
  errors: {
    id?: boolean | undefined
    dob?: boolean | undefined
    phoneNum?: boolean | undefined
    firstName?: boolean | undefined
    lastName?: boolean | undefined
    address?:
      | {
          city?: boolean | undefined
          street?: boolean | undefined
          postalCode?: boolean | undefined
        }
      | undefined
    gender: boolean
  }
  isEditAddress: boolean
  setIsEditAddress: Dispatch<SetStateAction<boolean>>
}

const AddressesForm: FunctionComponent<Props> = ({
  setErrors,
  errors,
  isEditAddress,
  setIsEditAddress,
}) => {
  const dispatch = useAppDispatch()
  const formData = useAppSelector((state) => state.user.personalDetails)
  const user = useAppSelector((state) => state.user)
  const [selectedAddress, setSelectedAddress] = useState<IAddress>({
    city: '',
    street: '',
    buildingNumber: '',
    postalCode: '',
  })

  const [cities, setCities] = useState<any[]>([])

  useEffect(() => {
    setCities(citiesJson)

    return () => {
      setIsEditAddress(false)
    }
  }, [])

  const handleCityDropdownChange = (value: string, error?: boolean) => {
    if (!formData) return
    setErrors({ ...errors, address: { ...errors.address, city: error } })

    setSelectedAddress((prevState) => ({
      ...prevState,
      city: value,
    }))

    const updatedAddresses = userService.updateOrAddAddress(
      formData,
      selectedAddress,
      'city',
      value,
    )

    dispatch(
      updateUser({
        ...user,
        personalDetails: {
          ...formData,
          addresses: updatedAddresses,
        },
      }),
    )
  }

  const handlePostalCodeChange = (value: string, error?: boolean) => {
    if (!formData) return
    setErrors({ ...errors, address: { ...errors.address, postalCode: error } })

    setSelectedAddress((prevState) => ({
      ...prevState,
      postalCode: value,
    }))

    const updatedAddresses = userService.updateOrAddAddress(
      formData,
      selectedAddress,
      'postalCode',
      value,
    )

    dispatch(
      updateUser({
        ...user,
        personalDetails: {
          ...formData,
          addresses: updatedAddresses,
        },
      }),
    )
  }

  const handleBuildingNumberChange = (value: string) => {
    if (!formData) return

    setSelectedAddress((prevState) => ({
      ...prevState,
      buildingNumber: value,
    }))

    const updatedAddresses = userService.updateOrAddAddress(
      formData,
      selectedAddress,
      'buildingNumber',
      value,
    )

    dispatch(
      updateUser({
        ...user,
        personalDetails: {
          ...formData,
          addresses: updatedAddresses,
        },
      }),
    )
  }

  const handleStreetDropdownChange = (value: string, error?: boolean) => {
    if (!formData) return
    setErrors({ ...errors, address: { ...errors.address, street: error } })

    setSelectedAddress((prevState) => ({
      ...prevState,
      street: value,
    }))

    const updatedAddresses = userService.updateOrAddAddress(
      formData,
      selectedAddress,
      'street',
      value,
    )

    dispatch(
      updateUser({
        ...user,
        personalDetails: {
          ...formData,
          addresses: updatedAddresses,
        },
      }),
    )
  }

  const onMakeMainAddressClick = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.preventDefault()
    if (!formData) return

    const updatedAddresses = formData.addresses
      ?.map((address) => {
        return {
          ...address,
          isMainAddress: address.id === selectedAddress.id,
        }
      })
      ?.sort((a) => {
        return a.isMainAddress ? -1 : 1
      })

    setSelectedAddress((prevState) => ({
      ...prevState,
      isMainAddress: true,
    }))

    dispatch(
      updateUser({
        ...user,
        personalDetails: {
          ...formData,
          addresses: updatedAddresses,
        },
      }),
    )
  }

  if (!formData) return null

  return (
    <>
      {!isEditAddress ? (
        <AddressesList
          addresses={formData.addresses}
          setSelectedAddress={setSelectedAddress}
          setIsEditAddress={setIsEditAddress}
        />
      ) : (
        <AddressesFromContainer>
          <Dropdown
            value={selectedAddress?.city || ''}
            disabled={false}
            hasError={errors.address?.city}
            errorText={'חובה לבחור עיר מהרשימה'}
            label={'עיר'}
            id={'city'}
            options={cities.map((city: any) => {
              return city.cityName
            })}
            callback={handleCityDropdownChange}
            color={theme.white}
            bgColor={theme.gray}
          />
          <Dropdown
            value={selectedAddress?.street || ''}
            disabled={false}
            hasError={errors.address?.city}
            errorText={'חובה לבחור רחוב מהרשימה'}
            label={'רחוב'}
            id={'street'}
            options={
              cities?.filter(
                (city: any) => city.cityName === selectedAddress?.city,
              )?.length
                ? cities?.filter(
                    (city: any) => city.cityName === selectedAddress?.city,
                  )[0].streets
                : []
            }
            callback={handleStreetDropdownChange}
            color={theme.white}
            bgColor={theme.gray}
          />
          <FormRow>
            <NumberInput
              value={selectedAddress?.buildingNumber ?? ''}
              regex={RegExp(/^[0-9]{1,9}$/i)}
              hasError={false}
              errorText={''}
              callback={handleBuildingNumberChange}
              label={"מס' בית"}
              id={'buildingNumber'}
              color={theme.white}
              bgColor={theme.gray}
            />
            <NumberInput
              value={selectedAddress?.postalCode || ''}
              regex={RegExp(/^[0-9]{4,9}$/i)}
              hasError={errors.address?.postalCode}
              errorText={'מיקוד לא תקין'}
              callback={handlePostalCodeChange}
              label={'מיקוד'}
              id={'postalCode'}
              color={theme.white}
              bgColor={theme.gray}
            />
          </FormRow>
          {!selectedAddress.isMainAddress && (
            <MakeMainAddressButton
              onClick={(ev) => {
                onMakeMainAddressClick(ev)
              }}
            >
              הפוך/י לכתובת ראשית
            </MakeMainAddressButton>
          )}
        </AddressesFromContainer>
      )}
    </>
  )
}

const AddressesFromContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  color: ${theme.white};
`

const MakeMainAddressButton = styled(Button)`
  color: ${({ theme }) => theme.primaryBlue};
  font-size: 20px;
  border-bottom: 1px solid ${({ theme }) => theme.primaryBlue};
  margin-bottom: 10px;
  align-self: center;
`

export { AddressesForm }
