import React, { useState } from 'react'
import {
  ButtonWrapper,
  LinkToLoginContainer,
  OverlayShadow,
  ToLoginContainer,
  WelcomeContainer,
  WelcomeContent,
  WelcomeDescription,
  WelcomeSubTitle,
  WelcomeTitle,
} from './style'
import { ESignupForms } from '../../../../types/enums/ESignupForms'
import { ISignupStepProps } from '../../../../types/interfaces/ISignupStepProps'
import { SignupTexts } from '../../../../constants/signup-texts'
import { Loader } from '../../../../shared/components/loader/loader'
import { Link } from 'react-router-dom'
import { EAppRoutes } from 'types/enums/EAppRoutes'

export const WelcomePage: React.FC<ISignupStepProps> = ({ callback }) => {
  const [isLoading, setIsLoading] = useState(false)

  return isLoading ? (
    <Loader />
  ) : (
    <WelcomeContainer>
      <OverlayShadow />
      <WelcomeContent>
        <WelcomeTitle>{SignupTexts.Welcome}</WelcomeTitle>
        <WelcomeSubTitle>{SignupTexts.YayNewWebsiteMounted}</WelcomeSubTitle>
        <WelcomeDescription>
          {SignupTexts.InOrderToGiveTheBestExperiencePleaseFillCertainDetails}
          <br />
          <br />
          {SignupTexts.ShallWeBegin}
        </WelcomeDescription>
      </WelcomeContent>
      <ButtonWrapper
        type="submit"
        className="buttonWrapper"
        text={SignupTexts.ToRegister}
        callback={() => {
          setIsLoading(true)
          callback(ESignupForms.signupForm)
          setIsLoading(false)
        }}
      />
      <ToLoginContainer>
        {SignupTexts.AlreadyAUser}
        <LinkToLoginContainer>
          <Link style={{ zIndex: '2' }} to={EAppRoutes.LOGIN}>
            {SignupTexts.ToLogIn}
          </Link>
        </LinkToLoginContainer>
      </ToLoginContainer>
    </WelcomeContainer>
  )
}
