import React, { useEffect, useState } from 'react'
import { WelcomePage } from './components/welcome/WelcomePage'
import { SignupForm } from './components/signup-form/SignupForm'
import { useAppDispatch, useAppSelector } from '../../store/store'
import { ESignupForms } from '../../types/enums/ESignupForms'
import { useLocation } from 'react-router-dom'
import { SuccessPage } from './components/success/SuccessPage'
import { showFooter, showNavbar } from '../../store/reducers/layoutReducer'

export const Signup = () => {
  const { isAuthenticated } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [form, setForm] = useState<ESignupForms>(
    location?.state?.signupStep ?? ESignupForms.welcomePage,
  )

  useEffect(() => {
    dispatch(showNavbar(false))
    dispatch(showFooter(false))
  }, [isAuthenticated, dispatch, form])

  const setFormType = (form: ESignupForms) => {
    setForm(form)
  }

  const renderForm = () => {
    switch (form) {
      case ESignupForms.welcomePage:
        return <WelcomePage callback={setFormType}></WelcomePage>
      case ESignupForms.signupForm:
        return <SignupForm callback={setFormType}></SignupForm>
      case ESignupForms.successPage:
        return <SuccessPage></SuccessPage>
      default:
        return <WelcomePage callback={setFormType}></WelcomePage>
    }
  }

  return (
    <>
      {form === ESignupForms.welcomePage ||
      form === ESignupForms.successPage ? (
        renderForm()
      ) : (
        <SignupForm
          callback={() => setFormType(ESignupForms.successPage)}
        ></SignupForm>
      )}
    </>
  )
}
