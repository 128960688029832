import styled from 'styled-components/macro'

export const RadioButtonContainer = styled.div`
  position: relative;
  margin-bottom: 25px;
  margin-left: 15px;
  transition: all 0.3s ease-in-out;
  color: rgba(0, 0, 0, 0.46);
  font-family: Atlas-AAA-Regular, sans-serif;
  font-weight: 400;
  &.selected {
    font-weight: 700;
    font-family: Atlas-AAA-Bold, sans-serif;
    color: #000000;
  }
`

export const Label = styled.label`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
`

export const Input = styled.input`
  width: 100%;
  opacity: 0;
  outline: none;
  position: absolute;
  cursor: pointer;
`
