import { FunctionComponent } from 'react'
import styled from 'styled-components/macro'

interface Props {
  mb?: string
  mt?: string
  bgColor?: string
}

const HorizontalLine: FunctionComponent<Props> = ({ mb, mt, bgColor }) => {
  return <HorizontalLineDiv mb={mb} mt={mt} bgColor={bgColor} />
}

const HorizontalLineDiv = styled.div<{
  mb?: string
  mt?: string
  bgColor?: string
}>`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.grayBorder};
  ${({ mt }) => mt && `margin-top: ${mt};`}
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
`

export { HorizontalLine }
