import styled from 'styled-components/macro'
import { Button } from '../../../../../../shared/components/button/Button'

export const ButtonWrapper = styled(Button)`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: #007638;
  color: #fff;
  padding: 20px 0;
  border: none;
  cursor: pointer;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
  }
`
