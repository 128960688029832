import styled from 'styled-components/macro'
import mobileSignupBackground from '../../../../assets/images/mobile_signupform_bg.png'
import desktopSignupBackground from '../../../../assets/images/desktop_signupform_bg.png'
import { device } from '../../../../constants/media-query'

export const SignupContainer = styled.form`
  width: 100vw;
  height: 100vh;
  padding-bottom: 60px;

  @media ${device.desktop} {
    display: flex;
    flex-grow: 1;
    height: auto;
    padding-bottom: 0px;
  }
`

export const SignupFormBg = styled.div`
  background: url(${mobileSignupBackground});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 120px;

  @media ${device.desktop} {
    background: url(${desktopSignupBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-grow: 1;
    padding: 0;
    height: auto;
  }
`

export const SignupFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding: 60px 25px 0;
  position: relative;

  @media ${device.desktop} {
    width: 45%;
    justify-content: center;
  }
`

export const SignupFormHeader = styled.div`
  padding: 0 0 32px;
  text-align: center;
  border-bottom: 1px solid #00000033;
  margin-bottom: 32px;
`

export const SignupFormTitle = styled.div`
  font-weight: 500;
  font-size: 36px;
  line-height: 41px;
  letter-spacing: -0.025em;
  color: #000000;
`

export const SignupFormSubtitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.025em;
  color: #757575;
  white-space: pre-line;
`

export const SignupFormBody = styled.div``

export const LogoutButton = styled.button`
  position: absolute;
  top: 130px;
  right: 10px;
  border: 0;
  background: none;
  cursor: pointer;
  color: #284d2f;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: underline;
  text-decoration-color: #284d2f;
  text-underline-offset: 4px;
  gap: 4px;
  font-size: 16px;
  direction: rtl;
  z-index: 10;

  @media ${device.desktop} {
    top: 10px;
  }
`

export const DoorIcon = styled.img`
  width: 30px;
  height: 30px;
`
