import { useEffect } from 'react'
import { useAppLogout } from 'shared/helpers/useAppLogout'

export const Logout = () => {
  const logout = useAppLogout()

  useEffect(() => {
    logout()
  }, [])

  return <></>
}
