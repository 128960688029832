/* eslint-disable react/prop-types */
import { useIsAuthecticated } from 'shared/helpers/useIsAuthenticated'
import { IUserFrom } from 'types/interfaces/IUser'
import { useEffect, useState } from 'react'
import { getCurrentAuthenticatedUserInfo } from 'services/AuthService'
import { Navigate, useLocation } from 'react-router-dom'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { useAppSelector } from 'store/store'
import { userService } from 'services/user.service'

interface Props {
  children: React.ReactNode
}

const AuthRedirectRoute: React.FC<Props> = ({ children }) => {
  const isAuthenticated = useIsAuthecticated()
  const user = useAppSelector((state) => state.user)
  const [userToken, setUserToken] = useState<string | null | undefined>(null)
  const [userEmail, setUserEmail] = useState<string | null | undefined>(null)
  const location = useLocation()

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userInfo = await getCurrentAuthenticatedUserInfo()
      setUserToken(userInfo?.token)
      setUserEmail(userInfo?.email)
    }
    fetchUserInfo()
  }, [])

  if (location.pathname === EAppRoutes.SIGNUP) return <>{children}</>
  if (isAuthenticated === null) return null
  if (userToken === null || userEmail === null) return null

  // Handle cases when user is coming from another window
  if (isAuthenticated && window?.opener && userToken) {
    window?.opener.postMessage(
      {
        cognitoToken: userToken,
        email: userEmail,
        from: IUserFrom.LOGIN,
      },
      '*',
    )
    window.close()
    return null
  }

  const isMissingUserDetails = userService.isUserMissingDetails(user)

  return isAuthenticated && !isMissingUserDetails ? (
    <Navigate to={EAppRoutes.PERSONAL_AREA} replace />
  ) : (
    <>{children}</>
  )
}

export { AuthRedirectRoute }
