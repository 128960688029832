import moment from 'moment'
import 'moment/locale/he'

moment.locale('he')

const getHebrewMonthName = (monthNumber: number): string => {
  return moment()
    .month(monthNumber - 1)
    .format('MMMM')
}

export const dateService = { getHebrewMonthName }
