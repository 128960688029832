import styled from 'styled-components/macro'

export const MhfcIdInputContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
`

export const Label = styled.label`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
`

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 20px 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
  border: 1px solid #9c9c9c;

  &:focus {
    outline: none;
  }
`
