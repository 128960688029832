import React, { ChangeEvent } from 'react'
import { Input, Label, TextInputContainer } from './style'
import { ITextInputProps } from '../../../types/interfaces/ITextInputProps'
import { Error } from '../error/Error'
import { HeightPlaceHolderForError } from '../email-confirm-input/style'

export const TextInput: React.FC<ITextInputProps> = ({
  callback,
  hasError,
  errorText,
  className,
  minLength,
  label,
  id,
  value,
  disabled,
  title,
  color,
  bgColor,
  disabledOpacityStyle,
  isCancelMb = false,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const error = e.target.value.length < minLength
    callback(e.target.value, error)
  }

  return (
    <TextInputContainer
      className={`textInput ${className ?? ''} ${hasError ? 'error' : ''}`}
      isCancelMb={isCancelMb}
    >
      <Label htmlFor={id} color={color}>
        {label}
      </Label>
      <Input
        disabled={disabled ?? false}
        value={value}
        type="text"
        name={id}
        onChange={(e: any) => handleChange(e)}
        title={title}
        color={color}
        bgColor={bgColor}
        disabledOpacityStyle={disabledOpacityStyle}
      />
      <HeightPlaceHolderForError>
        <Error hasError={hasError} errorText={errorText}></Error>
      </HeightPlaceHolderForError>
    </TextInputContainer>
  )
}
