import React, { FormEvent, useEffect, useMemo, useState } from 'react'
import {
  BackButtonLink,
  MyProfileBackground,
  UserDetailsContainer,
  UserDetailsFormContainer,
  UserDetailsFormHeader,
  UserDetailsFormTitle,
} from './style'
import { PersonnalDetailsForm } from './components/user-details-form/PersonnalDetailsForm'
import {
  SubmitButtonWrapper,
  UserDetailsFormEditComment,
} from './components/user-details-form/style'
import { Loader } from 'shared/components/loader/loader'
import { useAppDispatch, useAppSelector } from 'store/store'
import {
  useGetSfUserByEmailQuery,
  useUpdateSfUserMutation,
} from 'store/api-slices/userSlice'
import { updateUser } from 'store/reducers/userReducer'
import mobileSignupBackground from '../../assets/images/profile-mobile.png'
import { MobileHero } from 'shared/components/mobile-hero/MobileHero'
import { SuccessCheckmark } from 'shared/components/success-checkmark/SuccessCheckmark'
import { AddressesForm } from './components/addresses-form/AddressesForm'
import { ContactDetailsForm } from './components/contact-details-form/ContactDetailsForm'
import { showFooter, showNavbar } from 'store/reducers/layoutReducer'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import styled from 'styled-components/macro'
import { IUserFrom } from 'types/interfaces/IUser'
import { ErrorTexts } from 'constants/signup-texts'
import { DUPLICATE_ID, errorStatusCodes } from 'constants/errors'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'

export const UserDetails = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [tabIndex, setTabIndex] = React.useState(0)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [isSuccess, setIsSuccess] = useState<boolean>(false)
  const [isEditAddress, setIsEditAddress] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const [showUserIdExistsError, setShowUserIdExistsError] =
    useState<boolean>(false)
  const [errors, setErrors] = useState<{
    id?: boolean
    dob?: boolean
    phoneNum?: boolean
    firstName?: boolean
    lastName?: boolean
    address?: { city?: boolean; street?: boolean; postalCode?: boolean }
    gender: boolean
  }>({
    id: showUserIdExistsError,
    dob: false,
    phoneNum: false,
    firstName: false,
    lastName: false,
    address: { city: false, street: false, postalCode: false },
    gender: false,
  })

  const idErrorText = useMemo(() => {
    return showUserIdExistsError
      ? ErrorTexts.IdIsAlreadyInSystem
      : ErrorTexts.MisparZehutIsNotValid
  }, [showUserIdExistsError])

  const [updateSfUser] = useUpdateSfUserMutation()
  const getSfUser = useGetSfUserByEmailQuery({})
  const { data, error, isFetching, isError } = getSfUser
  const formData = useAppSelector((state) => state.user.personalDetails)
  const user = useAppSelector((state) => state.user)

  useEffect(() => {
    dispatch(showNavbar(true))
    dispatch(showFooter(true))

    return () => {
      dispatch(showNavbar(false))
      dispatch(showFooter(false))
    }
  }, [])

  useEffect(() => {
    if (
      error &&
      (error as FetchBaseQueryError).status === errorStatusCodes.NOT_ACCEPTABLE
    )
      navigate(EAppRoutes.PENDING_PAGE)

    if (!isFetching && !isError) {
      if (data?.personalDetails) {
        setIsLoading(false)
        dispatch(updateUser({ ...user, personalDetails: data.personalDetails }))
      }
    } else if (isError) {
      setIsLoading(false)
      console.log('[UserDetails] error getSfUser: ', error)
    }
  }, [getSfUser])

  useEffect(() => {
    setIsSuccess(false)
    if (
      formData?.id &&
      formData?.dob &&
      formData?.phoneNum &&
      formData?.firstName &&
      formData?.lastName &&
      formData?.addresses &&
      formData?.addresses[0]?.city &&
      formData?.addresses[0]?.street &&
      formData?.addresses[0]?.postalCode &&
      formData?.gender &&
      !errors.id &&
      !errors.dob &&
      !errors.phoneNum &&
      !errors.firstName &&
      !errors.lastName &&
      !errors.address?.city &&
      !errors.address?.street &&
      !errors.address?.postalCode &&
      !errors.gender
    ) {
      setIsDisabled(false)
    } else if (!isDisabled) {
      setIsDisabled(true)
    }
  }, [dispatch, formData, errors])

  const handleSubmit = async (event?: FormEvent) => {
    setIsLoading(true)
    setIsDisabled(true)
    if (event) event.preventDefault()
    try {
      const res = await updateSfUser(formData)

      if ('error' in res) {
        if (res.error && 'data' in res.error) {
          const errorData = res.error.data as { errorToClient: string }
          if (errorData.errorToClient === DUPLICATE_ID) {
            setErrors((prevErrors) => ({ ...prevErrors, id: true }))
            setShowUserIdExistsError(true)
            return
          }
        }
      }

      if ('data' in res && res.data) {
        if (window?.opener && searchParams.get('missingId')) {
          window.opener.postMessage(
            {
              cognitoToken: user?.token,
              email: user?.email,
              from: IUserFrom.LOGIN,
            },
            '*',
          )
          window.close()
          return
        }
        dispatch(updateUser({ ...user, personalDetails: res.data }))
        setIsSuccess(true)
      }
    } catch (error) {
      console.log('[UserDetails - handleSubmit] error: ', error)
    } finally {
      setIsLoading(false)
    }
  }

  const onTabChange = (index: number) => {
    setTabIndex(index)
    setIsSuccess(false)
  }

  const renderProfileTab = () => {
    switch (tabIndex) {
      case 0:
        return (
          <PersonnalDetailsForm
            errors={errors}
            setErrors={setErrors}
            showUserIdExistsError={showUserIdExistsError}
            idErrorText={idErrorText}
            setShowUserIdExistsError={setShowUserIdExistsError}
          />
        )
      case 1:
        return (
          <AddressesForm
            setErrors={setErrors}
            errors={errors}
            isEditAddress={isEditAddress}
            setIsEditAddress={setIsEditAddress}
          />
        )
      case 2:
        return <ContactDetailsForm errors={errors} setErrors={setErrors} />
      default:
        return null
    }
  }

  return (
    <MyProfileBackground align="center">
      <MobileHero
        title="הפרופיל שלי"
        imgUrl={mobileSignupBackground}
        textAlign={'right'}
      />
      <UserDetailsContainer>
        <UserDetailsFormHeader>
          <UserDetailsFormTitle
            onClick={() => onTabChange(0)}
            isSelected={tabIndex === 0}
          >
            {'פרטים אישיים'}
          </UserDetailsFormTitle>
          <UserDetailsFormTitle
            onClick={() => onTabChange(1)}
            isSelected={tabIndex === 1}
          >
            {'כתובות'}
          </UserDetailsFormTitle>
          <UserDetailsFormTitle
            onClick={() => onTabChange(2)}
            isSelected={tabIndex === 2}
          >
            {'פרטי התקשרות'}
          </UserDetailsFormTitle>
        </UserDetailsFormHeader>
        <UserDetailsFormContainer isFullWidth={tabIndex === 1}>
          {isLoading ? <Loader /> : renderProfileTab()}
        </UserDetailsFormContainer>
        {!isEditAddress && (
          <UserDetailsFormEditComment shouldDisplay={tabIndex !== 1}>
            עבור שינוי הפרטים האישיים, יש לשלוח בקשה{' '}
            <CutomerEmailLink to="mailto:tickets4@maccabihaifafc.com">
              למייל המוקד תמיכה
            </CutomerEmailLink>{' '}
          </UserDetailsFormEditComment>
        )}
        {isSuccess ? (
          <SuccessCheckmark title="הפרטים נשמרו בהצלחה!" />
        ) : tabIndex === 0 || isEditAddress ? (
          <SubmitButtonWrapper
            disabled={isDisabled}
            className={'buttonWrapper'}
            text={'שמור/י'}
            callback={handleSubmit}
          />
        ) : null}
        <BackButtonLink to={EAppRoutes.PERSONAL_AREA}>
          חזרה לאזור האישי
        </BackButtonLink>
      </UserDetailsContainer>
    </MyProfileBackground>
  )
}

const CutomerEmailLink = styled(Link)`
  color: ${({ theme }) => theme.primaryBlue};
  text-decoration: underline;
`

export default UserDetails
