import { useEffect, useState } from 'react'
import { isUserAuthenticated } from '../../services/AuthService'
import { useAppDispatch } from '../../store/store'
import { userAuth } from '../../store/reducers/userReducer'
import { useGetSfUserByEmailQuery } from 'store/api-slices/userSlice'
import { AUTH_USER_TOKEN_KEY } from 'constants/auth'
import { cookieService } from 'services/cookie.service'

export const useIsAuthecticated = (): boolean | null => {
  const dispatch = useAppDispatch()
  const accessToken =
    cookieService.getCookie(AUTH_USER_TOKEN_KEY) ||
    localStorage.getItem(AUTH_USER_TOKEN_KEY)

  const [isAuthenticated, setIsAuthecticated] = useState<boolean | null>(null)

  const { data, error } = useGetSfUserByEmailQuery(
    { shouldValidateUser: true },
    {
      skip: !accessToken,
    },
  )

  useEffect(() => {
    isAuth()
  }, [dispatch, data])

  const isAuth = async () => {
    setIsAuthecticated((await isUserAuthenticated()) && !error)

    dispatch(userAuth({ isAuthenticated: isAuthenticated ?? false }))
  }

  return isAuthenticated
}
