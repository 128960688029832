// TODO Add links
export const footerLinks = [
  { to: 'https://mhaifafc.com/website-regulations', text: 'תקנון' },
  { to: process.env.REACT_APP_SHOP_URL || '/', text: 'החנות הרשמית' },
  {
    to: process.env.REACT_APP_BASE_ROBOTICKET_URL || '/',
    text: 'רכישת כרטיסים',
  },
  {
    to: 'https://mhaifafc.com/website-behavior-rules',
    text: 'חוקי התנהגות באתר',
  },
  { to: 'https://mhaifafc.com/website-terms-of-service', text: 'תקנון האתר' },
  { to: 'https://mhaifafc.com/privacy-policy', text: 'מדיניות פרטיות' },
  { to: 'https://mhaifafc.com/website-accessibility', text: 'הצהרת נגישות' },
  { to: 'https://mhaifafc.com/news', text: 'חדשות' },
  { to: 'https://mhaifafc.com/contact-us', text: 'צור/י קשר' },
  { to: 'https://mhaifafc.com/the-club?tab=דרושים', text: 'דרושים' },
]
