import { size } from 'constants/media-query'
import { useEffect, useState } from 'react'

export const useIsMobile = (minimumWidth = size.desktop) => {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window === 'undefined') return undefined

    const updateIsMobile = () => {
      setIsMobile(window.innerWidth < parseInt(size.desktop))
    }

    updateIsMobile()

    window.addEventListener('resize', updateIsMobile)
    return () => window.removeEventListener('resize', updateIsMobile)
  }, [minimumWidth])

  return isMobile
}
