import styled from 'styled-components/macro'
import LogoMHFC from 'assets/images/logo_mhfc.png'
import { device } from 'constants/media-query'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'

const FlexRow = styled.div<{
  gap?: number
  justify?: string
  align?: string
  mb?: string
  width?: string
  mt?: string
}>`
  display: flex;
  ${({ gap }) => gap && `gap: ${gap}px`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ align }) => align && `align-items: ${align}`};
  ${({ mb }) => mb && `margin-bottom: ${mb}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ mt }) => mt && `margin-top: ${mt}`};
`

const FlexColumn = styled.div<{
  gap?: number
  justify?: string
  align?: string
  width?: string
  mb?: string
}>`
  display: flex;
  flex-direction: column;
  ${({ gap }) => gap && `gap: ${gap}px`};
  ${({ justify }) => justify && `justify-content: ${justify}`};
  ${({ align }) => align && `align-items: ${align}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ mb }) => mb && `margin-bottom: ${mb}`};
`

const Heading1 = styled.h1<{
  align?: string
  alignSelf?: string
  fontSize?: string
  weight?: number
  color?: string
  lineHeight?: string
}>`
  ${({ weight = 400 }) => `font-weight: ${weight}`};
  ${({ fontSize = '32px' }) => `font-size: ${fontSize}`};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ alignSelf }) => alignSelf && `align-self: ${alignSelf}`};
  ${({ color }) => color && `color: ${color}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  margin: 0;
`

const Heading2 = styled.h2<{
  align?: string
  fontSize?: string
  weight?: number
  lineHeight?: string
  color?: string
}>`
  ${({ weight = 400 }) => `font-weight: ${weight}`};
  ${({ fontSize = '28px' }) => `font-size: ${fontSize}`};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight}`};
  ${({ color }) => color && `color: ${color}`};
  margin: 0;
`

const Heading3 = styled.h3<{
  align?: string
  fontSize?: string
  weight?: number
  fontFamily?: string
}>`
  ${({ weight = 400 }) => `font-weight: ${weight}`};
  ${({ fontSize = '24px' }) => `font-size: ${fontSize}`};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily}`};
  margin: 0;
`

const Heading4 = styled.h4<{
  align?: string
  fontSize?: string
  weight?: number
  fontFamily?: string
}>`
  ${({ weight = 400 }) => `font-weight: ${weight}`};
  ${({ fontSize = '20px' }) => `font-size: ${fontSize}`};
  ${({ align }) => align && `text-align: ${align}`};
  ${({ fontFamily = EAppFontFamilies.ATLAS_REGULAR }) =>
    fontFamily && `font-family: ${fontFamily}`};
  margin: 0;
`

const Text = styled.p<{
  fontSize?: string
  color?: string
  mb?: string
  pb?: string
  weight?: number
  fontFamily?: string
  maxWidth?: string
  width?: string
  textAlign?: string
}>`
  ${({ fontSize = '18px' }) => `font-size: ${fontSize}`};
  ${({ color }) => color && `color: ${color}`};
  margin: 0;
  ${({ mb }) => mb && `margin-bottom: ${mb}`};
  ${({ weight }) => weight && `font-weight: ${weight}`};
  font-family: 'Atlas-AAA-Regular', sans-serif;
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily}`};
  ${({ maxWidth }) => maxWidth && `max-width: ${maxWidth}`};
  ${({ width }) => width && `width: ${width}`};
  ${({ pb }) => pb && `padding-bottom: ${pb}`};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign}`};
`

const TextSmall = styled(Text)`
  font-size: 16px;
  line-height: 20px;
`

const TextRegular = styled(Text)`
  font-size: 16px;

  @media ${device.desktop} {
    font-size: 20px;
    ${({ fontSize }) => fontSize && `font-size: ${fontSize}`}
`

const Span = styled.span<{
  fontSize?: string
  color?: string
  fontFamily?: string
  display?: string
}>`
  ${({ fontSize = '18px' }) => `font-size: ${fontSize}`};
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily}`};
  ${({ color }) => color && `color: ${color}`};
  ${({ display }) => display && `display: ${display}`};
`

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  justify-content: center;
  align-items: center;
  font-family: 'Atlas-AAA', sans-serif;
`

const GreenButton = styled(Button)`
  background-color: ${({ theme }) => theme.primaryGreen};
  padding: 10px 20px;
  color: ${({ theme }) => theme.white};
  font-size: 20px;
  min-width: 100px;
`

const BorderButton = styled(Button)`
  border: 1px solid ${({ theme }) => theme.mhGreen};
  padding: 10px 20px;
  color: ${({ theme }) => theme.mhGreen};
  font-size: 20px;
  min-width: 100px;
`

const Image = styled.img<{ width?: string }>`
  width: 100%;
  ${({ width }) => width && `width: ${width}`};
`

const Logo = styled(Image).attrs({
  src: LogoMHFC,
  alt: 'Logo Maccabi Haifa',
})`
  width: 100%;
`

const IconContainer = styled.a<{ width?: string }>`
  ${({ width }) => width && `width: ${width}`};
  display: flex;
  justify-content: center;
  align-items: center;
`

export {
  FlexRow,
  FlexColumn,
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Text,
  TextRegular,
  Button,
  Image,
  Span,
  GreenButton,
  BorderButton,
  Logo,
  IconContainer,
  TextSmall,
}
