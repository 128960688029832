import React, { useEffect } from 'react'
import { NavbarContainer } from './style'
import { Button, FlexRow, Image, Logo } from 'styles/generics'
import styled from 'styled-components/macro'
import homeSvg from 'assets/icons/home.svg'
import whiteShirtSvg from 'assets/icons/white-shirt.svg'
import ticketsSvg from 'assets/images/tickets.svg'
import { Link, useLocation } from 'react-router-dom'
import { ProfileIcon } from '../profile-icon/ProfileIcon'
import { useAppDispatch, useAppSelector } from 'store/store'
import { EExternalLinks } from 'types/enums/EExternalLinks'
import { Popover } from 'antd'
import { LogoutPopoverContent } from './components/LogoutPopoverContent'
import { device } from 'constants/media-query'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { theme } from 'styles/theme'
import { setIsPersonalAreaWebView } from 'store/reducers/layoutReducer'

export const Navbar: React.FC = () => {
  const { personalDetails, token } = useAppSelector((state) => state.user)
  const { search } = useLocation()
  const isWebView = useAppSelector(
    (state) => state.layout.isPersonalAreaWebView,
  )
  const dispatch = useAppDispatch()
  useEffect(() => {
    const query = new URLSearchParams(search)
    dispatch(setIsPersonalAreaWebView(query.get('webView') === 'true'))
  }, [])

  const userInitials =
    (personalDetails?.firstName ? personalDetails.firstName[0] : '') +
    (personalDetails?.lastName ? personalDetails.lastName[0] : '')

  // TODO - add links
  return (
    <NavbarContainer>
      <MobileLogoContainer>
        <LogoLink to={EAppRoutes.PERSONAL_AREA}>
          <Logo />
        </LogoLink>
      </MobileLogoContainer>
      <DesktopNavbarContainer>
        <DesktopLogoContainer>
          <LogoLink to={EAppRoutes.PERSONAL_AREA}>
            <Logo />
          </LogoLink>
        </DesktopLogoContainer>

        {!isWebView && (
          <>
            <MobileNavbarIcon
              to={
                process.env.REACT_APP_BASE_OFFICIAL_WEBSITE_URL ||
                EExternalLinks.MHFC
              }
            >
              <Image src={homeSvg} />
            </MobileNavbarIcon>
            <MobileNavbarIcon
              to={process.env.REACT_APP_SHOP_URL || EExternalLinks.MHFC_SHOP}
            >
              <Image src={whiteShirtSvg} />
            </MobileNavbarIcon>
            <MobileNavbarIcon
              to={process.env.REACT_APP_BASE_ROBOTICKET_URL || '/'}
            >
              <Image src={ticketsSvg} />
            </MobileNavbarIcon>
          </>
        )}
        <DekstopNavLink target="_blank" to={EExternalLinks.MHFC}>
          לאתר הראשי
        </DekstopNavLink>
        <DekstopNavLink target="_blank" to={EExternalLinks.MHFC_SHOP}>
          החנות הרשמית
        </DekstopNavLink>
        <DekstopNavLink
          target="_blank"
          to={process.env.REACT_APP_BASE_ROBOTICKET_URL || '/'}
        >
          כרטיסים ומנויים
        </DekstopNavLink>
      </DesktopNavbarContainer>
      {token && !isWebView && (
        <LeftNavbar gap={22}>
          <Popover
            placement="bottomLeft"
            content={LogoutPopoverContent}
            trigger="click"
          >
            <Button>
              <ProfileIcon userInitials={userInitials} />
            </Button>
          </Popover>
        </LeftNavbar>
      )}
    </NavbarContainer>
  )
}

const MobileLogoContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 35%;
  width: 94px;

  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`

const LeftNavbar = styled(FlexRow)`
  align-items: center;
  padding-inline-end: 10px;

  ${({ theme }) => theme.media.sm`
    align-self: center;
    padding-bottom: 0;
    padding-inline-end: 35px;
  `}
`

const MobileNavbarIcon = styled(Link)`
  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`

const DesktopNavbarContainer = styled.div`
  display: flex;
  align-items: center;
  padding-inline-start: 10px;
  gap: 22px;
  color: ${theme.white};

  ${({ theme }) => theme.media.sm`
  justify-content: center;
  gap: 25px;
  `}

  ${({ theme }) => theme.media.md`
    gap: 40px;
  `}
`

const DesktopLogoContainer = styled.div`
  display: none;

  ${({ theme }) => theme.media.sm`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 101px;
    padding-bottom: 15px;
  `}

  ${({ theme }) => theme.media.md`
    margin-inline-start: 35px;`}
`

const DekstopNavLink = styled(Link)`
  @media ${device.mobileOnly} {
    display: none;
  }

  font-size: 24px;
  font-weight: 400;
  line-height: 28px;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
  color: ${({ theme }) => theme.white};
  &:hover {
    color: ${({ theme }) => theme.primaryGreen};
  }
`

const LogoLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
`
