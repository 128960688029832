import moment from 'moment'
import 'moment-timezone'

const isoDateStringToDotSeperatedDateString = (dateString: string): string => {
  const date = new Date(dateString)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0') // getMonth() is zero-based
  const year = date.getFullYear()

  return `${day}.${month}.${year}`
}

const convertSfDatetoIsraeliDate = (
  dateString: string | undefined,
  separator: string = '/',
): string => {
  if (!dateString) return ''
  return dateString.split('-').reverse().join(separator)
}

const serializeGreenplusBaseprice = (basePrice: string | undefined): string => {
  if (!basePrice) return ''
  const basePriceArray = basePrice.split('_')
  basePriceArray.shift()

  return basePriceArray
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

const convertDateTimeToHebrew = (dateTime: string | undefined): string => {
  if (!dateTime) return ''

  const hebrewLocale = 'he'
  const timeZone = 'Asia/Jerusalem'

  moment.locale(hebrewLocale)
  const zonedDate = moment.tz(dateTime, timeZone)

  const dayOfWeek = zonedDate.format('dddd')
  const day = zonedDate.format('D')
  const month = zonedDate.format('M')
  const year = zonedDate.format('YYYY')
  const time = zonedDate.format('HH:mm')

  return `${dayOfWeek}, ${day}.${month}.${year}, ${time}`
}

const convertDateToShortDate = (date: string | undefined): string => {
  if (!date) return ''
  const dateArray = date.split('.')
  const day = dateArray[0]
  const month = dateArray[1]
  const year = dateArray[2].slice(2)

  return `${day}.${month}.${year}`
}

export const utilsService = {
  isoDateStringToDotSeperatedDateString,
  convertSfDatetoIsraeliDate,
  serializeGreenplusBaseprice,
  convertDateTimeToHebrew,
  convertDateToShortDate,
}
