import { useEffect, useState } from 'react'

const inAppBrowserUserAgents = ['FBAN', 'FBAV', 'Instagram']

const useIsInAppBrowser = (): boolean => {
  const [isInAppBrowser, setIsInAppBrowser] = useState(false)

  useEffect(() => {
    // @ts-expect-error window.opera
    const userAgent = navigator.userAgent || navigator.vendor || window.opera
    if (
      inAppBrowserUserAgents.some((inAppBrowserUserAgent) =>
        userAgent.includes(inAppBrowserUserAgent),
      )
    ) {
      setIsInAppBrowser(true)
    }
  }, [])

  return isInAppBrowser
}

export { useIsInAppBrowser }
