import React from 'react'
import { useAppSelector } from '../../../../store/store'
import { IUserFrom } from '../../../../types/interfaces/IUser'
import {
  SignupTexts,
  successPageTextDesktop,
  successPageTextMobile,
} from '../../../../constants/signup-texts'
import { AUTH_USER_TOKEN_KEY } from '../../../../constants/auth'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import {
  ButtonWrapper,
  Container,
  Content,
  Description,
  HeaderIcon,
  Icon,
  MaccabiConnect,
  MhfcLogo,
  OverlayShadow,
  PageContainer,
  Title,
} from 'pages/pending-page/style'
import mhfcLogoSvg from 'assets/icons/mhfc-logo.svg'
import maccabiConnectSvg from 'assets/icons/maccabi-connect.svg'
import successIconSvg from 'assets/icons/green-checkmark.svg'

import { successPageTitle } from '../../../../constants/signup-texts'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import { Image } from 'styles/generics'
import { cookieService } from 'services/cookie.service'
import { useNavigate } from 'react-router-dom'

export const SuccessPage: React.FC<any> = () => {
  const user = useAppSelector((state) => state.user)
  const isMobile = useIsMobile()
  const navigate = useNavigate()

  return (
    <PageContainer>
      <OverlayShadow />
      <MhfcLogo
        src={mhfcLogoSvg}
        alt="mhfc-logo"
        onClick={() => {
          window?.opener
            ? window.close()
            : (window.location.href = `${process.env.REACT_APP_WINDOW_OPENER_URL}`)
        }}
      />
      <Container>
        <Description>
          <Title dir="rtl">
            {user?.personalDetails?.firstName
              ? successPageTitle(user?.personalDetails?.firstName)
              : successPageTitle()}
          </Title>
          <Content dir="rtl">
            {isMobile
              ? successPageTextMobile(user?.personalDetails?.gender)
              : successPageTextDesktop(user?.personalDetails?.gender)}
          </Content>
        </Description>
        <MaccabiConnect>
          <Image src={maccabiConnectSvg} alt="maccabi-connect" />
        </MaccabiConnect>
        <HeaderIcon>
          <Icon src={successIconSvg} alt="icon" />
        </HeaderIcon>
      </Container>
      <ButtonWrapper
        type="submit"
        className="buttonWrapper"
        text={SignupTexts.ToTheOfficialStore}
        callback={() => {
          if (window?.opener) {
            const mhfcToken =
              cookieService.getCookie(AUTH_USER_TOKEN_KEY) ||
              window.localStorage.getItem(AUTH_USER_TOKEN_KEY)
            window.opener.postMessage(
              {
                cognitoToken: mhfcToken,
                firstName: user.personalDetails?.firstName,
                lastName: user.personalDetails?.lastName,
                email: user.email,
                phone: user.personalDetails?.phoneNum,
                from: IUserFrom.SIGN_UP,
              },
              EAppRoutes.WILD_CARD_PATH,
            )
            window.close()
          } else {
            navigate(EAppRoutes.PERSONAL_AREA)
          }
        }}
      />
    </PageContainer>
  )
}
