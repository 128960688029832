import styled from 'styled-components/macro'
import { theme } from 'styles/theme'

export const IdInputContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;

  &.error {
    input {
      border: 1px solid #ff0000;
    }
  }
`

export const Label = styled.label<{
  color?: string
  disabledOpacityStyle?: boolean
}>`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
  ${({ color }) => color && `color: ${color};`}
  ${({ disabledOpacityStyle }) => disabledOpacityStyle && `opacity: 0.3;`}
`

export const Input = styled.input<{
  disabled: boolean
  disabledOpacityStyle?: boolean
  color?: string
  bgColor?: string
}>`
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  padding: 20px 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
  border: 1px solid #9c9c9c;
  ${({ color }) => color && `color: ${color};`}
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
  ${({ disabled }) => disabled && `cursor: not-allowed;`}
  ${({ disabledOpacityStyle }) =>
    disabledOpacityStyle && `background-color: ${theme.gray400}; opacity: 0.5;`}

  &:focus {
    outline: none;
  }
`
