import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { IUser } from '../../types/interfaces/IUser'

const initialState: IUser = {
  isAuthenticated: false,
  email: '',
  password: '',
  terms: false,
  newsletter: true,
  registeredCognito: false,
  personalDetails: {
    mhfcId: '',
    id: '',
    dob: undefined,
    phoneNum: undefined,
    firstName: '',
    lastName: '',
    addresses: [
      {
        city: '',
        street: '',
        buildingNumber: '',
        postalCode: '',
        isMainAddress: true,
      },
    ],
    gender: undefined,
  },
  creditAmount: null,
  memberships: [],
  greenpluses: [],
  children: [],
  friends: [],
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    userAuth(state, action: PayloadAction<IUser>) {
      const { isAuthenticated } = action.payload
      state.isAuthenticated = isAuthenticated
    },
    userLogin(state, action: PayloadAction<IUser>) {
      const { isAuthenticated, email, password, token } = action.payload
      state.isAuthenticated = isAuthenticated
      state.email = email
      state.password = password
      state.token = token
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    userLogout(state) {
      return initialState
    },
    updateUser(state, action: PayloadAction<IUser>) {
      const currentFirstName = state.personalDetails?.firstName || ''
      return {
        ...action.payload,
        personalDetails: {
          firstName: currentFirstName,
          ...action.payload?.personalDetails,
        },
      }
    },
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload
    },
  },
})

// Export actions and reducer.
export const {
  userAuth,
  userLogin,
  updateUser,
  setIsAuthenticated,
  userLogout,
} = userSlice.actions
export default userSlice.reducer
