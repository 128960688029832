import { FunctionComponent, useMemo } from 'react'
import styled from 'styled-components/macro'
import { SectionContainer } from './SectionContainer'
import { personalAreaTexts } from 'constants/personal-area-texts'
import {
  FlexColumn,
  FlexRow,
  Heading3,
  Heading4,
  TextRegular,
} from 'styles/generics'
import { HorizontalLine } from 'shared/components/horizontal-line/HorizontalLine'
import { theme } from 'styles/theme'
import { useAppSelector } from 'store/store'
import { IconLink } from 'shared/components/IconButton'
import { utilsService } from 'services/utils.service'
import { GreenplusIcon } from 'shared/components/GreenplusIcon'
import { device } from 'constants/media-query'

const MySeason: FunctionComponent = () => {
  const user = useAppSelector((state) => state.user)

  const [greenpluses, memberships, isEmptyList] = useMemo(() => {
    return [
      user?.greenpluses,
      user?.memberships,
      !user?.greenpluses?.length && !user?.memberships?.length,
    ]
  }, [user.greenpluses, user.memberships])

  const activeMembershipsAndGreenpluses = useMemo(() => {
    return (
      <>
        {greenpluses?.map((greenplus) => {
          return (
            <MembershipRow justify="space-between" key={greenplus.sfId}>
              <FlexRow gap={5}>
                <MembershipName>
                  {utilsService.serializeGreenplusBaseprice(
                    greenplus.basePrice,
                  )}
                </MembershipName>
                <GreenplusIcon />
              </FlexRow>
              <MembershipValidTo>
                {utilsService.convertSfDatetoIsraeliDate(
                  greenplus.expirationDate,
                )}
              </MembershipValidTo>
            </MembershipRow>
          )
        })}
        {memberships?.map((membership) => {
          return (
            <MembershipRow justify="space-between" key={membership.sfId}>
              <MembershipName>
                {personalAreaTexts.mySeason.seasonalMembership}
              </MembershipName>
              <MembershipValidTo>
                {utilsService.convertSfDatetoIsraeliDate(
                  membership.expirationDate,
                )}
              </MembershipValidTo>
            </MembershipRow>
          )
        })}
      </>
    )
  }, [greenpluses, memberships])

  return (
    <SectionContainer title={personalAreaTexts.mySeason.title}>
      <HeaderContainer justify="space-between">
        <HeadingTitle>
          {personalAreaTexts.general.activeMemberships}
        </HeadingTitle>
        <HeadingTitle>
          {isEmptyList ? '' : personalAreaTexts.general.validTo}
        </HeadingTitle>
      </HeaderContainer>
      <HorizontalLine mb="15px" bgColor={theme.white} />
      {isEmptyList ? (
        <FlexColumn align="center" gap={10}>
          <EmptyStateTitle>
            {personalAreaTexts.mySeason.emptyStateTitle}
          </EmptyStateTitle>
          <TextRegular mb="15px" textAlign="center" fontSize="24px">
            {personalAreaTexts.mySeason.emptyStateSeasonTickets}
          </TextRegular>
          <IconLink
            justify="center"
            target="_blank"
            href={process.env.REACT_APP_BASE_ROBOTICKET_URL}
            bgColor="transparent"
            borderColor={theme.white}
            color={theme.white}
            gap="5px"
          >
            {personalAreaTexts.mySeason.buyMembershipCTA}
            <GreenplusIcon />
          </IconLink>
        </FlexColumn>
      ) : (
        activeMembershipsAndGreenpluses
      )}
    </SectionContainer>
  )
}

const HeaderContainer = styled(FlexRow)`
  padding: 0 15px;
  margin-bottom: 12px;
`

const HeadingTitle = styled(Heading3)`
  font-family: 'Atlas-AAA-Medium';
  font-size: 20px;

  @media ${device.desktop} {
    font-size: 24px;
  }
`

const EmptyStateTitle = styled(Heading4)`
  font-size: 32px;
`

const MembershipRow = styled(FlexRow)`
  padding: 8px 0px;

  &:not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.grayBorder};
  }

  @media ${device.desktop} {
    padding: 5px 0px;
    margin-inline-start: 40px;
    margin-inline-end: 20px;
  }
`

const MembershipName = styled(TextRegular)`
  padding-inline-start: 15px;

  @media ${device.desktop} {
    padding-inline-start: 0px;
  }
`

const MembershipValidTo = styled(TextRegular)`
  padding-inline-end: 15px;

  @media ${device.desktop} {
    padding-inline-end: 0px;
  }
`

export { MySeason }
