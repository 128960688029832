import React, { useState } from 'react'
import {
  DoorIcon,
  LogoutButton,
  SignupContainer,
  SignupFormBg,
  SignupFormBody,
  SignupFormContainer,
  SignupFormHeader,
  SignupFormSubtitle,
  SignupFormTitle,
} from './style'
import { SignupFormEmail } from './components/signup-form-email/SignupFormEmail'
import { SignupFormEmailConfirmation } from './components/signup-form-email-confirm/SignupFormEmailConfirmation'
import { SignupFormPassword } from './components/signup-form-password/SignupFormPassword'
import { PersonalDetailsForm } from './components/personal-details-form/PersonalDetailsForm'
import { ESignupFormSteps } from '../../../../types/enums/ESignupFormSteps'
import { SignupTexts } from '../../../../constants/signup-texts'
import { ESignupForms } from '../../../../types/enums/ESignupForms'
import { ISignupStepProps } from '../../../../types/interfaces/ISignupStepProps'
import { useLocation } from 'react-router-dom'
import DoorIconSvg from 'assets/icons/door-exit.svg'
import { useAppLogout } from 'shared/helpers/useAppLogout'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import {
  Email,
  LoginFormSubtitle,
} from 'pages/login/components/reset-password/style'
import { LoginTexts } from 'constants/login-texts'
import { useAppSelector } from 'store/store'

export const SignupForm: React.FC<ISignupStepProps> = ({ callback }) => {
  const location = useLocation()
  const [step, setStep] = useState<ESignupFormSteps>(
    location?.state?.signupFormStep ?? ESignupFormSteps.email,
  )
  const logout = useAppLogout()
  const user = useAppSelector((state) => state.user)

  const [signupFormTexts, setSignupFormTexts] = useState<{
    title: string
    subtitle: string
    subtitleSecondRow?: string
  }>({
    title: SignupTexts.RegisterToStore,
    subtitle: SignupTexts.EmailConfirmationMessage,
  })

  const signupStep = () => {
    switch (step) {
      case ESignupFormSteps.email:
        return (
          <SignupFormEmail
            setSignupFormTexts={setSignupFormTexts}
            onFinishStep={onFinishStep}
          />
        )
      case ESignupFormSteps.password:
        return (
          <SignupFormPassword
            setSignupFormTexts={setSignupFormTexts}
            onFinishStep={onFinishStep}
          />
        )
      case ESignupFormSteps.emailConfirm:
        return (
          <SignupFormEmailConfirmation
            setSignupFormTexts={setSignupFormTexts}
            onFinishStep={onFinishStep}
          />
        )
      case ESignupFormSteps.personalDetails:
        return (
          <PersonalDetailsForm
            setSignupFormTexts={setSignupFormTexts}
            onFinishStep={() => callback(ESignupForms.signupForm)}
          />
        )
      default:
        return (
          <SignupFormEmail
            setSignupFormTexts={setSignupFormTexts}
            onFinishStep={onFinishStep}
          />
        )
    }
  }

  const onFinishStep = (nextStep: ESignupFormSteps) => {
    setStep(nextStep)
  }

  return (
    <>
      <LogoutButton
        onClick={(ev) => {
          ev.preventDefault()
          ev.stopPropagation()
          logout(EAppRoutes.LOGIN, false)
        }}
      >
        <DoorIcon src={DoorIconSvg} alt="logout-icon" />
        {SignupTexts.Exit}
      </LogoutButton>
      <SignupContainer>
        <SignupFormBg></SignupFormBg>
        <SignupFormContainer>
          <SignupFormHeader>
            {signupFormTexts.title && (
              <SignupFormTitle>{signupFormTexts.title}</SignupFormTitle>
            )}
            {step === ESignupFormSteps.emailConfirm ? (
              <>
                <LoginFormSubtitle>
                  {LoginTexts.verificationCodeSent}
                </LoginFormSubtitle>
                <Email>{user?.email ?? ''}</Email>
                <LoginFormSubtitle>
                  {LoginTexts.pleaseEnterTheCode}
                </LoginFormSubtitle>
              </>
            ) : (
              <>
                {signupFormTexts.subtitle && (
                  <SignupFormSubtitle>
                    {signupFormTexts.subtitle}
                  </SignupFormSubtitle>
                )}
                {signupFormTexts.subtitleSecondRow && (
                  <SignupFormSubtitle>
                    {signupFormTexts.subtitleSecondRow}
                  </SignupFormSubtitle>
                )}
              </>
            )}
          </SignupFormHeader>
          <SignupFormBody>{signupStep()}</SignupFormBody>
        </SignupFormContainer>
      </SignupContainer>
    </>
  )
}
