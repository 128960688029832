export const LoginTexts = {
  userMissingDetailsModal: {
    title: 'שלום!',
    description:
      'נתקלנו במספר פרטים חסרים בחשבון ה-Maccabi connect שלך. על מנת לספק את החוויה הטובה ביותר עבורך, נא להשלים את הפרטים בשלב הבא.',
    button: 'המשך',
  },
  verificationCodeSent: 'נשלח אליך קוד אימות לכתובת הדוא”ל:',
  pleaseEnterTheCode: 'אנא הזן/י את הקוד',
}
