import styled from 'styled-components/macro'
import { device } from '../../constants/media-query'
import { Button } from '../../shared/components/button/Button'
import { Link } from 'react-router-dom'
import { FlexColumn } from 'styles/generics'
import profileDesktopBg from '../../assets/images/desktop_welcome_bg.png'
import { theme } from 'styles/theme'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'

export const MyProfileBackground = styled(FlexColumn)`
  background: url(${profileDesktopBg});
  position: relative;

  ${({ theme }) => theme.media.sm`
    padding-bottom: 50px;
    padding-top: 50px;

    ::before {
      content: '';
      position: absolute;
      top: 0px;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }
  `}
`

export const UserDetailsContainer = styled.form`
  @media ${device.mobileOnly} {
    background-color: unset;
    backdrop-filter: blur(30px);
  }

  z-index: 10;
  width: 100vw;
  padding-bottom: 60px;
  direction: rtl;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${theme.opacityWhite};
  color: ${theme.white};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(10px);

  ${({ theme }) => theme.media.sm`
    max-width: 610px;
    border-radius: 10px;
    padding-bottom: 18px;
  `}
`

export const MobileHeroContainer = styled.div<{
  textAlign?: string
  fontSize?: string
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;

  .bg-title {
    position: absolute;
    color: ${({ theme: { white } }) => white};
    bottom: 20px;
    z-index: 20;
    ${({ fontSize }) => `font-size: ${fontSize || '40px'};`}
    font-weight: 700;
    inset-inline-end: 30px;
    ${({ textAlign }) =>
      textAlign && `text-align: ${textAlign}; inset-inline-end: unset;`}
    font-family: 'Atlas-AAA-Bold';
  }

  ${({ theme }) => theme.media.sm`
      margin-top: 70px;
      margin-bottom: 50px;
      .bg-title {
        bottom: 0;
        font-size: 48px;
        width: 90%;
      }
  `}
`

export const UserDetailsFormBg = styled.div<{ imgUrl?: string }>`
  ${({ imgUrl }) =>
    imgUrl &&
    `
  background-image: url(${imgUrl});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1;
  }
  `}
  width: 100%;
  height: 200px;
  position: relative;

  ${({ theme }) => theme.media.sm`
    height: 50px;
    background-image: none;
    display: none;

    ::before {
      display: none;
    }
  `}
`

export const UserDetailsFormContainer = styled.div<{ isFullWidth: boolean }>`
  display: flex;
  flex-direction: column;
  direction: rtl;
  padding: 0px 15px 0;
  min-height: 260px;
  align-items: center;
  width: 90%;
  max-width: 530px;

  @media ${device.desktop} {
    margin: 0 auto;
    max-width: 500px;
    ${({ isFullWidth }) => isFullWidth && `max-width: 530px;`}
  }
`

export const UserDetailsFormHeader = styled.div`
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  text-align: center;
  width: 100%;
  box-shadow: 0px 1px 0px 0px ${theme.white};
  margin-bottom: 28px;
  height: 64px;

  ${({ theme }) => theme.media.sm`
    margin-bottom: 50px;
  `}
`

export const UserDetailsFormTitle = styled.div<{ isSelected: boolean }>`
  display: flex;
  height: 62px;
  align-items: center;
  font-weight: 500;
  line-height: 41px;
  letter-spacing: -0.025em;
  transition: box-shadow 0.2s ease-in-out;
  font-family: ${EAppFontFamilies.ATLAS_BOLD};
  color: ${theme.white};
  cursor: pointer;
  ${({ isSelected }) =>
    isSelected && `box-shadow: 0px 4px 0px 0px ${theme.white}`};
`

export const UserDetailsFormSubtitle = styled.div`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.025em;
  color: ${({ theme: { grayText } }) => grayText};
`

export const FormRow = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`

export const BackButtonWrapper = styled(Button)<{ width?: string }>`
  font-family: 'Atlas-AAA', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  text-align: center;
  letter-spacing: -0.025em;
  color: ${({ theme: { grayText } }) => grayText};
  padding: 20px 0;
  border: none;
  width: ${({ width }) => (width ? `width: ${width}` : '100%')};
  background: none;
  cursor: pointer;
  border-bottom: 1px solid ${({ theme: { grayText } }) => grayText};
`

export const BackButtonLink = styled(Link)<{ mt?: string }>`
  margin-top: 28px;
  font-size: 20px;
  color: ${({ theme: { white } }) => white};
  text-decoration: none;
  border-bottom: 1px solid ${({ theme: { white } }) => white};
  ${({ mt }) => mt && `margin-top: ${mt};`}
  font-family: ${EAppFontFamilies.ATLAS_REGULAR};

  &:visited {
    color: ${({ theme: { white } }) => white};
  }
`
