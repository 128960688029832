/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
enum EAppRoutes {
  LOGIN = '/login',
  LOGOUT = '/logout',
  SIGNUP = '/signup',
  RESET_PASSWORD = '/reset-password',
  PERSONAL_AREA = '/personal-area',
  ORDER_HISTORY = '/personal-area/order-history',
  GREENPLUS = '/personal-area/greenplus',
  GREENPLUS_CREDIT = '/personal-area/greenplus/credit',
  GREENPLUS_DISCOUNTS = '/personal-area/greenplus/discounts',
  GREENPLUS_ORDERS = '/personal-area/greenplus/orders',
  PROFILE = '/user-details',
  WILD_CARD_PATH = '*',
  UNDER_CONSTRUCTION = '/under-construction',
  PENDING_PAGE = '/pending-page',
}

export { EAppRoutes }
