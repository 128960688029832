import React from 'react'
import { Error } from '../error/Error'
import { INumberInputProps } from '../../../types/interfaces/INumberInputProps'
import { Input, Label, NumberInputContainer, PostalCodeLink } from './style'
import { SignupTexts } from '../../../constants/signup-texts'
import { AppLinks } from '../../../constants/links'
import { HeightPlaceHolderForError } from '../email-confirm-input/style'

export const NumberInput: React.FC<INumberInputProps> = ({
  callback,
  hasError,
  errorText,
  className,
  regex,
  label,
  id,
  value,
  color,
  bgColor,
}) => {
  const handleChange = (e: any) => {
    e.preventDefault()
    let error
    if (regex) {
      error = !regex.test(e.target.value)
    }
    callback(e.target.value, error)
  }

  return (
    <>
      <NumberInputContainer
        className={`numberInput ${className ?? ''} ${hasError ? 'error' : ''}`}
      >
        <Label htmlFor={id} color={color}>
          {label}
        </Label>
        <Input
          color={color}
          bgColor={bgColor}
          defaultValue={value}
          type="text"
          name={id}
          onChange={(e: any) => handleChange(e)}
        ></Input>
        {label === SignupTexts.PostalCode ? (
          <PostalCodeLink
            color={color}
            href={AppLinks.PostalCodeSearchLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {SignupTexts.ToPostMailWebsite}{' '}
          </PostalCodeLink>
        ) : (
          ''
        )}
        <HeightPlaceHolderForError>
          <Error hasError={hasError} errorText={errorText}></Error>
        </HeightPlaceHolderForError>
      </NumberInputContainer>
    </>
  )
}
