import styled from 'styled-components/macro'
import { Button } from '../../../../../../../../shared/components/button/Button'

export const AddressWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .dropdown {
    width: 60%;
  }

  .numberInput {
    width: 37%;
  }
`

export const SubmitButtonWrapper = styled(Button)`
  font-family: 'Atlas-AAA', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: #007638;
  color: #fff;
  padding: 20px 0;
  border: none;
  width: 100%;
  cursor: pointer;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
  }
`

export const BackButtonWrapper = styled(Button)`
  font-family: 'Atlas-AAA', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  text-align: center;
  letter-spacing: -0.025em;
  color: #000000;
  padding: 20px 0;
  border: none;
  width: 100%;
  background: none;
  cursor: pointer;
`
