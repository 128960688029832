import React, { FormEvent, useEffect, useState } from 'react'
import { EmailConfirmInput } from '../../../../../../shared/components/email-confirm-input/EmailConfirmInput'
import { ESignupFormSteps } from '../../../../../../types/enums/ESignupFormSteps'
import {
  ButtonWrapper,
  ResendButton,
  ResendButtonText,
  ResendButtonWrapper,
} from './style'
import {
  confirmSignUp,
  resendConfirmSignUp,
  signIn,
} from '../../../../../../services/AuthService'
import { useAppDispatch, useAppSelector } from '../../../../../../store/store'
import {
  updateUser,
  userLogin,
} from '../../../../../../store/reducers/userReducer'
import { AUTH_USER_TOKEN_KEY } from '../../../../../../constants/auth'
import { Loader } from '../../../../../../shared/components/loader/loader'
import {
  ErrorTexts,
  SignupTexts,
  getEmailVerificationMessage,
} from '../../../../../../constants/signup-texts'
import {
  useCreateSfUserMutation,
  useUpdateSfUserMutation,
} from 'store/api-slices/userSlice'
import { errorStatusCodes } from 'constants/errors'

export const SignupFormEmailConfirmation: React.FC<any> = ({
  setSignupFormTexts,
  onFinishStep,
}) => {
  const user = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()
  const [code, setCode] = useState<string>('')
  const [hasError, setHasError] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [createSfUser, createSfUserRes] = useCreateSfUserMutation()
  const [updateSfUser] = useUpdateSfUserMutation()

  useEffect(() => {
    setSignupFormTexts({
      title: SignupTexts.EmailVerification,
      subtitle: getEmailVerificationMessage(user.email ?? ''),
    })
  }, [])

  const handleEmailConfirmChange = (value: any, error?: boolean) => {
    setCode(value)
    if (error) {
      if (!isDisabled) {
        setIsDisabled(true)
      }
    } else if (isDisabled) {
      setIsDisabled(false)
    }
  }

  const handleEmailConfirmSubmit = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    if (user.email && user.password) {
      const isConfirmed = await confirmSignUp(user.email.toLowerCase(), code)
      if (!isConfirmed) {
        setHasError(true)
        setIsLoading(false)
      } else {
        const signedUser = await signIn(user.email.toLowerCase(), user.password)
        if (signedUser) {
          if ('error' in signedUser || !signedUser.token) {
            // TODO error handling
            return
          }
          localStorage.setItem(AUTH_USER_TOKEN_KEY, signedUser.token!)
          dispatch(userLogin({ ...user, token: signedUser.token }))

          const response = await createSfUser({
            email: user.email,
            terms: user.terms,
            newsletter: user.newsletter,
            lastName: 'temp',
            registeredCognito: true,
          })

          if ('error' in response) {
            if (response.error) {
              if ('status' in response.error) {
                const statusCode = response?.error?.status
                if (statusCode === errorStatusCodes.INTERNAL_SERVER_ERROR)
                  await updateSfUser({
                    newsletter: user.newsletter,
                    registeredCognito: user.registeredCognito,
                    terms: user.terms,
                  })
                dispatch(
                  updateUser({
                    ...user,
                    newsletter: user.newsletter,
                    registeredCognito: user.registeredCognito,
                    terms: user.terms,
                  }),
                )
              }
            }
          } else {
            dispatch(
              updateUser({
                ...user,
                personalDetails: createSfUserRes.data?.personalDetails,
              }),
            )
          }
          setIsLoading(false)
          onFinishStep(ESignupFormSteps.personalDetails)
        }
      }
    }
  }

  const handleResendClick = async (event: FormEvent) => {
    setIsLoading(true)
    event.preventDefault()
    await resendConfirmSignUp(user?.email || '')
    setIsLoading(false)
  }

  const handleChangeEmailClick = async (event: FormEvent) => {
    setIsLoading(true)
    event.preventDefault()
    onFinishStep(ESignupFormSteps.email)
    setIsLoading(false)
    return
  }

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <>
      <EmailConfirmInput
        hasError={hasError}
        errorText={ErrorTexts.CodeIsNotValid}
        callback={handleEmailConfirmChange}
      ></EmailConfirmInput>
      <ButtonWrapper
        type="submit"
        disabled={isDisabled}
        className={`buttonWrapper ${isDisabled ? 'disabled' : ''}`}
        text={SignupTexts.Next}
        callback={handleEmailConfirmSubmit}
      />
      <ResendButtonWrapper>
        <ResendButtonText>{SignupTexts.DidNotReceive}</ResendButtonText>
        <ResendButton
          disabled={false}
          className={`resendButton`}
          text={SignupTexts.SendAgain}
          callback={handleResendClick}
        ></ResendButton>
        <ResendButtonText>או</ResendButtonText>
        <ResendButton
          disabled={false}
          className={`resendButton`}
          text={SignupTexts.ChangeEmail}
          callback={handleChangeEmailClick}
        ></ResendButton>
      </ResendButtonWrapper>
    </>
  )
}
