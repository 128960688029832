import { personalAreaTexts } from 'constants/personal-area-texts'
import { FunctionComponent } from 'react'
import { PlusWhiteIcon } from 'shared/components/PlusWhiteIcon'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import styled from 'styled-components/macro'
import { FlexRow, Text } from 'styles/generics'

interface Props {
  url?: string
}

const AddRelationshipLink: FunctionComponent<Props> = ({ url }) => {
  const isMobile = useIsMobile()

  return (
    <FlexRow align="center">
      <PlusWhiteIcon />
      <AddRelationLink href={url} target="_blank">
        {personalAreaTexts.myChildren.addAccountCTA}
      </AddRelationLink>
      <Text fontSize="16px">
        &nbsp;
        {isMobile
          ? personalAreaTexts.myChildren.addAccountCommentMobile
          : personalAreaTexts.myChildren.addAccountComment}
      </Text>
    </FlexRow>
  )
}

const AddRelationLink = styled.a`
  font-family: 'Atlas-AAA-Medium';
  text-decoration: underline;
`

export { AddRelationshipLink }
