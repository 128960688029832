import { configureStore } from '@reduxjs/toolkit'
import layoutReducer from './reducers/layoutReducer'
import userReducer from './reducers/userReducer'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { mhfcAuthApiSlice } from './apiSlice'

export const store = configureStore({
  reducer: {
    layout: layoutReducer,
    user: userReducer,
    [mhfcAuthApiSlice.reducerPath]: mhfcAuthApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(mhfcAuthApiSlice.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

// Use this instead of plain useSelector / useDispatch for store types.
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
