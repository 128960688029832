import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import personalAreaBgMobile from 'assets/images/personal-area-bg-mobile.png'
import personalAreaBg from 'assets/images/personal-area-bg.png'
import { errorStatusCodes } from 'constants/errors'
import { device } from 'constants/media-query'
import { personalAreaTexts } from 'constants/personal-area-texts'
import { FunctionComponent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Loader } from 'shared/components/loader/loader'
import { PlusWhiteIcon } from 'shared/components/PlusWhiteIcon'
import { Screen } from 'shared/components/screen/Screen'
import { useGetSfUserByEmailQuery } from 'store/api-slices/userSlice'
import { showFooter, showNavbar } from 'store/reducers/layoutReducer'
import { updateUser } from 'store/reducers/userReducer'
import { useAppDispatch, useAppSelector } from 'store/store'
import styled from 'styled-components/macro'
import { FlexRow, Text } from 'styles/generics'
import { theme } from 'styles/theme'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { CardLinks } from './components/CardLinks'
import { MyChildren } from './components/MyChildren'
import { MyFriends } from './components/MyFriends'
import { MySeason } from './components/MySeason'
import { PersonalAreaSummary } from './components/PersonalAreaSummary'

const PersonalArea: FunctionComponent = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const getSfUser = useGetSfUserByEmailQuery({ isExtraDetails: true })
  const { data, error, isFetching, isError, isLoading } = getSfUser
  const shouldDisplayAddRelationLink =
    !user?.children?.length || !user?.friends?.length

  useEffect(() => {
    dispatch(showNavbar(true))
    dispatch(showFooter(true))

    return () => {
      dispatch(showNavbar(false))
      dispatch(showFooter(false))
    }
  }, [])

  useEffect(() => {
    if (
      error &&
      (error as FetchBaseQueryError).status === errorStatusCodes.NOT_ACCEPTABLE
    )
      navigate(EAppRoutes.PENDING_PAGE)

    if (!isFetching && !isError) {
      if (data?.personalDetails) {
        dispatch(
          updateUser({
            ...user,
            ...data,
          }),
        )
      }
    } else if (isError) {
      console.log('[UserDetails] error getSfUser: ', error)
    }
  }, [getSfUser])

  return (
    <>
      <BackgroundContainer />
      <PersonalAreaContainer isLoading={isLoading}>
        <Screen />
        {isLoading ? (
          <Loader style={{ marginTop: '100px' }} />
        ) : (
          <>
            <PersonalAreaSummary />
            <MySeason />
            <MyChildren />
            <MyFriends />
            {shouldDisplayAddRelationLink && (
              <FlexRow mt={'-30px'} align="center" mb={'40px'}>
                <PlusWhiteIcon />
                <Text color={theme.white}>
                  {personalAreaTexts.general.addRelationship}{' '}
                  <AddRelationLink
                    target="_blank"
                    href={process.env.REACT_APP_BASE_ROBOTICKET_URL}
                  >
                    {personalAreaTexts.general.addRelationshipCTA}
                  </AddRelationLink>
                </Text>
              </FlexRow>
            )}
            <CardLinks />
          </>
        )}
      </PersonalAreaContainer>
    </>
  )
}

const BackgroundContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${personalAreaBgMobile});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center -50px;
  z-index: -1;

  @media ${device.desktop} {
    background: url(${personalAreaBg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center -180px;
  }
`

const PersonalAreaContainer = styled.main<{ isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 16px 0;
  direction: rtl;
  z-index: 10;
  position: relative;
  ${({ isLoading }) => isLoading && 'min-height: 100vh;'}

  @media ${device.desktop} {
    padding: 80px 30px 0;
  }

  @media ${device.large} {
    padding: 80px 100px 0;
  }

  @media ${device.xl} {
    padding: 80px 215px 0;
  }
`

const AddRelationLink = styled.a`
  font-family: 'Atlas-AAA-Medium';
  text-decoration: underline;
`

export { PersonalArea }
