import styled from 'styled-components/macro'

export const GenderInputContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;

  &.error {
    input {
      border: 1px solid #ff0000;
    }
  }
`

export const Label = styled.label`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
`

export const OptionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 40%;
`
