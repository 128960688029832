/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { EPersonalDetailsFormSteps } from '../../../../../../../../types/enums/EPersonalDetailsFormSteps'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/store'
import { IUser } from '../../../../../../../../types/interfaces/IUser'
import { IAddress } from '../../../../../../../../types/interfaces/IAddress'
import { updateUser } from '../../../../../../../../store/reducers/userReducer'
import { AddressWrapper, BackButtonWrapper, SubmitButtonWrapper } from './style'
import { TextInput } from '../../../../../../../../shared/components/text-input/TextInput'
import Dropdown from '../../../../../../../../shared/components/dropdown/Dropdown'
import citiesJson from '../../../../../../../../assets/data/cities.json'
import { NumberInput } from '../../../../../../../../shared/components/number-input/NumberInput'
import { GenderInput } from '../../../../../../../../shared/components/gender-input/GenderInput'
import { useUpdateSfUserMutation } from '../../../../../../../../store/api-slices/userSlice'
import Checkbox from '../../../../../../../../shared/components/checkbox/Checkbox'
import { Tooltip } from 'antd'
import styled from 'styled-components/macro'
import { Loader } from '../../../../../../../../shared/components/loader/loader'
import {
  ErrorTexts,
  SignupTexts,
} from '../../../../../../../../constants/signup-texts'
import { postalCodeRegex } from '../../../../../../../../shared/helpers/regex'
import { useIsInAppBrowser } from 'shared/helpers/useIsInAppBrowser'
import { InAppBrowserPopUp } from 'shared/components/InAppBrowserPopUp'

export const PersonalDetailsFormExtraPersonalInfoSecondStep: React.FC<any> = ({
  setSignupFormTexts,
  onNextClicked,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector<IUser>((state) => state.user)
  const [updateSfUser] = useUpdateSfUserMutation()
  const isInAppBrowser = useIsInAppBrowser()
  const [isInAppBrowserModalVisible, setIsInAppBrowserModalVisible] =
    useState(false)

  const [extraPersonalInfoFormData, setExtraPersonalInfoFormData] = useState<{
    firstName: string
    lastName: string
    addresses: IAddress[]
    gender?: 'Male' | 'Female' | 'Other'
    approveDetails: boolean
  }>({
    firstName: user?.personalDetails?.firstName ?? '',
    lastName: user?.personalDetails?.lastName ?? '',
    addresses: user?.personalDetails?.addresses?.length
      ? [
          {
            ...user.personalDetails.addresses[0],
            city: user.personalDetails.addresses[0]?.city ?? '',
            street: user.personalDetails.addresses[0]?.street ?? '',
            buildingNumber:
              user.personalDetails.addresses[0]?.buildingNumber ?? '',
            postalCode: user.personalDetails.addresses[0]?.postalCode ?? '',
            isMainAddress: true,
            id: user?.personalDetails?.addresses?.[0]?.id ?? undefined,
          },
        ]
      : [],
    gender: user?.personalDetails?.gender ?? undefined,
    approveDetails: false,
  })

  const [errors, setErrors] = useState<{
    firstName?: boolean
    lastName?: boolean
    address?: { city?: boolean; street?: boolean; postalCode?: boolean }
    gender: boolean
    approveDetails?: boolean
  }>({
    firstName: false,
    lastName: false,
    address: {
      city: false,
      street: false,
      postalCode: false,
    },
    gender: false,
    approveDetails: false,
  })
  const [cities, setCities] = useState<any[]>([])
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isChoseCity, setIsChoseCity] = useState<boolean>(
    !!extraPersonalInfoFormData?.addresses?.[0]?.city,
  )
  const [isChoseStreet, setIsChoseStreet] = useState<boolean>(
    !!extraPersonalInfoFormData?.addresses?.[0]?.street,
  )

  useEffect(() => {
    const city = extraPersonalInfoFormData?.addresses?.[0]?.city ?? ''
    const street = extraPersonalInfoFormData?.addresses?.[0]?.street ?? ''
    setIsChoseCity(!!city)
    setIsChoseStreet(!!street)
  }, [
    extraPersonalInfoFormData?.addresses[0]?.city,
    extraPersonalInfoFormData?.addresses[0]?.street,
  ])

  useEffect(() => {
    setCities(citiesJson)
    setSignupFormTexts({
      title: SignupTexts.YayAnotherStep,
      subtitle: SignupTexts.NoticeToFillAccurateDetails,
    })
  }, [])

  useEffect(() => {
    if (
      extraPersonalInfoFormData.firstName &&
      extraPersonalInfoFormData.lastName &&
      extraPersonalInfoFormData.approveDetails &&
      extraPersonalInfoFormData.addresses?.length &&
      extraPersonalInfoFormData.addresses[0]?.city &&
      isChoseCity &&
      extraPersonalInfoFormData.addresses[0]?.street &&
      isChoseStreet &&
      extraPersonalInfoFormData.addresses[0]?.postalCode &&
      extraPersonalInfoFormData.gender &&
      !errors.firstName &&
      !errors.lastName &&
      !errors.address?.city &&
      !errors.address?.street &&
      !errors.address?.postalCode &&
      !errors.gender &&
      !errors.approveDetails
    ) {
      setIsDisabled(false)
    } else if (!isDisabled) {
      setIsDisabled(true)
    }
  }, [
    extraPersonalInfoFormData.firstName,
    extraPersonalInfoFormData.lastName,
    extraPersonalInfoFormData.addresses,
    extraPersonalInfoFormData.gender,
    extraPersonalInfoFormData.approveDetails,
    errors,
    isDisabled,
    isChoseCity,
    isChoseStreet,
  ])

  const handleFirstNameChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, firstName: error })
    setExtraPersonalInfoFormData({
      ...extraPersonalInfoFormData,
      firstName: value,
    })
  }

  const handleLastNameChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, lastName: error })
    setExtraPersonalInfoFormData({
      ...extraPersonalInfoFormData,
      lastName: value,
    })
  }

  const handleCityDropdownChange = (value: any, error?: boolean) => {
    const selectedCity = cities.find((city) => city.cityName === value)

    setErrors({ ...errors, address: { ...errors.address, city: error } })
    setExtraPersonalInfoFormData({
      ...extraPersonalInfoFormData,
      addresses: extraPersonalInfoFormData?.addresses?.length
        ? [
            {
              ...extraPersonalInfoFormData.addresses[0],
              city: value,
              street:
                selectedCity &&
                selectedCity.streets.length === 1 &&
                selectedCity.streets[0] === SignupTexts.NoStreet
                  ? SignupTexts.NoStreet
                  : '',
            },
          ]
        : [
            {
              city: value,
              street:
                selectedCity &&
                selectedCity.streets.length === 1 &&
                selectedCity.streets[0] === SignupTexts.NoStreet
                  ? SignupTexts.NoStreet
                  : '',
              postalCode: '',
              buildingNumber: '',
              isMainAddress: true,
            },
          ],
    })
  }

  const handleStreetDropdownChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, address: { ...errors.address, street: error } })
    setExtraPersonalInfoFormData({
      ...extraPersonalInfoFormData,
      addresses: extraPersonalInfoFormData.addresses?.length
        ? [{ ...extraPersonalInfoFormData.addresses[0], street: value }]
        : [
            {
              city: '',
              street: value,
              postalCode: '',
              buildingNumber: '',
              isMainAddress: true,
            },
          ],
    })
    setIsChoseStreet(true)
  }

  const handlePostalCodeChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, address: { ...errors.address, postalCode: error } })
    setExtraPersonalInfoFormData({
      ...extraPersonalInfoFormData,
      addresses: extraPersonalInfoFormData.addresses?.length
        ? [{ ...extraPersonalInfoFormData.addresses[0], postalCode: value }]
        : [],
    })
  }

  const handleBuildingNumberChange = (
    value: any,
    //error?: boolean
  ) => {
    setExtraPersonalInfoFormData({
      ...extraPersonalInfoFormData,
      addresses: extraPersonalInfoFormData.addresses?.length
        ? [{ ...extraPersonalInfoFormData.addresses[0], buildingNumber: value }]
        : [],
    })
  }

  const handleGenderChange = (
    value: 'Male' | 'Female' | 'Other',
    // error?: boolean,
  ) => {
    setExtraPersonalInfoFormData({
      ...extraPersonalInfoFormData,
      gender: value,
    })
  }

  const handleApproveDetailsChange = () => {
    setErrors({
      ...errors,
      approveDetails: extraPersonalInfoFormData.approveDetails,
    })
    setExtraPersonalInfoFormData({
      ...extraPersonalInfoFormData,
      approveDetails: !extraPersonalInfoFormData.approveDetails,
    })
  }

  const nextButtonClicked = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)

    dispatch(
      updateUser({
        ...user,
        personalDetails: {
          ...user.personalDetails,
          firstName: extraPersonalInfoFormData.firstName,
          lastName: extraPersonalInfoFormData.lastName,
          addresses: extraPersonalInfoFormData.addresses,
          gender: extraPersonalInfoFormData.gender,
        },
      }),
    )

    const sfUserToUpdate = {
      firstName: extraPersonalInfoFormData.firstName,
      lastName: extraPersonalInfoFormData.lastName,
      addresses: extraPersonalInfoFormData.addresses,
      gender: extraPersonalInfoFormData.gender,
      registerToShop: true,
    }

    await updateSfUser(sfUserToUpdate)
    setIsLoading(false)

    if (isInAppBrowser) setIsInAppBrowserModalVisible(true)
    else onNextClicked(EPersonalDetailsFormSteps.thirdStep)
  }

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <>
      <InAppBrowserPopUp isModalOpen={isInAppBrowserModalVisible} />
      <h4>{SignupTexts.SignupFormConfirmAllDetailsAreValid}</h4>
      <Tooltip
        title={
          user?.personalDetails?.firstName
            ? SignupTexts.ToChangeDetailsContactCustomerService
            : ''
        }
      >
        <InputOverlayContainer>
          {user?.personalDetails?.firstName && <Overlay />}
          <TextInput
            value={extraPersonalInfoFormData.firstName}
            minLength={2}
            hasError={errors.firstName}
            errorText={ErrorTexts.FirstNameIsNotValid}
            callback={handleFirstNameChange}
            label={SignupTexts.FirstName}
            id={'firstName'}
            disabled={user?.personalDetails?.firstName ? true : false}
          />
        </InputOverlayContainer>
      </Tooltip>
      <Tooltip
        title={
          user?.personalDetails?.lastName
            ? SignupTexts.ToChangeDetailsContactCustomerService
            : ''
        }
      >
        <InputOverlayContainer>
          {user?.personalDetails?.lastName && <Overlay />}
          <TextInput
            value={
              extraPersonalInfoFormData.lastName !== 'temp'
                ? extraPersonalInfoFormData.lastName
                : ''
            }
            minLength={2}
            hasError={errors.lastName}
            errorText={ErrorTexts.LastNameIsNotValid}
            callback={handleLastNameChange}
            label={SignupTexts.LastName}
            id={'lastName'}
            disabled={user?.personalDetails?.lastName ? true : false}
          />
        </InputOverlayContainer>
      </Tooltip>
      <AddressWrapper>
        <Dropdown
          value={
            extraPersonalInfoFormData.addresses?.length
              ? extraPersonalInfoFormData.addresses[0]?.city ?? ''
              : ''
          }
          autocomplete={false}
          disabled={false}
          hasError={errors.address?.city}
          errorText={ErrorTexts.MustChooseCityFromTheGivenList}
          label={SignupTexts.City}
          id={'city'}
          options={cities.map((city: any) => {
            return city.cityName
          })}
          callback={handleCityDropdownChange}
          dropdownType={'City'}
          setIsChoseCity={setIsChoseCity}
        ></Dropdown>
        <NumberInput
          value={
            extraPersonalInfoFormData.addresses?.length
              ? extraPersonalInfoFormData.addresses[0]?.postalCode ?? ''
              : ''
          }
          regex={postalCodeRegex}
          hasError={errors.address?.postalCode}
          errorText={ErrorTexts.PostalCodeIsNotValid}
          callback={handlePostalCodeChange}
          label={SignupTexts.PostalCode}
          id={'postalCode'}
        ></NumberInput>
        <Dropdown
          autocomplete={false}
          value={
            extraPersonalInfoFormData.addresses?.length
              ? extraPersonalInfoFormData.addresses[0]?.street ?? ''
              : ''
          }
          disabled={
            (extraPersonalInfoFormData.addresses?.length &&
              !extraPersonalInfoFormData.addresses[0]?.city) ||
            false
          }
          hasError={errors.address?.city}
          errorText={ErrorTexts.MustChooseStreetFromTheGivenList}
          label={SignupTexts.Street}
          id={'street'}
          options={
            cities?.filter(
              (city: any) =>
                extraPersonalInfoFormData.addresses?.length &&
                city.cityName === extraPersonalInfoFormData.addresses[0].city,
            )?.length
              ? cities?.filter(
                  (city: any) =>
                    extraPersonalInfoFormData.addresses?.length &&
                    city.cityName ===
                      extraPersonalInfoFormData.addresses[0].city,
                )[0].streets
              : []
          }
          callback={handleStreetDropdownChange}
          dropdownType={'Street'}
          setIsChoseStreet={setIsChoseStreet}
        ></Dropdown>
        <NumberInput
          value={
            extraPersonalInfoFormData.addresses?.length
              ? extraPersonalInfoFormData.addresses[0]?.buildingNumber ?? ''
              : ''
          }
          regex={RegExp(/^[0-9]{1,9}$/i)}
          hasError={false}
          callback={handleBuildingNumberChange}
          label={SignupTexts.BuildingNumber}
          id={'buildingNumber'}
        ></NumberInput>
      </AddressWrapper>
      <GenderInput
        value={extraPersonalInfoFormData.gender}
        callback={handleGenderChange}
      ></GenderInput>
      <Checkbox
        hasError={errors.approveDetails}
        errorText={ErrorTexts.MustApproveAllDetails}
        callback={handleApproveDetailsChange}
        id={'approveDetails'}
        label={SignupTexts.ReadAndConfirmedAllDetails}
        checked={extraPersonalInfoFormData.approveDetails}
      ></Checkbox>
      <SubmitButtonWrapper
        type="submit"
        disabled={isDisabled}
        className={`buttonWrapper ${isDisabled ? 'disabled' : ''}`}
        text={SignupTexts.Next}
        callback={(e: any) => nextButtonClicked(e)}
      />
      <BackButtonWrapper
        disabled={false}
        className={`buttonWrapper ${isDisabled ? 'disabled' : ''}`}
        text={SignupTexts.Previous}
        callback={() => onNextClicked(EPersonalDetailsFormSteps.firstStep)}
      />
    </>
  )
}

const InputOverlayContainer = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: not-allowed;
  z-index: 1;
  background-color: transparent;
`
