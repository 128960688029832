import React, { Fragment, useEffect, useState } from 'react'
import { Error } from '../error/Error'
import {
  DropdownContainer,
  Input,
  Label,
  NoOption,
  OptionItem,
  OptionsList,
} from './style'
import { IDropdownProps } from '../../../types/interfaces/IDropdownProps'
import { useAppDispatch, useAppSelector } from '../../../store/store'
import { updateUser } from '../../../store/reducers/userReducer'
import { SignupTexts } from '../../../constants/signup-texts'
import { HeightPlaceHolderForError } from '../email-confirm-input/style'

export const Dropdown: React.FC<IDropdownProps> = ({
  hasError,
  errorText,
  callback,
  id,
  label,
  options,
  className,
  disabled,
  value,
  dropdownType,
  setIsChoseStreet,
  setIsChoseCity,
  autocomplete = true,
  color,
  bgColor,
}) => {
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const [filteredOptions, setFilteredOptions] = useState<string[]>([])
  const [userInput, setUserInput] = useState<string>(value ?? '')
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const formData = useAppSelector((state) => state.user.personalDetails)

  useEffect(() => {
    setUserInput(value ?? '')
  }, [value])

  const onClick = (e: any) => {
    setFilteredOptions(filteredOptions)
    setShowOptions(false)
    setUserInput(e.target.innerText)
    callback(e.target.innerText, false)
    if (setIsChoseCity) {
      setIsChoseCity(true)
    }
    if (setIsChoseStreet) {
      setIsChoseStreet(true)
    }
  }

  const onChange = (e: any) => {
    const userInput = e.target.value
    switch (dropdownType) {
      case 'City':
        if (setIsChoseCity) {
          setIsChoseCity(false)
        }
        dispatch(
          updateUser({
            ...user,
            personalDetails: {
              ...formData,
              addresses: formData?.addresses?.length
                ? [
                    {
                      ...formData?.addresses[0],
                      city: userInput,
                    },
                  ]
                : [
                    {
                      city: userInput,
                      street: '',
                      buildingNumber: '',
                      postalCode: '',
                      isMainAddress: true,
                    },
                  ],
            },
          }),
        )

        break

      case 'Street':
        if (setIsChoseStreet) setIsChoseStreet(false)
        dispatch(
          updateUser({
            ...user,
            personalDetails: {
              ...formData,
              addresses: formData?.addresses?.length
                ? [
                    {
                      ...formData?.addresses[0],
                      street: userInput,
                    },
                  ]
                : [
                    {
                      city: '',
                      street: userInput,
                      buildingNumber: '',
                      postalCode: '',
                      isMainAddress: true,
                    },
                  ],
            },
          }),
        )
        break
    }
    // Filter our suggestions that don't contain the user's input
    const filteredOptions = options.filter(
      (option: any) =>
        option.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
    )
    setFilteredOptions(filteredOptions)
    setShowOptions(true)
    setUserInput(userInput)
  }

  const renderOptionsListComponent = () => {
    return showOptions && userInput ? (
      filteredOptions.length ? (
        <OptionsList>
          {filteredOptions.map((option, index) => {
            let className
            return option !== SignupTexts.NotActive ? (
              <OptionItem className={className} key={option} onClick={onClick}>
                {option}
              </OptionItem>
            ) : (
              <Fragment key={index}></Fragment>
            )
          })}
        </OptionsList>
      ) : (
        <NoOption>
          <em>{SignupTexts.WeCouldNotFindWhatYouHaveBeenLookingFor}</em>
        </NoOption>
      )
    ) : (
      <></>
    )
  }

  return (
    <>
      <DropdownContainer
        className={`dropdown ${className ?? ''} ${hasError ? 'error' : ''}`}
      >
        <Label htmlFor={id} color={color}>
          {label}
        </Label>
        <Input
          bgColor={bgColor}
          color={color}
          autoComplete={autocomplete ? undefined : 'new-password'}
          disabled={disabled}
          id={id}
          type="text"
          onChange={onChange}
          value={userInput}
        ></Input>
        {renderOptionsListComponent()}
        <HeightPlaceHolderForError>
          <Error hasError={hasError} errorText={errorText}></Error>
        </HeightPlaceHolderForError>
      </DropdownContainer>
    </>
  )
}
export default Dropdown
