import React from 'react'
import { IButtonProps } from '../../../types/interfaces/IButtonProps'

export const Button: React.FC<IButtonProps> = ({
  className,
  text,
  callback,
  type,
  disabled = false,
}) => {
  return (
    <button
      type={type ?? 'button'}
      disabled={disabled}
      className={className}
      onClick={(e: any) => callback(e)}
    >
      {text}
    </button>
  )
}
