import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface LayoutState {
  showNavbar: boolean
  showFooter: boolean
  isPersonalAreaWebView: boolean
}

const initialState: LayoutState = {
  showNavbar: false,
  showFooter: false,
  isPersonalAreaWebView: false,
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    showNavbar: (state, action: PayloadAction<boolean>) => {
      state.showNavbar = action.payload
    },
    showFooter: (state, action: PayloadAction<boolean>) => {
      state.showFooter = action.payload
    },
    setIsPersonalAreaWebView: (state, action: PayloadAction<boolean>) => {
      state.isPersonalAreaWebView = action.payload
    },
  },
})

export const { showNavbar, showFooter, setIsPersonalAreaWebView } =
  layoutSlice.actions

export default layoutSlice.reducer
