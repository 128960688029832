import styled from 'styled-components/macro'

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 124px;
  z-index: 20;
  margin-bottom: -60px;
  background-color: ${({ theme }) => theme.black};
  position: relative;
  background: linear-gradient(to bottom, black 0%, black 80%, transparent 100%);
  direction: rtl;

  ${({ theme }) => theme.media.sm`
    justify-content: space-between;
    height: 153px;
  `}
`

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 60px;
`
