import React from 'react'
import { ErrorIndicator } from './style'

export const Error: React.FC<any> = ({ hasError, errorText }) => {
  return (
    <ErrorIndicator className={hasError ? 'error' : ''}>
      {errorText}
    </ErrorIndicator>
  )
}
