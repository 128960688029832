/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../store/store'
import { IdInput } from '../../../../shared/components/id-input/IdInput'
import { DatePickerWrapper } from '../../../../shared/components/date-picker/DatePickerWrapper'
import { TextInput } from '../../../../shared/components/text-input/TextInput'
import { updateUser } from '../../../../store/reducers/userReducer'
import { SelectInput } from '../../../../shared/components/select-input/SelectInput'
import { genderInputOptions } from 'constants/gender-input'
import { FormRow } from 'pages/user-details/style'
import { theme } from 'styles/theme'
import { useGetSfUserByEmailQuery } from 'store/api-slices/userSlice'

interface Props {
  errors: {
    id?: boolean | undefined
    dob?: boolean | undefined
    phoneNum?: boolean | undefined
    firstName?: boolean | undefined
    lastName?: boolean | undefined
    address?:
      | {
          city?: boolean | undefined
          street?: boolean | undefined
          postalCode?: boolean | undefined
        }
      | undefined
    gender: boolean
  }
  setErrors: React.Dispatch<
    React.SetStateAction<{
      id?: boolean | undefined
      dob?: boolean | undefined
      phoneNum?: boolean | undefined
      firstName?: boolean | undefined
      lastName?: boolean | undefined
      address?:
        | {
            city?: boolean | undefined
            street?: boolean | undefined
            postalCode?: boolean | undefined
          }
        | undefined
      gender: boolean
    }>
  >
  showUserIdExistsError: boolean
  idErrorText: string
  setShowUserIdExistsError: React.Dispatch<React.SetStateAction<boolean>>
}

export const PersonnalDetailsForm: React.FC<Props> = ({
  errors,
  setErrors,
  showUserIdExistsError,
  idErrorText,
  setShowUserIdExistsError,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const initialUserRef = useRef(user)
  const { data } = useGetSfUserByEmailQuery({})

  const formData = useAppSelector((state) => state.user.personalDetails)

  useEffect(() => {
    const id = initialUserRef.current.personalDetails?.id
    const isIdValid = id ? id.length === 9 && id !== '' : false

    setErrors((prevErrors) => ({ ...prevErrors, id: !isIdValid }))
  }, [])

  const handleIdChange = async (value: any, error?: boolean) => {
    setShowUserIdExistsError(false)
    setErrors({ ...errors, id: error })
    dispatch(
      updateUser({ ...user, personalDetails: { ...formData, id: value } }),
    )
  }

  const handleDobChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, dob: error })
    dispatch(
      updateUser({ ...user, personalDetails: { ...formData, dob: value } }),
    )
  }

  const handleFirstNameChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, firstName: error })
    dispatch(
      updateUser({
        ...user,
        personalDetails: { ...formData, firstName: value },
      }),
    )
  }

  const handleLastNameChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, lastName: error })
    dispatch(
      updateUser({
        ...user,
        personalDetails: { ...formData, lastName: value },
      }),
    )
  }

  const handleGenederChange = (gender: {
    value: 'Male' | 'Female' | 'Other' | undefined
  }) => {
    dispatch(
      updateUser({
        ...user,
        personalDetails: { ...formData, gender: gender.value },
      }),
    )
  }

  return (
    <>
      <FormRow>
        <TextInput
          value={formData?.firstName}
          minLength={2}
          hasError={errors.firstName}
          errorText={'שם פרטי לא תקין'}
          callback={handleFirstNameChange}
          label={'שם פרטי'}
          id={'firstName'}
          disabled={true}
          color={theme.white}
          bgColor={theme.gray}
          disabledOpacityStyle
          isCancelMb
        />
        <TextInput
          value={formData?.lastName !== 'temp' ? formData?.lastName : ''}
          minLength={2}
          hasError={errors.lastName}
          errorText={'שם משפחה לא תקין'}
          callback={handleLastNameChange}
          label={'שם משפחה'}
          id={'lastName'}
          disabled={true}
          bgColor={theme.gray}
          color={theme.white}
          disabledOpacityStyle
          isCancelMb
        />
      </FormRow>
      <DatePickerWrapper
        color={theme.white}
        bgColor={theme.gray}
        boxShadow
        value={formData?.dob}
        hasError={errors.dob}
        errorText={'חובה לבחור תאריך לידה'}
        callback={handleDobChange}
        disabled={initialUserRef.current.personalDetails?.dob ? true : false}
        disabledOpacityStyle
        isCancelMb
      />
      <FormRow>
        <IdInput
          bgColor={theme.gray}
          color={theme.white}
          value={formData?.id}
          hasError={errors.id || showUserIdExistsError}
          errorText={idErrorText}
          callback={handleIdChange}
          disabledOpacityStyle={
            data?.personalDetails?.id &&
            data?.personalDetails?.id?.length === 9 &&
            !showUserIdExistsError
              ? true
              : false
          }
          disabled={
            data?.personalDetails?.id &&
            data?.personalDetails?.id?.length === 9 &&
            !showUserIdExistsError
              ? true
              : false
          }
        />
        <SelectInput
          title={'מגדר'}
          options={genderInputOptions}
          value={formData?.gender}
          callback={handleGenederChange}
          bgColor={theme.gray}
          color={theme.white}
        />
      </FormRow>
    </>
  )
}

export default PersonnalDetailsForm
