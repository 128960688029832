import tiktokIconSvg from 'assets/images/tiktok.svg'
import facebookIconSvg from 'assets/images/facebook.svg'
import instagramIconSvg from 'assets/images/instagram.svg'
import twitterIconSvg from 'assets/images/twitter.svg'

// TODO Add links
export const socialIcons = [
  {
    src: tiktokIconSvg,
    alt: 'tiktok icon',
    url: 'https://www.tiktok.com/@mhfootballclub',
  },
  {
    src: twitterIconSvg,
    alt: 'twitter icon',
    url: 'https://twitter.com/mhfootballclub',
  },
  {
    src: instagramIconSvg,
    alt: 'instagram icon',
    url: 'https://www.instagram.com/maccabihaifafc/',
  },
  {
    src: facebookIconSvg,
    alt: 'facebook icon',
    url: 'https://www.facebook.com/Mhfootballclub',
  },
]
