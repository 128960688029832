import { IShopifyOrder } from 'types/interfaces/IShopifyOrder'
import { dateService } from './date.service'
import moment from 'moment'

const getOrdersByMonth = (
  shopifyOrders: IShopifyOrder[],
): {
  month: number
  monthName: string
  year: number
  orders: IShopifyOrder[]
}[] => {
  if (!shopifyOrders) return []

  const currentYear = moment().year()
  const currentMonth = moment().month()

  // Determine the start and end dates of the current membership season
  const seasonStart =
    currentMonth >= 5
      ? moment(`01-06-${currentYear}`, 'DD-MM-YYYY')
      : moment(`01-06-${currentYear - 1}`, 'DD-MM-YYYY')
  const seasonEnd = seasonStart.clone().add(1, 'year').subtract(1, 'day')

  // Filter orders that fall within the current season
  const filteredOrders = shopifyOrders.filter((order) => {
    const orderDate = moment(order.createdAt, 'DD.MM.YYYY')
    return orderDate.isBetween(seasonStart, seasonEnd, 'day', '[]')
  })

  // Group the filtered orders by month and year
  const ordersGroupedByMonthAndYear = filteredOrders.reduce(
    (acc, order) => {
      const orderDate = moment(order.createdAt, 'DD.MM.YYYY')
      const monthIndex = orderDate.month()
      const year = orderDate.year()
      const key = `${monthIndex}-${year}`

      if (!acc[key])
        acc[key] = { month: monthIndex + 1, year: year, orders: [] }

      acc[key].orders.push(order)
      return acc
    },
    {} as Record<
      string,
      { month: number; year: number; orders: IShopifyOrder[] }
    >,
  )

  // Convert to array and sort by year and month
  return Object.values(ordersGroupedByMonthAndYear)
    .map((entry) => ({
      ...entry,
      monthName: dateService.getHebrewMonthName(entry.month),
    }))
    .sort((a, b) => {
      return b.year - a.year || b.month - a.month
    })
}

const getOpponentNameByTicketName = (
  ticketName: string | undefined,
  isMobile?: boolean,
): string => {
  if (!ticketName) return ''
  const parts = ticketName.split(' - ')
  if (parts.length === 1)
    return isMobile ? parts[0] : parts[0].slice(0, 12) + '...'
  const partToReturn = parts[0].includes('מכבי חיפה') ? parts[1] : parts[0]

  return isMobile ? partToReturn : partToReturn.slice(0, 12)
}

const formatShopName = (shopName: string | undefined): string => {
  if (!shopName) return ''
  return shopName.replace('חנות', '').trim()
}

const formatStadiumName = (stadiumName: string | undefined): string => {
  if (!stadiumName) return ''
  return stadiumName.replace('אצטדיון', '').trim()
}

export const orderService = {
  getOrdersByMonth,
  getOpponentNameByTicketName,
  formatShopName,
  formatStadiumName,
}
