/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FormEvent, useEffect, useState } from 'react'
import { EmailInput } from '../../../../shared/components/email-input/EmailInput'
import { ForgotFormBody, ButtonWrapper } from './style'
import { resetPassword } from '../../../../services/AuthService'
import { useNavigate } from 'react-router-dom'
import { ErrorTexts, SignupTexts } from '../../../../constants/signup-texts'
import { EAppRoutes } from 'types/enums/EAppRoutes'

export const ForgotPassword: React.FC<any> = ({ setLoginTexts }) => {
  const [forgotFormData, setForgotFormData] = useState({
    email: '',
  })
  const [errors, setErrors] = useState<{ email?: boolean }>({ email: false })
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const navigate = useNavigate()

  useEffect(() => {
    setLoginTexts({
      title: SignupTexts.Welcome,
      subtitle: SignupTexts.ForgotPasswordPleaseEnterEmailMessage,
    })
  }, [])

  useEffect(() => {
    if (forgotFormData.email && !errors.email) {
      setIsDisabled(false)
    } else if (!isDisabled) {
      setIsDisabled(true)
    }
  }, [forgotFormData])

  const handleEmailChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, email: error })
    setForgotFormData({ ...forgotFormData, email: value })
  }

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault()
    resetPassword(forgotFormData.email.toLowerCase())
    return navigate(EAppRoutes.RESET_PASSWORD, {
      state: { email: forgotFormData.email },
    })
  }

  return (
    <ForgotFormBody>
      <EmailInput
        hasError={errors.email}
        errorText={ErrorTexts.EmailIsNotValid}
        callback={handleEmailChange}
      ></EmailInput>
      <ButtonWrapper
        type="submit"
        disabled={isDisabled}
        className={`buttonWrapper ${isDisabled ? 'disabled' : ''}`}
        text={'שלח/י'}
        callback={handleSubmit}
      />
    </ForgotFormBody>
  )
}
