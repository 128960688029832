import { device, size } from 'constants/media-query'
import { FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { WhiteLink } from 'shared/components/WhiteLink'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import styled from 'styled-components/macro'
import { FlexColumn, Heading3, Text } from 'styles/generics'
import { theme } from 'styles/theme'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'
import { EAppRoutes } from 'types/enums/EAppRoutes'

interface Props {
  title: string
  description: string
  bgImg: string
  btnText: string
  linkTo: EAppRoutes
  mobileBgSize?: 'cover' | 'contain'
}

const CardLink: FunctionComponent<Props> = ({
  title,
  description,
  bgImg,
  btnText,
  linkTo,
  mobileBgSize = 'contain',
}) => {
  const isXsMobile = useIsMobile(size.xs)

  return (
    <CardContainer bgImg={bgImg} mobileBgSize={mobileBgSize}>
      <Link to={linkTo} style={{ display: 'block', width: '100%' }}>
        <Heading3 fontFamily={EAppFontFamilies.ATLAS_500} fontSize="32px">
          {title}
        </Heading3>
        <Description>{description}</Description>
        <WhiteLink
          linkTo={linkTo}
          text={btnText}
          mt={isXsMobile ? '30px' : 'auto'}
        />
      </Link>
    </CardContainer>
  )
}

const CardContainer = styled(FlexColumn)<{
  bgImg: string
  mobileBgSize: 'cover' | 'contain'
}>`
  background: url(${({ bgImg }) => bgImg});
  background-size: cover;
  background-position: inline-end;
  background-repeat: no-repeat;
  background-color: ${theme.opacityWhite};
  background-size: contain;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  height: 230px;
  color: ${theme.white};
  gap: 10px;
  width: 100%;
  line-height: 24px;

  @media ${device.mobileOnly} {
    padding-top: 15px;
    padding-inline-start: 8px;
    padding-bottom: 12px;
  }
  @media ${device.desktop} {
    padding: 30px;
    padding-bottom: 20px;
    background-size: contain;
  }

  @media ${device.large} {
    width: 49%;
  }
`

const Description = styled(Text)`
  max-width: 210px;
  font-size: 16px;

  @media ${device.desktop} {
    max-width: 300px;
    font-size: 20px;
  }
`

export { CardLink }
