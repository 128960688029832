export const size = {
  xs: '480px',
  sm: '576px',
  desktop: '768px',
  large: '992px',
  xl: '1200px',
}

export const device = {
  xsOnly: `(max-width: ${size.xs})`,
  mobileOnly: `(max-width: ${size.desktop})`,
  xs: `(max-width: ${size.xs})`,
  sm: `(min-width: ${size.sm})`,
  desktop: `(min-width: ${size.desktop})`,
  large: `(min-width: ${size.large})`,
  xl: `(min-width: ${size.xl})`,
}
