import { FunctionComponent } from 'react'
import styled, { css, CSSObject } from 'styled-components/macro'
import { Tooltip } from 'antd'
import { ImageWrapper } from 'shared/components/ImageWrapper'
import infoSvg from 'assets/icons/info.svg'
import { purchaseHistoryText } from 'constants/purchase-history-text'
import { FlexColumn, FlexRow, Text } from 'styles/generics'
import { theme } from 'styles/theme'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'
import { device } from 'constants/media-query'

interface Props {
  customStyle?: CSSObject
}

const WrongInfoTooltip: FunctionComponent<Props> = ({ customStyle }) => {
  const TooltipContent = (
    <FlexColumn>
      <Text fontSize="18px" style={{ color: 'black' }}>
        {purchaseHistoryText.wrongInfoTooltip.identifiedWrongInfo}
        <br />
        <br />
        {purchaseHistoryText.wrongInfoTooltip.tooltipDescription}
        <br />
        <EmailLink href={'mailto:tickets4@maccabihaifafc.com'}>
          {' '}
          {purchaseHistoryText.wrongInfoTooltip.contactEmail}
        </EmailLink>
        <br />
        {purchaseHistoryText.wrongInfoTooltip.handleProblem}
        <br />
        <br />
        {purchaseHistoryText.wrongInfoTooltip.thanksFor}
      </Text>
    </FlexColumn>
  )

  return (
    <TooltipContainer customStyle={customStyle}>
      <Tooltip
        color="white"
        title={TooltipContent}
        overlayClassName="wrong-info-tooltip"
      >
        <FlexRow gap={10} align="center">
          <ImageWrapper src={infoSvg} alt="info" width="25px" />
          <TooltipCTAText fontSize="16px" color={theme.white}>
            {purchaseHistoryText.wrongInfoTooltip.wrongInfoCTA}
          </TooltipCTAText>
        </FlexRow>
      </Tooltip>
    </TooltipContainer>
  )
}

const TooltipContainer = styled.div<{
  customStyle?: CSSObject
}>`
  position: absolute;
  bottom: -35px;
  right: 5px;
  cursor: pointer;

  ${({ customStyle }) => customStyle && css(customStyle)}
`

const TooltipCTAText = styled(Text)`
  text-decoration: underline;
  font-family: ${EAppFontFamilies.ATLAS_BOLD};

  @media ${device.desktop} {
    font-size: 18px;
  }
`

const EmailLink = styled.a`
  color: ${theme.primaryGreen};
`

export { WrongInfoTooltip }
