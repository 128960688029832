import React, { CSSProperties } from 'react'
import Lottie from 'react-lottie'
import mhfcLoaderJson from 'assets/animations/MHFC-loader.json'
import styled from 'styled-components'

interface Props {
  style?: CSSProperties
  mt?: string
  mb?: string
}

export const Loader: React.FC<Props> = ({ style, mt, mb }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: mhfcLoaderJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }

  return (
    <LoaderContainer className="loader" style={style} mt={mt} mb={mb}>
      <Lottie options={defaultOptions} height={150} width={150} />
    </LoaderContainer>
  )
}

const LoaderContainer = styled.div<{ mt?: string; mb?: string }>`
  ${({ mt }) => mt && `margin-top: ${mt};`}
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
`
