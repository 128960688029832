import styled from 'styled-components/macro'
import { theme } from 'styles/theme'

export const DatePickerContainer = styled.div<{
  color?: string
  bgColor?: string
  boxShadow?: boolean
  disabledOpacityStyle?: boolean
  isCancelMb?: boolean
}>`
  width: 100%;
  ${({ isCancelMb }) =>
    isCancelMb ? 'margin-bottom: 0;' : 'margin-bottom: 25px;'}

  &.error {
    .react-datepicker-wrapper {
      input {
        border: 1px solid #ff0000;
      }
    }
  }

  .date-picker {
    font-family: 'Atlas-AAA', sans-serif;
    box-sizing: border-box;
    width: 100%;
    height: 44px;
    padding: 20px 10px;
    font-weight: 400;
    font-size: 18px;
    line-height: 9px;
    letter-spacing: -0.025em;
    color: #000000;
    border: 1px solid #9c9c9c;
    border-radius: 0;
    outline: none;
    box-shadow: none;
    ${({ color }) => color && `color: ${color};`};
    ${({ bgColor }) => bgColor && `background-color: ${bgColor};`};
    ${({ boxShadow }) =>
      boxShadow && `box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);`};

    ${({ disabledOpacityStyle }) =>
      disabledOpacityStyle &&
      `cursor: not-allowed; background-color: ${theme.gray400}; opacity: 0.5;`}

    .ant-picker-input {
      display: flex;
      flex-direction: row-reverse;
      gap: 10px;
      pointer-events: none;
      touch-action: manipulation;

      input {
        ${({ color }) => color && `color: ${color};`};
        font-size: 16px;
      }
    }
  }
`

export const Label = styled.label<{
  color?: string
  disabledOpacityStyle?: boolean
}>`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
  ${({ color }) => color && `color: ${color};`}
  ${({ disabledOpacityStyle }) => disabledOpacityStyle && `opacity: 0.3;`}
`
