import React from 'react'
import { Navigate } from 'react-router-dom'
import { useIsAuthecticated } from 'shared/helpers/useIsAuthenticated'
import { useAppSelector } from 'store/store'

export const ProtectedRoute = ({ children }: { children: any }) => {
  const isUserStateInitialized = useAppSelector((state) => state.user)
  const isAuthenticated = useIsAuthecticated()
  if (isAuthenticated === null || !isUserStateInitialized) return null

  if (!isAuthenticated && isUserStateInitialized) {
    return <Navigate to="/login" replace />
  }
  return children
}
