import React from 'react'
import { IInputProps } from '../../../types/interfaces/IInputProps'
import { MhfcIdInputContainer, Input, Label } from './style'
import { SignupTexts } from '../../../constants/signup-texts'
import { ClientNumberRegex } from '../../helpers/regex'

export const MhfcIdInput: React.FC<IInputProps> = ({
  callback,
  className,
  value,
  disabled,
}) => {
  const handleChange = (e: any) => {
    e.preventDefault()
    const error = !ClientNumberRegex.test(e.target.value)
    callback(e.target.value, error)
  }

  return (
    <MhfcIdInputContainer className={className ?? 'mhfcId'}>
      <Label htmlFor="mhfcId">{SignupTexts.ClientNumber}</Label>
      <Input
        disabled={disabled}
        value={value}
        type="text"
        name="mhfcId"
        onChange={(e: any) => handleChange(e)}
      ></Input>
    </MhfcIdInputContainer>
  )
}
