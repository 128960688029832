import { FunctionComponent, useMemo } from 'react'
import { useGetUserTicketsQuery } from 'store/api-slices/userSlice'
import { CollapseProps } from 'antd'
import expandIconSvg from 'assets/icons/expand.svg'
import { Loader } from 'shared/components/loader/loader'
import { FlexColumn, FlexRow, Span, TextSmall } from 'styles/generics'
import { theme } from 'styles/theme'
import {
  CollapseLabelContainer,
  EmptyStateText,
  LabelHeaderText,
  ListHeader,
  MobileExtraInfo,
  NestedLabelHeaderText,
  NestedListHeaderContainer,
  NestedListHeaderText,
  OrderHistoryList,
  ParentStyledCollapse,
  StyledCollapse,
} from './styles'
import { ITicket } from 'types/interfaces/ITicket'
import { purchaseHistoryText } from 'constants/purchase-history-text'
import { orderService } from 'services/order.service'
import { utilsService } from 'services/utils.service'
import oneZeroImg from 'assets/images/one-zero.png'
import winnerLigatAlWhiteImg from 'assets/images/logo-winner-ligat-al-white.png'
import trophySvg from 'assets/icons/trophy.svg'
import clockSvg from 'assets/icons/clock.svg'
import { ImageWrapper } from 'shared/components/ImageWrapper'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import styled from 'styled-components/macro'
import { ESeasonYears } from 'types/enums/ESeasonYears'

const Tickets: FunctionComponent = () => {
  const isMobile = useIsMobile()
  const { data: ticketsData, isLoading } = useGetUserTicketsQuery(undefined)

  const customExpandIcon: CollapseProps['expandIcon'] = (panelProps) => (
    <img
      src={expandIconSvg}
      alt="expand icon"
      style={{
        transform: `rotate(${panelProps.isActive ? 0 : 90}deg)`,
        transition: 'transform 0.2s',
      }}
    />
  )

  const renderTournamentLogo = (eventSeason: string) => {
    let tournamentLogoSrc = oneZeroImg
    switch (eventSeason) {
      case ESeasonYears.SEASON_2425:
        tournamentLogoSrc = winnerLigatAlWhiteImg
        break
      default:
        break
    }

    return <ImageWrapper src={tournamentLogoSrc} alt="ticket" width="60px" />
  }

  const ticketYearsCollapseItems: CollapseProps['items'] = useMemo(() => {
    if (!ticketsData) return []
    const sortedYears = Object.keys(ticketsData).sort(
      (a, b) => parseInt(b) - parseInt(a),
    )

    return sortedYears.map((year) => ({
      key: year,
      label: (
        <CollapseLabelContainer>
          <LabelHeaderText color={theme.white}>{year}</LabelHeaderText>
        </CollapseLabelContainer>
      ),
      children: (
        <FlexColumn gap={10}>
          {/* Header Row */}
          <NestedListHeaderContainer justify="space-between" align="center">
            <NestedListHeaderText width="15%">
              {purchaseHistoryText.tickets.listHeaders.opponent}
            </NestedListHeaderText>
            <NestedListHeaderText
              width={isMobile ? '50%' : '15%'}
              justifyMobile="flex-end"
            >
              {purchaseHistoryText.tickets.listHeaders.date}
            </NestedListHeaderText>
            <NestedListHeaderText shouldHideMobile width="15%">
              {purchaseHistoryText.tickets.listHeaders.stadium}
            </NestedListHeaderText>
            <NestedListHeaderText shouldHideMobile width="15%">
              {purchaseHistoryText.tickets.listHeaders.creditAccumulation}
            </NestedListHeaderText>
            <NestedListHeaderText shouldHideMobile width="15%">
              {purchaseHistoryText.tickets.listHeaders.creditSpent}
            </NestedListHeaderText>
            <NestedListHeaderText width="7%" paddingInlineEndMobile="12px">
              {purchaseHistoryText.tickets.listHeaders.total}
            </NestedListHeaderText>
          </NestedListHeaderContainer>
          {/* Ticket Rows */}
          <StyledCollapse
            accordion
            items={ticketsData[year].map((ticket: ITicket) => ({
              key: ticket.sfId,
              label: (
                <CollapseLabelContainer justify="space-between" align="center">
                  <NestedLabelHeaderTextEventName
                    width={isMobile ? '25%' : '18%'}
                    isCanceled={ticket.status === 'Cancelled'}
                    title={ticket.eventNameHeb}
                  >
                    {orderService.getOpponentNameByTicketName(
                      ticket.eventNameHeb,
                      isMobile,
                    )}
                  </NestedLabelHeaderTextEventName>
                  <NestedLabelHeaderText
                    isCanceled={ticket.status === 'Cancelled'}
                    width={isMobile ? '50%' : '13%'}
                    paddingInlineEndMobile="40px"
                  >
                    {utilsService.convertSfDatetoIsraeliDate(
                      ticket.saleDate,
                      '.',
                    )}
                  </NestedLabelHeaderText>
                  <NestedLabelHeaderText
                    isCanceled={ticket.status === 'Cancelled'}
                    shouldHideMobile
                    width="20%"
                  >
                    {orderService.formatStadiumName(ticket.stadiumName)}
                  </NestedLabelHeaderText>
                  <NestedLabelHeaderText
                    isCanceled={ticket.status === 'Cancelled'}
                    shouldHideMobile
                    width="20%"
                  >
                    {ticket.creditAccumulationAmount || '-'}
                  </NestedLabelHeaderText>
                  <NestedLabelHeaderText
                    isCanceled={ticket.status === 'Cancelled'}
                    shouldHideMobile
                    width="16%"
                  >
                    {ticket.creditAmountSpent || '-'}
                  </NestedLabelHeaderText>
                  <NestedLabelHeaderText
                    isCanceled={ticket.status === 'Cancelled'}
                    width={isMobile ? '15%' : '10%'}
                  >
                    {ticket.totalPriceToPay && (
                      <>
                        <Span>₪</Span>
                        {ticket.totalPriceToPay}
                      </>
                    )}
                  </NestedLabelHeaderText>
                </CollapseLabelContainer>
              ),
              children: (
                <FlexColumn gap={15}>
                  {(ticket.stadiumName ||
                    ticket.creditAccumulationAmount ||
                    ticket.creditAmountSpent) &&
                    isMobile && (
                      <MobileExtraInfo>
                        {ticket.stadiumName && (
                          <LabelHeaderText width="15%" color={theme.white}>
                            {`${purchaseHistoryText.tickets.listHeaders.stadium + ': ' + orderService.formatStadiumName(ticket.stadiumName)}`}
                          </LabelHeaderText>
                        )}
                        {ticket.creditAccumulationAmount && (
                          <LabelHeaderText width="16%" color={theme.white}>
                            {`${purchaseHistoryText.shopifyOrders.creditAccumulationMobile + ': ' + ticket.creditAccumulationAmount}`}
                          </LabelHeaderText>
                        )}
                        {ticket.creditAmountSpent && (
                          <LabelHeaderText width="15%" color={theme.white}>
                            {`${purchaseHistoryText.shopifyOrders.creditSpentMobile + ': ' + ticket.creditAmountSpent}`}
                          </LabelHeaderText>
                        )}
                      </MobileExtraInfo>
                    )}
                  <FlexRow gap={20}>
                    {renderTournamentLogo(year)}
                    <FlexColumn justify="center">
                      {ticket.tournament && (
                        <FlexRow align="center" gap={10}>
                          <ImageWrapper
                            src={trophySvg}
                            alt="trophy"
                            width="16px"
                          />
                          <TextSmall color={theme.white} pb="3px">
                            {ticket.tournament || ''}
                          </TextSmall>
                        </FlexRow>
                      )}
                      {ticket.eventDateTimeUTC && (
                        <FlexRow gap={10}>
                          <ImageWrapper
                            src={clockSvg}
                            alt="calendar"
                            width="16px"
                          />
                          <TextSmall color={theme.white} pb="3px">
                            {utilsService.convertDateTimeToHebrew(
                              ticket.eventDateTimeUTC,
                            )}
                          </TextSmall>
                        </FlexRow>
                      )}
                    </FlexColumn>
                  </FlexRow>
                </FlexColumn>
              ),
            }))}
            expandIcon={customExpandIcon}
          />
        </FlexColumn>
      ),
    }))
  }, [ticketsData, isMobile])

  return isLoading ? (
    <Loader mt="40px" mb="40px" />
  ) : ticketYearsCollapseItems.length > 0 ? (
    <>
      <ListHeader mb="20px" width="100%" justify="space-between"></ListHeader>
      <OrderHistoryList>
        <ParentStyledCollapse
          accordion
          items={ticketYearsCollapseItems}
          expandIcon={customExpandIcon}
        />
      </OrderHistoryList>
    </>
  ) : (
    <EmptyStateText>
      {purchaseHistoryText.tickets.noAvailableTickets}
    </EmptyStateText>
  )
}

const NestedLabelHeaderTextEventName = styled(NestedLabelHeaderText)<{
  isCanceled?: boolean
}>`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${theme.white};
  text-decoration: ${({ isCanceled }) =>
    isCanceled ? 'line-through' : 'none'};
`

export { Tickets }
