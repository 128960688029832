import React, { useEffect, useState } from 'react'
import { EPersonalDetailsFormSteps } from '../../../../../../../../types/enums/EPersonalDetailsFormSteps'
import { SubmitButtonWrapper } from './style'
import { PhoneInput } from '../../../../../../../../shared/components/phone-input/PhoneInput'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/store'
import { IUser } from '../../../../../../../../types/interfaces/IUser'
import { updateUser } from '../../../../../../../../store/reducers/userReducer'
import { DatePickerWrapper } from '../../../../../../../../shared/components/date-picker/DatePickerWrapper'
import { useUpdateSfUserMutation } from '../../../../../../../../store/api-slices/userSlice'
import { Loader } from '../../../../../../../../shared/components/loader/loader'
import {
  ErrorTexts,
  SignupTexts,
} from '../../../../../../../../constants/signup-texts'
import dayjs from 'dayjs'

export const PersonalDetailsFormPersonalInfoFirstStep: React.FC<any> = ({
  setSignupFormTexts,
  onNextClicked,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector<IUser>((state) => state.user)
  const [personalInfoFormData, setPersonalInfoFormData] = useState<{
    dob: any
    phoneNum: string
  }>({
    dob: user?.personalDetails?.dob ?? '',
    phoneNum: user?.personalDetails?.phoneNum ?? '',
  })
  const [errors, setErrors] = useState<{
    dob?: boolean
    phoneNum?: boolean
  }>({
    dob: false,
    phoneNum: false,
  })
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [isLoading, setIsLoading] = useState(false)

  const [updateSfUser] = useUpdateSfUserMutation()

  useEffect(() => {
    setSignupFormTexts({
      title: SignupTexts.PersonalDetails,
      subtitle: SignupTexts.TellUsAboutYourself,
    })
  }, [])

  useEffect(() => {
    if (
      personalInfoFormData.dob &&
      personalInfoFormData.phoneNum &&
      !errors.dob &&
      !errors.phoneNum
    ) {
      setIsDisabled(false)
    } else if (!isDisabled) {
      setIsDisabled(true)
    }
  }, [personalInfoFormData.dob, personalInfoFormData.phoneNum, errors])

  const handleDobChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, dob: error })
    setPersonalInfoFormData({ ...personalInfoFormData, dob: value })
  }

  const handlePhoneChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, phoneNum: error })
    setPersonalInfoFormData({ ...personalInfoFormData, phoneNum: value })
  }

  const checkDobGreaterThan16 = (date: any) => {
    return dayjs().diff(date, 'year') >= 16
  }

  const nextButtonClicked = async (e: any) => {
    e.preventDefault()

    if (
      !personalInfoFormData.dob ||
      !checkDobGreaterThan16(personalInfoFormData.dob)
    ) {
      setErrors({ ...errors, dob: true })
      return
    }
    setIsLoading(true)

    const dob = personalInfoFormData.dob.$y
      ? `${personalInfoFormData.dob.$y}-${personalInfoFormData.dob.$M + 1}-${
          personalInfoFormData.dob.$D
        }`
      : personalInfoFormData.dob

    await updateSfUser({
      ...user.personalDetails,
      id: undefined,
      dob,
      phoneNum: personalInfoFormData.phoneNum,
      addresses: [],
    })

    dispatch(
      updateUser({
        ...user,
        personalDetails: {
          ...user.personalDetails,
          dob: personalInfoFormData.dob,
          phoneNum: personalInfoFormData.phoneNum,
        },
      }),
    )
    onNextClicked(EPersonalDetailsFormSteps.secondStep)
    setIsLoading(false)
  }

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <>
      <h4>{SignupTexts.SignupFormConfirmAllDetailsAreValid}</h4>
      <DatePickerWrapper
        value={personalInfoFormData.dob}
        hasError={errors.dob}
        errorText={ErrorTexts.MustChooseDateOfBirth}
        callback={handleDobChange}
      ></DatePickerWrapper>
      <PhoneInput
        value={personalInfoFormData.phoneNum}
        hasError={errors.phoneNum}
        errorText={ErrorTexts.PhoneNumberIsNotValid}
        callback={handlePhoneChange}
      ></PhoneInput>
      <SubmitButtonWrapper
        type="submit"
        disabled={isDisabled}
        className={`buttonWrapper ${isDisabled ? 'disabled' : ''}`}
        text={SignupTexts.Next}
        callback={(e: any) => nextButtonClicked(e)}
      />
    </>
  )
}
