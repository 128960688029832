import styled from 'styled-components/macro'
import { Button } from '../../../../../../../../shared/components/button/Button'

export const ButtonWrapper = styled(Button)`
  font-family: 'Atlas-AAA', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: #007638;
  color: #fff;
  padding: 20px 0;
  border: none;
  width: 100%;
  cursor: pointer;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
    cursor: default;
  }
`

export const IdInputComment = styled.p`
  margin: 18px 0 24px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
`

export const IdInputSkipButton = styled(Button)`
  font-family: 'Atlas-AAA-Bold', sans-serif;
  text-decoration: underline;
  background-color: transparent;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #007638;
  border: none;
  cursor: pointer;
  padding: 10px 5px;
`
