import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { AUTH_USER_TOKEN_KEY } from 'constants/auth'
import { cookieService } from 'services/cookie.service'

const URL = process.env.REACT_APP_BASE_API_URL

export const mhfcAuthApiSlice = createApi({
  // unique API idetifier for redux store.
  reducerPath: 'api',
  // All of our requests will have URLs starting with '/api'.
  baseQuery: fetchBaseQuery({
    baseUrl: `${URL}/api/`, // ? currently first version of api - v1.
    prepareHeaders: (headers) => {
      // get token from local storage.
      const mhfcToken =
        cookieService.getCookie(AUTH_USER_TOKEN_KEY) ||
        localStorage.getItem(AUTH_USER_TOKEN_KEY)
      if (mhfcToken) {
        headers.set('Authorization', `Bearer ${mhfcToken}`)
        return headers
      }
    },
  }),
  // The "endpoints" represent operations and requests for this server
  endpoints: () => ({}),
  tagTypes: ['User'],
})
