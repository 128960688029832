import { css } from 'styled-components'

const mixins = {
  hideScrollbar: css`
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-color: transparent transparent;
  `,
}

export { mixins }
