import {
  Dispatch,
  FunctionComponent,
  MouseEvent,
  useMemo,
  useState,
} from 'react'
import styled from 'styled-components/macro'
import {
  BorderButton,
  Button,
  FlexColumn,
  FlexRow,
  GreenButton,
  Image,
  Span,
  Text,
} from 'styles/generics'
import { theme } from 'styles/theme'
import { IAddress } from 'types/interfaces/IAddress'
import editIcon from 'assets/images/edit.svg'
import trashIcon from 'assets/images/trash.svg'
import plusIcon from 'assets/images/plus.svg'
import { AppModal } from 'shared/components/modal/AppModal'
import { useAppDispatch, useAppSelector } from 'store/store'
import { updateUser } from 'store/reducers/userReducer'
import { useDeleteSfUserAddressMutation } from 'store/api-slices/userSlice'

interface Props {
  addresses: IAddress[] | undefined
  setSelectedAddress: Dispatch<React.SetStateAction<IAddress>>
  setIsEditAddress: Dispatch<React.SetStateAction<boolean>>
}

const AddressesList: FunctionComponent<Props> = ({
  addresses,
  setSelectedAddress,
  setIsEditAddress,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.user)
  const formData = useAppSelector((state) => state.user.personalDetails)
  const [isConfirmDeleteAddressModalOpen, setIsConfirmDeleteAddressModalOpen] =
    useState(false)
  const [addressToRemoveId, setAddressToRemoveId] = useState<
    string | undefined
  >(undefined)
  const [deleteSfUserAddress] = useDeleteSfUserAddressMutation()

  const addressToRemove = useMemo(() => {
    if (!addressToRemoveId || !formData) return
    return formData.addresses?.find(
      (address) => address.id === addressToRemoveId,
    )
  }, [addressToRemoveId])

  const onRemoveAddressClick = (
    ev: MouseEvent<HTMLButtonElement>,
    addressId: string | undefined,
  ) => {
    ev.stopPropagation()
    ev.preventDefault()
    setAddressToRemoveId(addressId)
    setIsConfirmDeleteAddressModalOpen(true)
  }

  const removeAddress = () => {
    if (!addressToRemoveId || !formData) return
    const updatedAddresses = formData.addresses?.filter(
      (address) => address.id !== addressToRemoveId,
    )
    dispatch(
      updateUser({
        ...user,
        personalDetails: {
          ...formData,
          addresses: updatedAddresses,
        },
      }),
    )
    deleteSfUserAddress(addressToRemoveId)
  }

  const onEditAddressClick = (
    ev: MouseEvent<HTMLButtonElement>,
    address: IAddress,
  ) => {
    ev.stopPropagation()
    ev.preventDefault()
    setSelectedAddress(address)
    setIsEditAddress(true)
  }

  const renderAddAddressRow = () => {
    return (
      <AddAddressRow gap={15}>
        <AddAddressButton onClick={() => setIsEditAddress(true)}>
          <Image src={plusIcon} alt="plus" />
        </AddAddressButton>
        <Text fontSize="20px">הוסף כתובת</Text>
      </AddAddressRow>
    )
  }

  if (!addresses || !addresses.length)
    return (
      <>
        <Text>לא נמצאו כתובות...</Text>
        {renderAddAddressRow()}
      </>
    )

  return (
    <AddressesListContainer>
      <AppModal isModalOpen={isConfirmDeleteAddressModalOpen}>
        <Text fontSize="20px">הנך עומד/ת למחוק את הכתובת:</Text>
        <Text fontSize="20px">
          {addressToRemove?.street} {addressToRemove?.buildingNumber},{' '}
          {addressToRemove?.city}
        </Text>
        <Text mb="18px" fontSize="20px">
          האם את/ה בטוח/ה?
        </Text>
        <FlexRow gap={50} align="center" justify="center">
          <GreenButton
            onClick={() => setIsConfirmDeleteAddressModalOpen(false)}
          >
            בטל
          </GreenButton>
          <BorderButton
            onClick={() => {
              setIsConfirmDeleteAddressModalOpen(false)
              removeAddress()
            }}
          >
            מחק
          </BorderButton>
        </FlexRow>
      </AppModal>
      <AddressesContainer>
        {addresses.map((address) => (
          <AddressRow key={address.id}>
            <AddressInfo>
              <Text fontSize="20px">
                {`רח' ${address.street}${
                  address.buildingNumber ? ' ' + address.buildingNumber : ''
                }, ${address.city}`}{' '}
                {address.isMainAddress && (
                  <Span fontSize="16px"> (כתובת ראשית)</Span>
                )}
              </Text>
              <PostalCodeText
                shouldDisplay={!!address.postalCode}
                color={theme.grayText}
                fontSize="16px"
              >{`מיקוד ${address.postalCode}`}</PostalCodeText>
            </AddressInfo>
            <AddressActions gap={10}>
              <EditDeleteAddressButton
                onClick={(ev) => onEditAddressClick(ev, address)}
              >
                <Image src={editIcon} alt="edit" />
              </EditDeleteAddressButton>
              <EditDeleteAddressButton
                onClick={(ev) => onRemoveAddressClick(ev, address.id)}
              >
                <Image src={trashIcon} alt="trash" />
              </EditDeleteAddressButton>
            </AddressActions>
          </AddressRow>
        ))}
      </AddressesContainer>
      {renderAddAddressRow()}
    </AddressesListContainer>
  )
}

const AddressesListContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const AddressesContainer = styled(FlexColumn)`
  overflow: scroll;
  max-height: 300px;
`

const AddressRow = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid ${({ theme }) => theme.grayBorder};
`

const AddressInfo = styled(FlexColumn)`
  ${({ theme }) => theme.media.sm`
    margin-inline-start: 50px;
  `}
`

const PostalCodeText = styled(Text)<{ shouldDisplay: boolean }>`
  visibility: ${({ shouldDisplay }) => (shouldDisplay ? 'visible' : 'hidden')};
  color: ${theme.white};
`

const AddressActions = styled(FlexRow)`
  ${({ theme }) => theme.media.sm`
      margin-inline-end: 30px;
  `}
`

const AddAddressRow = styled(FlexRow)`
  justify-content: flex-start;
  width: 100%;
  padding-top: 20px;
`

const AddAddressButton = styled(Button)`
  margin-inline-start: 50px;
`

const EditDeleteAddressButton = styled(Button)`
  width: 30px;
`

export { AddressesList }
