import styled from 'styled-components/macro'
import mobileSuccessBackground from '../../../../assets/images/mobile_success_bg.png'
import desktopSuccessBackground from '../../../../assets/images/desktop_login_bg.jpg'
import { Button } from '../../../../shared/components/button/Button'
import { device } from '../../../../constants/media-query'

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
`

export const SuccessContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${mobileSuccessBackground}),
    linear-gradient(0deg, rgba(0, 0, 0, 0.76) 15.55%, rgba(0, 0, 0, 0) 56.53%);
  display: flex;
  flex-direction: column;
  text-align: center;
  direction: rtl;
  padding: 0px 25px;
  z-index: 0;

  ::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(
      0,
      0,
      0,
      0.56
    ); /* Adjust the alpha value to change the darkness level */
    z-index: -1; /* Ensures that the overlay does not block interaction with elements inside the div */
  }

  @media ${device.desktop} {
    background: url(${desktopSuccessBackground}),
      linear-gradient(
        360deg,
        rgba(0, 0, 0, 0.56) 51.92%,
        rgba(255, 255, 255, 0) 131.28%
      );
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`

export const SuccessContent = styled.div``

export const SuccessTitle = styled.span`
  display: block;
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -0.025em;
  color: #ffffff;
  margin-bottom: 25px;
`

export const SuccessSubTitle = styled.span`
  display: block;
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: -0.025em;
  color: #ffffff;
  margin-bottom: 25px;
`

export const SuccessDescription = styled.span`
  display: block;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: #ffffff;
  margin-bottom: 25px;
`

export const ButtonWrapper = styled(Button)`
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: #007638;
  color: #fff;
  padding: 20px 0;
  border: none;
  cursor: pointer;

  @media ${device.desktop} {
    width: 25%;
    margin: auto auto 40px;
  }
`
