import React, { useState } from 'react'
import { IInputProps } from '../../../types/interfaces/IInputProps'
import {
  PasswordInputContainer,
  Input,
  Label,
  InputContainer,
  ShowPasswordButton,
} from './style'
import { Error } from '../error/Error'
import { SignupTexts } from '../../../constants/signup-texts'
import { PasswordFormatRegex } from '../../helpers/regex'
import { HeightPlaceHolderForError } from '../email-confirm-input/style'

export const PasswordInput: React.FC<IInputProps> = ({
  callback,
  hasError,
  errorText,
  className,
}) => {
  const [passwordType, setPasswordType] = useState<'password' | 'text'>(
    'password',
  )

  const handleChange = (e: any) => {
    e.preventDefault()
    const error = !PasswordFormatRegex.test(e.target.value)
    callback(e.target.value, error)
  }

  const togglePasswordType = (e: any) => {
    e.preventDefault()
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }

  return (
    <PasswordInputContainer
      className={`password ${className ?? ''} ${hasError ? 'error' : ''}`}
    >
      <Label htmlFor="password">{SignupTexts.Password}</Label>
      <InputContainer>
        <Input
          type={passwordType}
          name="password"
          onChange={(e: any) => handleChange(e)}
        />
        <ShowPasswordButton onClick={togglePasswordType} type="button">
          {passwordType === 'password' ? (
            <i className="password-eye"></i>
          ) : (
            <i className="password-eye-hide"></i>
          )}
        </ShowPasswordButton>
      </InputContainer>
      <HeightPlaceHolderForError>
        <Error hasError={hasError} errorText={errorText}></Error>
      </HeightPlaceHolderForError>
    </PasswordInputContainer>
  )
}
