import React from 'react'
import { IInputProps } from '../../../types/interfaces/IInputProps'
import { EmailInputContainer, Input, Label } from './style'
import { Error } from '../error/Error'
import { SignupTexts } from '../../../constants/signup-texts'
import { EmailFormatRegex } from '../../helpers/regex'
import { HeightPlaceHolderForError } from '../email-confirm-input/style'

export const EmailInput: React.FC<IInputProps> = ({
  callback,
  hasError,
  errorText,
  className,
}) => {
  const handleChange = (e: any) => {
    e.preventDefault()
    const error = !EmailFormatRegex.test(e.target.value)
    callback(e.target.value, error)
  }

  return (
    <EmailInputContainer
      className={`email ${className ?? ''} ${hasError ? 'error' : ''}`}
    >
      <Label htmlFor="email">{SignupTexts.Email}</Label>
      <Input
        type="email"
        name="email"
        onChange={(e: any) => handleChange(e)}
      ></Input>
      <HeightPlaceHolderForError>
        <Error hasError={hasError} errorText={errorText}></Error>
      </HeightPlaceHolderForError>
    </EmailInputContainer>
  )
}
