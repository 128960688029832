import styled from 'styled-components/macro'
import mobileWelcomeBackground from '../../../../assets/images/mobile_welcome_bg.png'
import desktopWelcomeBackground from '../../../../assets/images/desktop_login_bg.jpg'
import { Button } from '../../../../shared/components/button/Button'
import { device } from '../../../../constants/media-query'

export const WelcomeContainer = styled.div`
  width: 100%;
  height: 100vh;
  background: url(${mobileWelcomeBackground}), linear-gradient(0deg, rgba(0, 0, 0, 0.76) 15.55%, rgba(0, 0, 0, 0) 56.53%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  direction: rtl;
  padding: 0px 25px;
  position: relative;
  padding-bottom: 100px;

  @media ${device.desktop} {
    background: url(${desktopWelcomeBackground});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 50px 0 0
    width: 100%;
    justify-content: center;
  }
`

export const WelcomeContent = styled.div`
  z-index: 2;
`

export const WelcomeTitle = styled.span`
  display: block;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  letter-spacing: -0.025em;
  color: #ffffff;

  @media ${device.desktop} {
    width: 33%;
  }
`

export const WelcomeSubTitle = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.025em;
  color: #ffffff;
  margin-bottom: 35px;

  @media ${device.desktop} {
    width: 33%;
  }
`

export const WelcomeDescription = styled.span`
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.025em;
  color: #ffffff;
  margin-bottom: 35px;

  @media ${device.desktop} {
    width: 33%;
  }
`

export const ButtonWrapper = styled(Button)`
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  margin-bottom: 25px;
  background: #007638;
  color: #fff;
  padding: 20px 0;
  border: none;
  cursor: pointer;
  z-index: 2;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
  }

  @media ${device.desktop} {
    width: 33%;
  }
`

export const OverlayShadow = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  z-index: 1;
  height: 100%;
`

export const LinkToLoginContainer = styled.div`
  z-index: 2;
  color: white;
  text-decoration: underline;
  text-decoration-color: white;
  text-underline-offset: 4px;
`

export const ToLoginContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  z-index: 2;
  font-size: 18px;
  color: white;
`
