import styled from 'styled-components/macro'
import { theme } from 'styles/theme'

export const DropdownContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;
  position: relative;

  &.error {
    input {
      border: 1px solid #ff0000;
    }
  }
`

export const Label = styled.label<{ color?: string }>`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
  ${({ color }) => (color ? `color: ${color};` : '')}
`

export const Input = styled.input<{ bgColor?: string; color?: string }>`
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  padding: 20px 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
  border: 1px solid #9c9c9c;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : '')}
  ${({ color }) => (color ? `color: ${color};` : '')}

  &:focus {
    outline: none;
  }
`

export const OptionsList = styled.ul`
  position: absolute;
  list-style: none;
  padding: 0px;
  margin-top: 0px;
  height: 100px;
  overflow: scroll;
  background: rgb(255, 255, 255);
  width: 100%;
  z-index: 1;
  border: 1px solid rgb(156, 156, 156);
  border-top: none;
  box-sizing: border-box;
  color: ${theme.black};
`

export const OptionItem = styled.li`
  height: 25px;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background: #f3f3f3;
  }
`

export const NoOption = styled.div`
  position: absolute;
  list-style: none;
  padding: 0px;
  margin-top: 0px;
  height: 100px;
  overflow: scroll;
  background: rgb(255, 255, 255);
  width: 100%;
  z-index: 1;
  border: 1px solid rgb(156, 156, 156);
  border-top: none;
  box-sizing: border-box;
  color: ${theme.black};
`
