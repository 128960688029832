import { FunctionComponent, useMemo } from 'react'
import { useGetUserMembershipsAndGreenplusesQuery } from 'store/api-slices/userSlice'
import { CollapseProps } from 'antd'
import expandIconSvg from 'assets/icons/expand.svg'
import { Loader } from 'shared/components/loader/loader'
import { FlexColumn, FlexRow, TextSmall } from 'styles/generics'
import { theme } from 'styles/theme'
import {
  CollapseLabelContainer,
  EmptyStateText,
  LabelHeaderText,
  ListHeader,
  MembershipsNestedListHeaderText,
  NestedLabelHeaderText,
  NestedListHeaderContainer,
  NestedListHeaderText,
  OrderHistoryList,
  ParentStyledCollapse,
  StyledCollapse,
} from './styles'
import { purchaseHistoryText } from 'constants/purchase-history-text'
import { utilsService } from 'services/utils.service'
import { IMembershipData } from 'types/interfaces/IMembershipData'
import { IGreenplusData } from 'types/interfaces/IGreenplusData'
import membershipImg from 'assets/images/membership.png'
import greenplusImg from 'assets/images/greenplus.png'
import { ImageWrapper } from 'shared/components/ImageWrapper'
import { VerticalLine } from 'shared/components/VerticalLine'
import styled from 'styled-components/macro'
import userSvg from 'assets/icons/user.svg'
import idSvg from 'assets/icons/id.svg'
import locationSvg from 'assets/icons/location.svg'
import { useIsMobile } from 'shared/helpers/useIsMobile'

const MembershipsAndGreenpluses: FunctionComponent = () => {
  const { data: membershipsAndGreenpluses, isLoading } =
    useGetUserMembershipsAndGreenplusesQuery(undefined)
  const isMobile = useIsMobile()

  const customExpandIcon: CollapseProps['expandIcon'] = (panelProps) => (
    <img
      src={expandIconSvg}
      alt="expand icon"
      style={{
        transform: `rotate(${panelProps.isActive ? 0 : 90}deg)`,
        transition: 'transform 0.2s',
      }}
    />
  )

  const isGreenplusData = (
    item: IMembershipData | IGreenplusData,
  ): item is IGreenplusData => {
    return (item as IGreenplusData).basePrice !== undefined
  }

  const membershipsAndGreenplusesCollapseItems: CollapseProps['items'] =
    useMemo(() => {
      if (!membershipsAndGreenpluses) return []
      const sortedYears = Object.keys(membershipsAndGreenpluses).sort(
        (a, b) => parseInt(b) - parseInt(a),
      )

      return sortedYears.map((year) => ({
        key: year,
        label: (
          <CollapseLabelContainer>
            <LabelHeaderText color={theme.white}>{year}</LabelHeaderText>
          </CollapseLabelContainer>
        ),
        children: (
          <FlexColumn gap={10}>
            {/* Header Row */}
            <NestedListHeaderContainer justify="space-between" align="center">
              <MembershipsNestedListHeaderText flex={1.5} width="15%">
                {
                  purchaseHistoryText.membershipsAndGreenpluses.listHeaders
                    .productType
                }
              </MembershipsNestedListHeaderText>
              <MembershipsNestedListHeaderText flex={2} width="20%">
                {
                  purchaseHistoryText.membershipsAndGreenpluses.listHeaders
                    .buyDate
                }
              </MembershipsNestedListHeaderText>
              <MembershipsNestedListHeaderText flex={2} width="20%">
                {
                  purchaseHistoryText.membershipsAndGreenpluses.listHeaders
                    .expirationDate
                }
              </MembershipsNestedListHeaderText>
              <NestedListHeaderText width="20%" shouldHideMobile>
                {
                  purchaseHistoryText.membershipsAndGreenpluses.listHeaders
                    .creditSpent
                }
              </NestedListHeaderText>
              <NestedListHeaderText width="10%">
                {
                  purchaseHistoryText.membershipsAndGreenpluses.listHeaders
                    .totalPrice
                }
              </NestedListHeaderText>
            </NestedListHeaderContainer>
            {/* Memberships and Greenpluses Rows */}
            <StyledCollapse
              accordion
              items={membershipsAndGreenpluses[year].map(
                (item: IMembershipData | IGreenplusData) => ({
                  key: item.sfId || item.roboticketId,
                  label: (
                    <CollapseLabelContainer
                      justify="space-between"
                      align="center"
                    >
                      <NestedLabelHeaderText width={isMobile ? '18%' : '16%'}>
                        {isGreenplusData(item) ? 'גרין+' : 'עונתי'}
                      </NestedLabelHeaderText>
                      <NestedLabelHeaderText width={isMobile ? '28%' : '15%'}>
                        {utilsService.convertSfDatetoIsraeliDate(
                          item.buyDate,
                          '.',
                        ) || '-'}
                      </NestedLabelHeaderText>
                      <NestedLabelHeaderText width={isMobile ? '28%' : '18%'}>
                        {utilsService.convertSfDatetoIsraeliDate(
                          item.expirationDate,
                          '.',
                        ) || '-'}
                      </NestedLabelHeaderText>
                      <NestedLabelHeaderText width="14%" shouldHideMobile>
                        {item.creditAmountSpent || '-'}
                        {item.creditAmountSpent &&
                          ' ' + purchaseHistoryText.points}
                      </NestedLabelHeaderText>
                      <NestedLabelHeaderText width="10%">
                        {item.productPrice || '-'}
                      </NestedLabelHeaderText>
                    </CollapseLabelContainer>
                  ),
                  children: (
                    <FlexColumn gap={15}>
                      {item.creditAmountSpent && isMobile && (
                        <FlexRow gap={10}>
                          {item.creditAmountSpent && (
                            <LabelHeaderText width="30%" color={theme.white}>
                              {`${purchaseHistoryText.shopifyOrders.creditSpent + ': ' + item.creditAmountSpent}`}
                            </LabelHeaderText>
                          )}
                        </FlexRow>
                      )}
                      <FlexRow gap={6} align="center">
                        <ImageWrapper
                          alt={
                            isGreenplusData(item) ? 'greenplus' : 'membership'
                          }
                          width="70px"
                          src={
                            isGreenplusData(item) ? greenplusImg : membershipImg
                          }
                        />
                        <VerticalLine bgColor={theme.gray500} height="70px" />
                        <FlexColumn>
                          <MembershipDetailsText color={theme.white}>
                            {isGreenplusData(item)
                              ? purchaseHistoryText.membershipsAndGreenpluses
                                  .greenplus +
                                ' ' +
                                utilsService.serializeGreenplusBaseprice(
                                  item.basePrice,
                                )
                              : purchaseHistoryText.membershipsAndGreenpluses
                                  .seasonalMembership +
                                ' ' +
                                item.season}
                          </MembershipDetailsText>
                          <FlexRow gap={5}>
                            <ImageWrapper
                              src={userSvg}
                              alt="user"
                              width="15px"
                            />
                            <MembershipDetailsText color={theme.white}>
                              {item.ownerName}
                            </MembershipDetailsText>
                          </FlexRow>
                          {!isGreenplusData(item) && (
                            <>
                              {item.subscriptionId && (
                                <FlexRow gap={5}>
                                  <ImageWrapper
                                    src={idSvg}
                                    alt="id"
                                    width="16px"
                                  />
                                  <MembershipDetailsText color={theme.white}>
                                    {item.subscriptionId}
                                  </MembershipDetailsText>
                                </FlexRow>
                              )}
                              {(item.seatNumber ||
                                item.row ||
                                item.sectorName) && (
                                <FlexRow gap={5}>
                                  <ImageWrapper
                                    src={locationSvg}
                                    alt="location"
                                    width="16px"
                                  />
                                  <MembershipDetailsText color={theme.white}>
                                    {item.sectorName
                                      ? `${purchaseHistoryText.membershipsAndGreenpluses.area}: ${item.sectorName}`
                                      : ''}
                                    {item.sectorName && item.row ? ', ' : ''}
                                    {item.row
                                      ? `${purchaseHistoryText.membershipsAndGreenpluses.row} ${item.row}`
                                      : ''}
                                    {item.row && item.seatNumber ? ', ' : ''}
                                    {item.seatNumber
                                      ? `${purchaseHistoryText.membershipsAndGreenpluses.seat} ${item.seatNumber}`
                                      : ''}
                                  </MembershipDetailsText>
                                </FlexRow>
                              )}
                            </>
                          )}
                        </FlexColumn>
                      </FlexRow>
                    </FlexColumn>
                  ),
                }),
              )}
              expandIcon={customExpandIcon}
            />
          </FlexColumn>
        ),
      }))
    }, [membershipsAndGreenpluses])

  return isLoading ? (
    <Loader mt="40px" mb="40px" />
  ) : membershipsAndGreenplusesCollapseItems.length > 0 ? (
    <>
      <ListHeader mb="20px" width="100%" justify="space-between"></ListHeader>
      <OrderHistoryList>
        <ParentStyledCollapse
          accordion
          items={membershipsAndGreenplusesCollapseItems}
          expandIcon={customExpandIcon}
        />
      </OrderHistoryList>
    </>
  ) : (
    <EmptyStateText>
      {
        purchaseHistoryText.membershipsAndGreenpluses
          .noAvailableMembershipsAndGreenpluses
      }
    </EmptyStateText>
  )
}

const MembershipDetailsText = styled(TextSmall)`
  line-height: 20px;
`

export { MembershipsAndGreenpluses }
