import styled from 'styled-components/macro'
import { Button } from '../../../../shared/components/button/Button'
import { theme } from 'styles/theme'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'

export const AddressWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .dropdown {
    width: 65%;
  }

  .numberInput {
    width: 30%;
  }
`

export const SubmitButtonWrapper = styled(Button)`
  font-family: 'Atlas-AAA', sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  background: ${theme.mhGreen};
  color: #fff;
  padding: 20px 0;
  border: none;
  width: 155px;
  cursor: pointer;

  &.disabled {
    background: rgba(0, 118, 56, 0.3);
  }

  ${({ theme }) => theme.media.sm`
  width: 240px;
  `}
`

export const UserDetailsFormEditComment = styled.div<{
  shouldDisplay?: boolean
  mb?: string
}>`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.025em;
  font-family: ${EAppFontFamilies.ATLAS_BOLD};
  text-align: center;
  color: ${theme.white};
  margin-bottom: 20px;
  padding: 0 25px;
  transition: opacity 0.2s ease-in-out;
  opacity: ${({ shouldDisplay }) => (shouldDisplay ? 1 : 0)};
  ${({ mb }) => mb && `margin-bottom: ${mb}`};
`
