import productImageFallback from 'assets/images/product-fallback.png'
import expandIconSvg from 'assets/icons/expand.svg'
import { FunctionComponent, useEffect, useMemo } from 'react'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import { useGetUserShopifyOrdersQuery } from 'store/api-slices/userSlice'
import { FlexColumn, FlexRow, Image, Span, TextRegular } from 'styles/generics'
import { theme } from 'styles/theme'
import { IShopifyOrder } from 'types/interfaces/IShopifyOrder'
import { CollapseProps } from 'antd'
import { Loader } from 'shared/components/loader/loader'
import {
  CollapseLabelContainer,
  EmptyStateText,
  LabelHeaderText,
  ListHeader,
  ListHeaderText,
  MobileExtraInfo,
  OrderHistoryList,
  ProductImageContainer,
  ProductPrice,
  StyledCollapse,
} from './styles'
import { purchaseHistoryText } from 'constants/purchase-history-text'
import { utilsService } from 'services/utils.service'
import styled from 'styled-components/macro'
import { device } from 'constants/media-query'
import { orderService } from 'services/order.service'
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { errorStatusCodes } from 'constants/errors'
import { useNavigate } from 'react-router-dom'
import { EAppRoutes } from 'types/enums/EAppRoutes'

const ShopifyOrders: FunctionComponent = () => {
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const {
    data: shopifyOrders,
    isLoading,
    error,
  } = useGetUserShopifyOrdersQuery(undefined)

  useEffect(() => {
    if (
      error &&
      (error as FetchBaseQueryError).status === errorStatusCodes.NOT_ACCEPTABLE
    )
      navigate(EAppRoutes.PENDING_PAGE)
  }, [error])

  const customExpandIcon: CollapseProps['expandIcon'] = (panelProps) => (
    <img
      src={expandIconSvg}
      alt="expand icon"
      style={{
        transform: `rotate(${panelProps.isActive ? 0 : 90}deg)`,
        transition: 'transform 0.2s',
      }}
    />
  )

  const shopifyOrdersCollapseItems: CollapseProps['items'] = useMemo(() => {
    return shopifyOrders?.map((order: IShopifyOrder) => ({
      key: order.id,
      label: (
        <CollapseLabelContainer>
          <LabelHeaderText
            justify="flex-start"
            width={isMobile ? '30%' : '22%'}
            color={theme.white}
          >
            {order.name.replace('test', '')}
          </LabelHeaderText>
          <LabelHeaderText width={isMobile ? '20%' : '13%'} color={theme.white}>
            {utilsService.convertDateToShortDate(order.createdAt)}
          </LabelHeaderText>
          <LabelHeaderText shouldHideMobile width="15%" color={theme.white}>
            {order.storeName || '-'}
          </LabelHeaderText>
          <LabelHeaderText shouldHideMobile width="16%" color={theme.white}>
            {order.creditAccumulationAmount || '-'}
          </LabelHeaderText>
          <LabelHeaderText shouldHideMobile width="15%" color={theme.white}>
            {order.creditAmountSpent || '-'}
          </LabelHeaderText>
          <LabelHeaderText
            color={theme.white}
            fontFamily="Atlas-AAA-Medium"
            fontSize={isMobile ? '16px' : '20px'}
            width={isMobile ? '24%' : '12%'}
          >
            <Span fontSize={isMobile ? '16px' : '20px'}>₪</Span>
            {order.currentTotalPrice}
          </LabelHeaderText>
        </CollapseLabelContainer>
      ),
      children: (
        <FlexColumn gap={18}>
          {(order.storeName ||
            order.creditAccumulationAmount ||
            (order.creditAmountSpent !== undefined &&
              order.creditAmountSpent !== null &&
              order.creditAmountSpent !== 0)) &&
            isMobile && (
              <MobileExtraInfo>
                {order.storeName && (
                  <LabelHeaderText width="15%" color={theme.white}>
                    {`${purchaseHistoryText.shopifyOrders.store + ': ' + orderService.formatShopName(order.storeName)}`}
                  </LabelHeaderText>
                )}
                {order.creditAccumulationAmount && (
                  <LabelHeaderText width="16%" color={theme.white}>
                    {`${purchaseHistoryText.shopifyOrders.creditAccumulationMobile + ': ' + order.creditAccumulationAmount}`}
                  </LabelHeaderText>
                )}
                {order.creditAmountSpent !== 0 && order.creditAmountSpent && (
                  <LabelHeaderText width="15%" color={theme.white}>
                    {`${purchaseHistoryText.shopifyOrders.creditSpentMobile + ': ' + order.creditAmountSpent}`}
                  </LabelHeaderText>
                )}
              </MobileExtraInfo>
            )}
          {order.lineItems.map((item) => {
            return (
              <FlexRow gap={16} key={item.shopifyProductId}>
                <ProductImageContainer>
                  <Image
                    src={item.productImageUrl || productImageFallback}
                    alt="product image"
                    width="100%"
                    height="100%"
                  />
                </ProductImageContainer>
                <FlexColumn gap={5}>
                  <TextRegular
                    fontFamily="Atlas-AAA-Medium"
                    color={theme.white}
                  >
                    {item.name}
                  </TextRegular>
                  {item.quantity && (
                    <TextRegular color={theme.white}>
                      כמות: {item.quantity}
                    </TextRegular>
                  )}
                </FlexColumn>
                <ProductPrice fontFamily="Atlas-AAA-Medium" color={theme.white}>
                  <Span fontFamily="Atlas-AAA-Medium" fontSize="12px">
                    ₪
                  </Span>
                  {item.price}
                </ProductPrice>
              </FlexRow>
            )
          })}
        </FlexColumn>
      ),
    }))
  }, [shopifyOrders, isMobile])

  return isLoading ? (
    <Loader mt="40px" mb="40px" />
  ) : shopifyOrders?.length > 0 ? (
    <ShopifyOrdersContainer>
      <ShopifyOrdersListHeader
        mb="20px"
        width="100%"
        justify="space-between"
        mt="25px"
      >
        <ListHeaderText width={isMobile ? '33%' : '24%'}>
          {purchaseHistoryText.shopifyOrders.orderNumber}
        </ListHeaderText>
        <ListHeaderText width={isMobile ? '' : '14%'}>
          {purchaseHistoryText.shopifyOrders.date}
        </ListHeaderText>
        <ListHeaderText shouldHideMobile width={isMobile ? '' : '12%'}>
          {purchaseHistoryText.shopifyOrders.store}
        </ListHeaderText>
        <ListHeaderText shouldHideMobile width={isMobile ? '' : '15%'}>
          {purchaseHistoryText.shopifyOrders.creditAccumulation}
        </ListHeaderText>
        <ListHeaderText shouldHideMobile width={isMobile ? '' : '14%'}>
          {purchaseHistoryText.shopifyOrders.creditSpent}
        </ListHeaderText>
        <ListHeaderText width={isMobile ? '' : '7%'}>
          {purchaseHistoryText.shopifyOrders.total}
        </ListHeaderText>
      </ShopifyOrdersListHeader>
      <OrderHistoryList>
        <StyledCollapse
          accordion
          items={shopifyOrdersCollapseItems}
          expandIcon={customExpandIcon}
        />
      </OrderHistoryList>
    </ShopifyOrdersContainer>
  ) : (
    <EmptyStateText>אין היסטוריית רכישות</EmptyStateText>
  )
}

const ShopifyOrdersContainer = styled(FlexColumn)`
  overflow: scroll;
  width: 100%;
  max-height: 300px;

  @media ${device.desktop} {
    max-height: 500px;
  }
}
`

const ShopifyOrdersListHeader = styled(ListHeader)`
  padding 0 20px;

  @media ${device.xs} {
    margin-inline-end: 20px;
  }

  @media ${device.sm} {
    margin-inline-end: 40px;
  }

  @media ${device.desktop} {
    padding: 0;
    padding-inline-start: 20px;
  }
`

export { ShopifyOrders }
