import styled from 'styled-components/macro'
import eyeIcon from '../../../assets/images/password_eye.svg'
import eyeIconHide from '../../../assets/images/password_eye_hide.svg'

export const PasswordInputContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;

  &.error {
    input {
      border: 1px solid #ff0000;
    }
  }
`

export const Label = styled.label`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
`

export const InputContainer = styled.div`
  position: relative;
`

export const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  padding: 20px 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
  border: 1px solid #9c9c9c;

  &::-ms-reveal {
    display: none;
  }

  &:focus {
    outline: none;
  }
`

export const ShowPasswordButton = styled.button`
  position: absolute;
  left: 1px;
  top: 1px;
  height: calc(100% - 2px);
  width: 40px;
  border-radius: 0;
  border: none;
  background: none;

  &:focus-visible {
    outline: none;
  }

  i {
    display: block;
    height: 100%;
    width: 100%;
    cursor: pointer;

    &.password-eye {
      background: url(${eyeIcon});
      background-position: center;
      background-repeat: no-repeat;
    }

    &.password-eye-hide {
      background: url(${eyeIconHide});
      background-position: center;
      background-repeat: no-repeat;
    }
  }
`
