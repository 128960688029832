import { Fragment, FunctionComponent, useMemo } from 'react'
import { SectionContainer } from './SectionContainer'
import { personalAreaTexts } from 'constants/personal-area-texts'
import { useAppSelector } from 'store/store'
import {
  FlexColumn,
  FlexRow,
  Heading3,
  Image,
  Text,
  TextRegular,
} from 'styles/generics'
import styled from 'styled-components/macro'
import girlSvg from 'assets/icons/girl.svg'
import boySvg from 'assets/icons/boy.svg'
import { theme } from 'styles/theme'
import { HorizontalLine } from 'shared/components/horizontal-line/HorizontalLine'
import { utilsService } from 'services/utils.service'
import { VerticalLine } from 'shared/components/VerticalLine'
import { GreenplusIcon } from 'shared/components/GreenplusIcon'
import { WhiteCircle } from '../style'
import { AddRelationshipLink } from './AddRelationshipLink'
import { device } from 'constants/media-query'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import maleFemaleSvg from 'assets/icons/male-female.svg'
import { IMembershipData } from 'types/interfaces/IMembershipData'
import { IGreenplusData } from 'types/interfaces/IGreenplusData'

const MyChildren: FunctionComponent = () => {
  const user = useAppSelector((state) => state.user)
  const isMobile = useIsMobile()
  const children = user?.children

  const getChildGenderIcon = (gender: 'Male' | 'Female' | 'Other') => {
    switch (gender) {
      case 'Male':
        return boySvg
      case 'Female':
        return girlSvg
      default:
        return maleFemaleSvg
    }
  }

  const childrenToDisplay = useMemo(() => {
    if (!children?.length) return
    return children.map((child) => (
      <Fragment key={child.id}>
        <ChildContainer>
          <ChildPersonalDetails gap={3}>
            <WhiteCircle iconName={child.gender}>
              <Image src={getChildGenderIcon(child.gender)} alt="child-icon" />
            </WhiteCircle>
            <DetailsContainer>
              <ChildName>
                {(child.firstName || '') + ' ' + (child.lastName || '')}
              </ChildName>
              <BirthDate>
                {utilsService.convertSfDatetoIsraeliDate(child.dob)}
              </BirthDate>
            </DetailsContainer>
          </ChildPersonalDetails>
          <VerticalLine />
          <MembershipsContainer width="100%">
            {(child?.greenpluses?.length !== 0 ||
              child?.memberships?.length !== 0) && (
              <MembershipsTitleRow justify="space-between" mb="5px">
                <HeadingTitle>
                  {personalAreaTexts.general.activeMemberships}
                </HeadingTitle>
                <HeadingTitle>{personalAreaTexts.general.validTo}</HeadingTitle>
              </MembershipsTitleRow>
            )}
            {child.greenpluses?.map(
              (greenplus: IGreenplusData, idx: number) => (
                <Fragment key={greenplus.sfId}>
                  <MembershipRow justify="space-between" mb="5px">
                    <FlexRow gap={5}>
                      <TextRegular>
                        {utilsService.serializeGreenplusBaseprice(
                          greenplus.basePrice,
                        )}
                      </TextRegular>
                      <GreenplusIcon />
                    </FlexRow>
                    <TextRegular>
                      {utilsService.convertSfDatetoIsraeliDate(
                        greenplus.expirationDate,
                      )}
                    </TextRegular>
                  </MembershipRow>
                  {!(
                    child?.greenpluses &&
                    idx !== child?.greenpluses?.length - 1 &&
                    child.memberships?.length
                  ) && (
                    <HorizontalLine
                      mb="7px"
                      mt="4px"
                      bgColor={theme.grayBorder}
                    />
                  )}
                </Fragment>
              ),
            )}
            {child.memberships?.map(
              (membership: IMembershipData, idx: number) => (
                <Fragment key={membership.sfId}>
                  <MembershipRow justify="space-between" mb="5px">
                    <TextRegular>
                      {personalAreaTexts.mySeason.seasonalMembership}
                    </TextRegular>
                    <TextRegular>
                      {utilsService.convertSfDatetoIsraeliDate(
                        membership.expirationDate,
                      )}
                    </TextRegular>
                  </MembershipRow>
                  {child.memberships &&
                    idx !== child.memberships?.length - 1 && (
                      <HorizontalLine
                        mb="7px"
                        mt="4px"
                        bgColor={theme.grayBorder}
                      />
                    )}
                </Fragment>
              ),
            )}
          </MembershipsContainer>
        </ChildContainer>
        {!isMobile && (
          <HorizontalLine mb="15px" mt="15px" bgColor={theme.white} />
        )}
      </Fragment>
    ))
  }, [children, isMobile])

  if (!children?.length) return null
  return (
    <SectionContainer title={personalAreaTexts.myChildren.title}>
      {childrenToDisplay}
      <AddRelationshipLink url={process.env.REACT_APP_BASE_ROBOTICKET_URL} />
    </SectionContainer>
  )
}

const ChildContainer = styled(FlexColumn)`
  margin-bottom: 35px;

  @media ${device.desktop} {
    margin-bottom: unset;
    flex-direction: row;
  }
`

const ChildPersonalDetails = styled(FlexRow)`
  padding-inline-end: 20px;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;

  @media ${device.desktop} {
    min-width: 100px;
    margin-bottom: 0;
    padding-inline-end: 0;
    align-items: center;
    flex-direction: column;
    white-space: nowrap;
  }
`

const ChildName = styled(Text)`
  font-size: 24px;
  font-family: ${EAppFontFamilies.ATLAS_500};

  @media ${device.desktop} {
    font-size: 16px;
    font-family: ${EAppFontFamilies.ATLAS_REGULAR};
  }
`

const BirthDate = styled(Text)`
  font-size: 20px;

  @media ${device.desktop} {
    font-size: 16px;
  }
`

const DetailsContainer = styled(FlexColumn)`
  @media ${device.desktop} {
    align-items: center;
  }
`

const MembershipsContainer = styled(FlexColumn)`
  padding-inline-start: 15px;
  padding-inline-end: 12px;

  @media ${device.desktop} {
    padding-inline-start: 20px;
    padding-inline-end: 40px;
  }
`

const HeadingTitle = styled(Heading3)`
  font-family: 'Atlas-AAA-Medium';
  font-size: 20px;
  margin-bottom: 10px;

  @media ${device.desktop} {
    margin-bottom: unset;
    font-size: 24px;
  }
`

const MembershipsTitleRow = styled(FlexRow)`
  width: 100%;
`

const MembershipRow = styled(FlexRow)`
  padding-inline-end: 5px;
`

export { MyChildren }
