import { Collapse } from 'antd'
import { device } from 'constants/media-query'
import styled from 'styled-components/macro'
import { FlexColumn, FlexRow, Text, TextRegular } from 'styles/generics'
import { mixins } from 'styles/mixins'
import { theme } from 'styles/theme'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'

const CollapseLabelContainer = styled(FlexRow)`
  @media ${device.mobileOnly} {
    &:first-child {
    }
  }

  white-space: nowrap;
  padding-inline-start: 3px;
  justify-content: space-between;
  width: 100%;
  text-align: center;
  align-items: center;
`

const ListHeader = styled(FlexRow)<{ padding?: string }>`
  color: ${theme.white};
  ${({ padding }) => padding && `padding: ${padding}`};
  width: 93%;
`

const ListHeaderText = styled(Text)<{ shouldHideMobile?: boolean }>`
  @media ${device.mobileOnly} {
    ${({ shouldHideMobile }) => shouldHideMobile && 'display: none;'}
  }

  font-family: ${EAppFontFamilies.ATLAS_REGULAR};
  font-size: 20px;
  display: flex;
  justify-content: center;

  @media ${device.desktop} {
    white-space: nowrap;
    font-size: 20px;
  }
`

const LabelHeaderText = styled(Text)<{
  color?: string
  width?: string
  fontSize?: string
  lineHeight?: string
  shouldHideMobile?: boolean
  justify?: string
}>`
  @media ${device.mobileOnly} {
    ${({ shouldHideMobile }) => shouldHideMobile && 'display: none;'}
  }

  ${({ fontSize }) => `font-size: ${fontSize ? fontSize : '16px'};`}
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight};`}
  flex: 0 0 ${({ width }) => width};

  @media ${device.desktop} {
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ justify }) => justify && `justify-content: ${justify};`}
    font-size: 20px;
    ${({ fontSize }) => fontSize && `font-size: ${fontSize};`}
  }
`

const OrderHistoryList = styled(FlexColumn)`
  width: 100%;
  align-items: center;
  margin-bottom: 35px;
  overflow: auto;
  color: ${theme.white};

  ${mixins.hideScrollbar}
`

const StyledCollapse = styled(Collapse)`
  border: none;
  width: 100%;
  background-color: ${theme.transparent};
  border-radius: unset;

  & .ant-collapse-header {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    align-items: center !important;
    border-radius: 2px;
  }

  & .ant-collapse-content-box {
    padding: 18px 0px 18px 20px !important;
    border-radius: 2px;
  }

  & .ant-collapse-item {
    border-bottom: none !important;
    border-radius: 2px;
    padding: 0 8px;

    & .ant-collapse-content {
      border: none;
      background-color: ${theme.transparent};
    }
  }

  & .ant-collapse-item:nth-child(odd) {
    & .ant-collapse-header {
      background-color: ${theme.gray400};
      box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.1);
    }
  }

  & .ant-collapse-item:nth-child(even) {
    & .ant-collapse-header {
      background-color: transparent;
    }
  }

  @media ${device.desktop} {
    & .ant-collapse-content-box {
      padding-inline-start: 0 !important;
    }
  }
`

const ProductImageContainer = styled.div`
  height: 85px;
  width: 64px;
  min-width: 64px;
`

const ProductPrice = styled(TextRegular)`
  margin-inline-start: auto;
`

const EmptyStateText = styled(TextRegular)`
  color: ${theme.white};
  margin-top: 40px;

  &:visited {
    color: ${theme.white};
  }
`

const MobileExtraInfo = styled(FlexRow)`
  & > * {
    flex: 1;
    display: flex;
    justify-content: center;
  }

  & > :first-child {
    flex: 1.9;
  }
`

// #region Nested Collapse
const ParentStyledCollapse = styled(Collapse)`
  border: none;
  width: 100%;
  background-color: ${theme.transparent};
  border-radius: unset;

  & .ant-collapse-header {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    align-items: center !important;
    border-radius: unset !important;
  }

  & .ant-collapse-content-box {
    padding: 5px 0 0 0 !important;

    & .ant-collapse-content-box {
      padding-inline-start: 5px !important;
    }

    & .ant-collapse-item {
      padding: 0;
    }

    & .ant-collapse-expand-icon {
      padding: 0 !important;
      margin: 0 !important;
      & .ant-collapse-arrow {
        width: 9px;
      }
    }
  }

  & .ant-collapse-item {
    border-radius: unset;
    border-bottom: none !important;
    padding: 0 8px;

    & .ant-collapse-content {
      border: none;
      background-color: ${theme.transparent};
    }
  }

  & .ant-collapse-header {
    background-color: ${theme.gray400};
    margin-bottom: 4px;
    box-shadow: unset !important;
  }
`

const NestedListHeaderContainer = styled(FlexRow)`
  @media ${device.mobileOnly} {
    padding: 0 20px 0 15px;

    & > * {
      flex: 1;
    }
  }

  padding: 0 30px 0 20px;
`

const NestedListHeaderText = styled(TextRegular)<{
  shouldHideMobile?: boolean
  paddingInlineEndMobile?: string
  justifyMobile?: string
}>`
  @media ${device.mobileOnly} {
    ${({ shouldHideMobile }) => shouldHideMobile && 'display: none;'}
    ${({ paddingInlineEndMobile }) =>
      paddingInlineEndMobile &&
      `padding-inline-end: ${paddingInlineEndMobile}`};
    ${({ justifyMobile }) =>
      justifyMobile && `justify-content: ${justifyMobile}`};
  }

  color: ${theme.white};
  display: flex;
  justify-content: center;

  &:last-child {
    justify-content: flex-end;
  }
`

const MembershipsNestedListHeaderText = styled(NestedListHeaderText)<{
  flex?: number
}>`
  @media ${device.mobileOnly} {
    ${({ flex }) => flex && `flex: ${flex}`}
  }
`

const NestedLabelHeaderText = styled(LabelHeaderText)<{
  isCanceled?: boolean
  paddingInlineEndMobile?: string
}>`
  @media ${device.mobileOnly} {
    ${({ paddingInlineEndMobile }) =>
      paddingInlineEndMobile &&
      `padding-inline-end: ${paddingInlineEndMobile};`}
  }

  line-height: 20px;
  color: ${theme.white};
  font-size: 18px;
  text-decoration: ${({ isCanceled }) =>
    isCanceled ? 'line-through' : 'none'};
`
// #endregion

export {
  CollapseLabelContainer,
  ListHeader,
  ListHeaderText,
  LabelHeaderText,
  OrderHistoryList,
  StyledCollapse,
  ProductImageContainer,
  ProductPrice,
  EmptyStateText,
  ParentStyledCollapse,
  NestedListHeaderContainer,
  NestedListHeaderText,
  NestedLabelHeaderText,
  MobileExtraInfo,
  MembershipsNestedListHeaderText,
}
