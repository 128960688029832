import React, { useEffect, useState } from 'react'
import { IdInput } from '../../../../../../../../shared/components/id-input/IdInput'
import { EPersonalDetailsFormSteps } from '../../../../../../../../types/enums/EPersonalDetailsFormSteps'
import {
  BackButtonWrapper,
  SubmitButtonWrapper,
} from '../personal-details-form-personal-info/style'
import {
  useAppDispatch,
  useAppSelector,
} from '../../../../../../../../store/store'
import { IUser } from '../../../../../../../../types/interfaces/IUser'
import { updateUser } from '../../../../../../../../store/reducers/userReducer'
import {
  useSetUserOnPendingMutation,
  useUpdateSfUserMutation,
} from '../../../../../../../../store/api-slices/userSlice'
import { UserExistsError } from '../../../signup-form-password/style'
import {
  DUPLICATE_ID,
  errorStatusCodes,
} from '../../../../../../../../constants/errors'
import { Loader } from '../../../../../../../../shared/components/loader/loader'
import {
  ErrorTexts,
  SignupTexts,
} from '../../../../../../../../constants/signup-texts'
import { MhfcIdInput } from 'shared/components/mhfc-id-input/MhfcIdInput'
import { EAppRoutes } from 'types/enums/EAppRoutes'
import { IdInputComment, IdInputSkipButton } from './style'
import { AUTH_USER_TOKEN_KEY, expirationTimeInMinutes } from 'constants/auth'
import { cookieService } from 'services/cookie.service'
import { useAppLogout } from 'shared/helpers/useAppLogout'
import { Tooltip } from 'antd'
import { InputOverlayContainer } from '../personal-details-form-extra-personal-info/PersonalDetailsFormExtraPersonalInfo'
import { HeightPlaceHolderForError } from 'shared/components/email-confirm-input/style'
import { getCurrentAuthenticatedUserInfo } from 'services/AuthService'

export const PersonalDetailsFormThirdStep: React.FC<any> = ({
  setSignupFormTexts,
  onNextClicked,
}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector<IUser>((state) => state.user)
  const [personalInfoFormData, setPersonalInfoFormData] = useState<{
    id: string
    mhfcId: string
  }>({
    id: user?.personalDetails?.id ?? '',
    mhfcId: user?.personalDetails?.mhfcId ?? '',
  })
  const [errors, setErrors] = useState<{
    id?: boolean
    mhfcId?: boolean
  }>({
    id: false,
    mhfcId: false,
  })
  const [isNextButtonDisabled, setIsNextButtonDisabled] =
    useState<boolean>(true)
  const [isMhfcIdDisabled, setIsMhfcIdDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [showUserIdExistsError, setShowUserIdExistsError] =
    useState<boolean>(false)
  const [showConflictError, setShowConflictError] = useState(false)
  const [updateSfUser] = useUpdateSfUserMutation()
  const [setUserOnPending] = useSetUserOnPendingMutation()
  const logout = useAppLogout()
  const [didUserChooseTz, setDidUserChooseTz] = useState<boolean>(false)
  const formatId = (id: string) => {
    if (id.length >= 2) {
      return `${id[0]}${'*'.repeat(Math.max(0, id.length - 2))}${id[id.length - 1]}`
    }
    return id
  }

  const onFinishStep = async () => {
    const cognitoUser = await getCurrentAuthenticatedUserInfo()
    setShowConflictError(false)
    setShowUserIdExistsError(false)
    cookieService.setCookie(
      AUTH_USER_TOKEN_KEY,
      cognitoUser?.token,
      expirationTimeInMinutes,
    )
    onNextClicked(EPersonalDetailsFormSteps.last)
  }
  useEffect(() => {
    setSignupFormTexts({
      title: SignupTexts.JoinTheClub,
      subtitle: SignupTexts.YayLastPageAndYouAreConnect,
      subtitleSecondRow: SignupTexts.NoticeToFillAccurateDetails,
    })

    const isIdValid =
      (personalInfoFormData?.id?.length === 9 &&
        personalInfoFormData?.id !== '') ||
      personalInfoFormData?.id === ''

    setErrors((prevErrors) => ({ ...prevErrors, id: !isIdValid }))
  }, [])

  useEffect(() => {
    if (personalInfoFormData.id && !errors.id) {
      setIsNextButtonDisabled(false)
    } else if (!isNextButtonDisabled) {
      setIsNextButtonDisabled(true)
    }
  }, [personalInfoFormData.id, errors])

  const handleIdChange = (value: any, error?: boolean) => {
    if (value === '') {
      setErrors({ ...errors, id: false })
    } else {
      const isIdValid = value.length >= 9
      setErrors({ ...errors, id: error || !isIdValid })
    }
    setShowUserIdExistsError(false)
    setPersonalInfoFormData({ ...personalInfoFormData, id: value })
  }

  const handleMhfcIdChange = (value: any) => {
    setShowConflictError(false)
    setPersonalInfoFormData({ ...personalInfoFormData, mhfcId: value })
  }

  const nextButtonClicked = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)

    if (!isNextButtonDisabled && isMhfcIdDisabled) {
      dispatch(
        updateUser({
          ...user,
          personalDetails: {
            ...user?.personalDetails,
            id: personalInfoFormData?.id,
          },
        }),
      )

      const sfUserToUpdate = {
        id: personalInfoFormData?.id,
      }

      const response = await updateSfUser(sfUserToUpdate)

      if ('error' in response) {
        if (response.error) {
          if ('data' in response.error) {
            const errorData = response?.error?.data as {
              errorToClient?: string
              statusCode?: number
            }
            if (errorData?.errorToClient === DUPLICATE_ID) {
              setShowUserIdExistsError(true)
            } else if (errorData?.statusCode === errorStatusCodes?.CONFLICT) {
              setShowConflictError(true)
            } else if (
              errorData?.statusCode === errorStatusCodes.NOT_ACCEPTABLE
            ) {
              logout(EAppRoutes.PENDING_PAGE, false, {
                firstName: user?.personalDetails?.firstName,
                lastName: user?.personalDetails?.lastName,
              })
            } else {
              setDidUserChooseTz(true)
              setIsMhfcIdDisabled(false)
            }
          } else {
            onFinishStep()
          }
        }
      } else {
        onFinishStep()
      }
    } else if (!isNextButtonDisabled && !isMhfcIdDisabled) {
      dispatch(
        updateUser({
          ...user,
          personalDetails: {
            ...user?.personalDetails,
            id: personalInfoFormData?.id,
            mhfcId: personalInfoFormData?.mhfcId,
          },
        }),
      )

      const sfUserToUpdate = {
        id: personalInfoFormData?.id,
        oldMhfcId: personalInfoFormData?.mhfcId,
      }

      const response = await updateSfUser(sfUserToUpdate)

      if ('error' in response) {
        if (response.error) {
          if ('data' in response.error) {
            const errorData = response?.error?.data as {
              errorToClient?: string
              statusCode?: number
            }
            if (errorData?.errorToClient === DUPLICATE_ID) {
              setShowUserIdExistsError(true)
            } else if (errorData?.statusCode === errorStatusCodes?.CONFLICT) {
              setShowConflictError(true)
            } else if (
              errorData?.statusCode === errorStatusCodes.NOT_ACCEPTABLE
            ) {
              logout(EAppRoutes.PENDING_PAGE, false, {
                firstName: user?.personalDetails?.firstName,
                lastName: user?.personalDetails?.lastName,
              })
            }
          } else {
            onFinishStep()
          }
        }
      } else {
        onFinishStep()
      }
    }
    setIsLoading(false)
  }

  const handleNoToptixIdCLick = async () => {
    dispatch(
      updateUser({
        ...user,
        personalDetails: {
          ...user?.personalDetails,
          isPending: true,
        },
      }),
    )
    try {
      const response = await setUserOnPending().unwrap()
      if (response?.success)
        logout(EAppRoutes.PENDING_PAGE, false, {
          firstName: user?.personalDetails?.firstName,
          lastName: user?.personalDetails?.lastName,
        })
    } catch (error) {
      console.log('error setting user on pending')
    }
  }

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <>
      <h4>{SignupTexts.SignupFormConfirmAllDetailsAreValid}</h4>
      <IdInput
        value={
          user?.personalDetails?.id && user?.personalDetails?.id?.length > 0
            ? formatId(user?.personalDetails?.id).split('').reverse().join('')
            : personalInfoFormData?.id
        }
        disabled={user?.personalDetails?.id || didUserChooseTz ? true : false}
        hasError={errors.id}
        errorText={ErrorTexts.MisparZehutIsNotValid}
        callback={handleIdChange}
        userExistsError={showUserIdExistsError}
      ></IdInput>
      {!isMhfcIdDisabled && (
        <Tooltip title={SignupTexts.MhfcIdToolTip}>
          <InputOverlayContainer>
            <MhfcIdInput callback={handleMhfcIdChange}></MhfcIdInput>
          </InputOverlayContainer>
        </Tooltip>
      )}
      <HeightPlaceHolderForError>
        <UserExistsError className={showConflictError ? 'error' : ''}>
          {ErrorTexts.toptixIdConflictMsg}
        </UserExistsError>
      </HeightPlaceHolderForError>
      {!isMhfcIdDisabled && (
        <IdInputComment>
          <IdInputSkipButton
            text={ErrorTexts.doNotHaveToptixId}
            callback={() => {
              handleNoToptixIdCLick()
            }}
          />
        </IdInputComment>
      )}
      <SubmitButtonWrapper
        type="submit"
        disabled={isNextButtonDisabled}
        className={`buttonWrapper ${isNextButtonDisabled ? 'disabled' : ''}`}
        text={SignupTexts.Next}
        callback={(e: any) => nextButtonClicked(e)}
      />
      {!didUserChooseTz && (
        <BackButtonWrapper
          disabled={false}
          className={`buttonWrapper ${isNextButtonDisabled ? 'disabled' : ''}`}
          text={SignupTexts.Back}
          callback={() => {
            onNextClicked(EPersonalDetailsFormSteps.secondStep)
          }}
        />
      )}
    </>
  )
}
