import { FunctionComponent } from 'react'
import styled from 'styled-components/macro'
import greenplusSvg from 'assets/icons/greenplus.png'
import { device } from 'constants/media-query'

const GreenplusIcon: FunctionComponent = () => {
  return (
    <GreenplusIconContainer>
      <GreenplusIconImg src={greenplusSvg} />
    </GreenplusIconContainer>
  )
}

const GreenplusIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;

  @media ${device.desktop} {
    width: 60px;
  }
`

const GreenplusIconImg = styled.img`
  width: 100%;
`

export { GreenplusIcon }
