import { FunctionComponent } from 'react'
import styled from 'styled-components/macro'

interface Props {
  mb?: string
  mt?: string
  bgColor?: string
  height?: string
}

const VerticalLine: FunctionComponent<Props> = ({
  mb,
  mt,
  bgColor,
  height,
}) => {
  return <VerticalLineDiv mb={mb} mt={mt} bgColor={bgColor} height={height} />
}

const VerticalLineDiv = styled.div<{
  mb?: string
  mt?: string
  bgColor?: string
  height?: string
}>`
  width: 1px;
  height: 100%;
  background-color: ${({ theme }) => theme.grayBorder};
  ${({ mt }) => mt && `margin-top: ${mt};`}
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ bgColor }) => bgColor && `background-color: ${bgColor};`}
  ${({ height }) => height && `height: ${height};`}
`

export { VerticalLine }
