import styled from 'styled-components/macro'

export const NumberInputContainer = styled.div`
  width: 100%;
  margin-bottom: 25px;

  &.error {
    input {
      border: 1px solid #ff0000;
    }
  }
`

export const Label = styled.label<{ color?: string }>`
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
  white-space: nowrap;
  ${({ color }) => color && `color: ${color};`}
`

export const Input = styled.input<{ color?: string; bgColor?: string }>`
  box-sizing: border-box;
  width: 100%;
  height: 44px;
  padding: 20px 10px;
  font-weight: 400;
  font-size: 18px;
  line-height: 9px;
  letter-spacing: -0.025em;
  color: #000000;
  border: 1px solid #9c9c9c;
  ${({ bgColor }) => (bgColor ? `background-color: ${bgColor};` : '')}
  ${({ color }) => (color ? `color: ${color};` : '')}

  &:focus {
    outline: none;
  }
`

export const PostalCodeLink = styled.a<{ color?: string }>`
  display: flex;
  padding-top: 7px;
  color: #000000;
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.025em;
  ${({ color }) => color && `color: ${color};`}
`
