import { device } from 'constants/media-query'
import { FunctionComponent, useMemo } from 'react'
import { IconLink } from 'shared/components/IconButton'
import { useAppSelector } from 'store/store'
import styled from 'styled-components/macro'
import { FlexColumn, Heading1, Heading2 } from 'styles/generics'
import { theme } from 'styles/theme'
import shoppingBagSvg from 'assets/icons/shopping-bag.svg'
import ticketSvg from 'assets/icons/ticket.svg'
import { personalAreaTexts } from 'constants/personal-area-texts'
import creditCircleSvg from 'assets/icons/credit-circle.png'
import { useIsMobile } from 'shared/helpers/useIsMobile'
import { ImageWrapper } from 'shared/components/ImageWrapper'

const PersonalAreaSummary: FunctionComponent = () => {
  const user = useAppSelector((state) => state.user)
  const isFemale = useMemo(
    () => user.personalDetails?.gender === 'Female',
    [user],
  )
  const isMobile = useIsMobile()

  return (
    <PersonalAreaSummaryContainer>
      <FlexColumn width="100%">
        <WelcomeText weight={400} alignSelf="flex-start" color={theme.white}>
          {isFemale
            ? personalAreaTexts.header.welcomeFemale
            : personalAreaTexts.header.welcomeMale}
          <UserName>
            {user.personalDetails?.firstName || ''}{' '}
            {user.personalDetails?.lastName || ''}
          </UserName>
        </WelcomeText>
        <PersonalAreaDescription>
          {personalAreaTexts.header.personalAreaDescription}
        </PersonalAreaDescription>
        {isMobile && (
          <CreditContainer>
            <CreditAmount>{user?.creditAmount || 0}</CreditAmount>
            <ImageWrapper
              src={creditCircleSvg}
              alt="credit-circle"
              width="250px"
            />
          </CreditContainer>
        )}
        <LinksContainer>
          <IconLink
            target="_blank"
            href={process.env.REACT_APP_BASE_ROBOTICKET_URL}
            icon={isMobile ? undefined : ticketSvg}
            bgColor={theme.white}
            mobileWidth="85%"
          >
            {personalAreaTexts.header.ticketCreditCTA}
          </IconLink>
          <IconLink
            target="_blank"
            href={process.env.REACT_APP_SHOP_URL}
            icon={isMobile ? undefined : shoppingBagSvg}
            bgColor={'transparent'}
            borderColor={theme.white}
            color={theme.white}
            mobileWidth="85%"
          >
            {personalAreaTexts.header.officialStoreCreditCTA}
          </IconLink>
        </LinksContainer>
      </FlexColumn>
      {!isMobile && (
        <CreditContainer>
          <CreditAmount>{user?.creditAmount || 0}</CreditAmount>
          <CreditCircleDesktop src={creditCircleSvg} alt="credit-circle" />
        </CreditContainer>
      )}
    </PersonalAreaSummaryContainer>
  )
}

const PersonalAreaSummaryContainer = styled.div`
  @media ${device.mobileOnly} {
    padding-top: 40px;
  }

  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

const WelcomeText = styled(Heading1)`
  font-size: 32px;
  line-height: 32px;
  align-self: flex-start;
  color: ${({ theme }) => theme.white};

  @media ${device.desktop} {
    font-size: 48px;
    weight: 400;
    margin-bottom: 10px;
  }
`

const UserName = styled.span`
  display: block;
  font-family: 'Atlas-AAA-Bold';
  font-size: 52px;
  line-height: 51px;

  @media ${device.desktop} {
    line-height: 80px;
    font-size: 96px;
    margin-inline-start: -3px;
  }
`

const PersonalAreaDescription = styled(Heading2)`
  weight: 500;
  font-size: 24px;
  line-height: 37px;
  color: ${({ theme }) => theme.white};
  text-align: right;
  font-family: 'Atlas-AAA-Medium';

  @media ${device.desktop} {
    font-size: 32px;
    line-height: 55px;
    text-align: unset;
    align-self: flex-start;
    margin-bottom: 15px;
  }
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;
  align-items: center;
  @media ${device.desktop} {
    width: 380px;
    align-self: flex-start;
  }
`

const CreditContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;

  @media ${device.desktop} {
    margin-bottom: unset;
    width: unset;
    position: relative;
    width: 100%;
    max-width: 350px;
  }
`

const CreditAmount = styled.span`
  font-size: 64px;
  position: absolute;
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${({ theme }) => theme.white};
  direction: ltr;

  @media ${device.desktop} {
    font-size: 96px;
  }
`

const CreditCircleDesktop = styled.img`
  position: absolute;
  width: 100%;
`

export { PersonalAreaSummary }
