import { FunctionComponent } from 'react'
import { AppModal } from './modal/AppModal'
import { Button, Heading3, Text } from 'styles/generics'
import styled from 'styled-components/macro'
import { theme } from 'styles/theme'
import { EAppFontFamilies } from 'types/enums/EAppFontFamilies'
import { SignupTexts } from 'constants/signup-texts'
import { message } from 'antd'

interface Props {
  isModalOpen: boolean
}

const InAppBrowserPopUp: FunctionComponent<Props> = ({ isModalOpen }) => {
  const [messageApi, contextHolder] = message.useMessage()

  const success = () => {
    messageApi.open({
      type: 'success',
      content: 'Link copied to clipboard!',
    })
  }

  const copyUrlToClipBoard = (url: string | undefined) => {
    if (!url) return

    navigator.clipboard
      .writeText(url)
      .then(() => {
        console.log('URL copied to clipboard')
        success()
      })
      .catch((error) => {
        console.error('Failed to copy URL to clipboard', error)
      })
  }

  return (
    <AppModal isModalOpen={isModalOpen}>
      {contextHolder}
      <ModalHeading fontFamily={EAppFontFamilies.ATLAS_REGULAR}>
        {SignupTexts.InAppBrowserModal.Title}
      </ModalHeading>
      <Text>
        {SignupTexts.InAppBrowserModal.Description1}
        <br /> {SignupTexts.InAppBrowserModal.Description2}
      </Text>
      <ButtonsContainer>
        <CopyUrlButton
          onClick={() =>
            copyUrlToClipBoard(process.env.REACT_APP_BASE_ROBOTICKET_URL)
          }
        >
          {SignupTexts.InAppBrowserModal.Tickets}
        </CopyUrlButton>
        <CopyUrlButton
          onClick={() => copyUrlToClipBoard(process.env.REACT_APP_SHOP_URL)}
        >
          {SignupTexts.InAppBrowserModal.Shop}
        </CopyUrlButton>
        <CopyUrlButton
          onClick={() =>
            copyUrlToClipBoard(process.env.REACT_APP_BASE_OFFICIAL_WEBSITE_URL)
          }
        >
          {SignupTexts.InAppBrowserModal.OfficialWebsite}
        </CopyUrlButton>
      </ButtonsContainer>
    </AppModal>
  )
}

const ModalHeading = styled(Heading3)`
  margin-bottom: 10px;
`

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`

const CopyUrlButton = styled(Button)`
  background: ${theme.primaryGreen};
  color: ${theme.white};
  border-radius: 5px;
  padding: 10px 15px;
`

export { InAppBrowserPopUp }
