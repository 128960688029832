import React, { useEffect, useState } from 'react'
import { PasswordInput } from '../../../../../../shared/components/password-input/PasswordInput'
import { PasswordConfirmInput } from '../../../../../../shared/components/password-confirm-input/PasswordConfirmInput'
import { ESignupFormSteps } from '../../../../../../types/enums/ESignupFormSteps'
import {
  BackButtonWrapper,
  ButtonWrapper,
  PasswordInstructionItem,
  PasswordInstructions,
  PasswordInstructionsContainer,
  PasswordInstructionsTitle,
  UserExistsError,
} from './style'
import { signUp } from '../../../../../../services/AuthService'
import { useAppDispatch, useAppSelector } from '../../../../../../store/store'
import { updateUser } from '../../../../../../store/reducers/userReducer'
import { useNavigate } from 'react-router-dom'
import {
  ErrorTexts,
  SignupTexts,
} from '../../../../../../constants/signup-texts'
import { Loader } from '../../../../../../shared/components/loader/loader'
import { EAppRoutes } from 'types/enums/EAppRoutes'

export const SignupFormPassword: React.FC<any> = ({
  setSignupFormTexts,
  onFinishStep,
}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user)
  const [signupFormPasswordData, setSignupFormPasswordData] = useState<{
    password: string
    passwordConfirm: string
  }>({ password: '', passwordConfirm: '' })
  const [errors, setErrors] = useState<{
    password?: boolean
    passwordConfirm?: boolean
  }>({
    password: false,
    passwordConfirm: false,
  })
  const [isDisabled, setIsDisabled] = useState<boolean>(true)
  const [showUserExistsError, setShowUserExistsError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setSignupFormTexts({
      title: SignupTexts.CreatePassword,
      subtitle: SignupTexts.PasswordIsUniqueMessage,
    })
  }, [])

  useEffect(() => {
    if (
      signupFormPasswordData.password &&
      signupFormPasswordData.passwordConfirm &&
      !errors.password &&
      !errors.passwordConfirm
    ) {
      setIsDisabled(false)
    } else if (!isDisabled) {
      setIsDisabled(true)
    }
  }, [signupFormPasswordData])

  const handlePasswordChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, password: error })
    setSignupFormPasswordData({ ...signupFormPasswordData, password: value })

    const confirmPasswordError = !(
      (value === signupFormPasswordData.passwordConfirm &&
        signupFormPasswordData.passwordConfirm !== '') ||
      (value !== signupFormPasswordData.passwordConfirm &&
        (signupFormPasswordData.passwordConfirm === '' ||
          signupFormPasswordData.password === ''))
    )
    setErrors({ ...errors, passwordConfirm: confirmPasswordError })
  }

  const handlePasswordConfirmChange = (value: any, error?: boolean) => {
    setErrors({ ...errors, passwordConfirm: error })
    setSignupFormPasswordData({
      ...signupFormPasswordData,
      passwordConfirm: value,
    })
  }

  const handlePasswordSubmit = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    // cognito
    if (user.email) {
      const signedUser = await signUp(
        user.email.toLowerCase(),
        signupFormPasswordData.password,
      )
      if (!signedUser) {
        setErrors({ ...errors, password: true })
        setIsLoading(false)
        return
      }
      if ('error' in signedUser) {
        setShowUserExistsError(true)
        setIsLoading(false)
        return
      }
      setShowUserExistsError(false)
      dispatch(
        updateUser({
          ...user,
          password: signupFormPasswordData.password,
          registeredCognito: true,
        }),
      )
      onFinishStep(ESignupFormSteps.emailConfirm)
      setIsLoading(false)
    }
    setIsLoading(false)
    return
  }

  return isLoading ? (
    <Loader></Loader>
  ) : (
    <>
      <PasswordInstructionsContainer>
        <PasswordInstructionsTitle>
          {SignupTexts.SignupFormPasswordInstructionsTitle}
        </PasswordInstructionsTitle>
        <PasswordInstructions>
          <PasswordInstructionItem>
            {SignupTexts.SignupFormPasswordInstructionFirstRule}
          </PasswordInstructionItem>
          <PasswordInstructionItem>
            {SignupTexts.SignupFormPasswordInstructionFifthRule}
          </PasswordInstructionItem>
          <PasswordInstructionItem>
            {SignupTexts.SignupFormPasswordInstructionSecondRule}
          </PasswordInstructionItem>
          <PasswordInstructionItem>
            {SignupTexts.SignupFormPasswordInstructionThirdRule}
          </PasswordInstructionItem>
          <PasswordInstructionItem>
            {SignupTexts.SignupFormPasswordInstructionFourthRule}
          </PasswordInstructionItem>
        </PasswordInstructions>
      </PasswordInstructionsContainer>
      <PasswordInput
        hasError={errors.password}
        errorText={ErrorTexts.PasswordIsNotValid}
        callback={handlePasswordChange}
      ></PasswordInput>
      <PasswordConfirmInput
        hasError={errors.passwordConfirm}
        errorText={ErrorTexts.PasswordIsNotMatch}
        password={signupFormPasswordData.password}
        callback={handlePasswordConfirmChange}
      ></PasswordConfirmInput>
      <UserExistsError className={showUserExistsError ? 'error' : ''}>
        {ErrorTexts.EmailIsAlreadyInSystem}
      </UserExistsError>
      <ButtonWrapper
        type="submit"
        disabled={isDisabled}
        className={`buttonWrapper ${isDisabled ? 'disabled' : ''}`}
        text={SignupTexts.Next}
        callback={handlePasswordSubmit}
      />
      {showUserExistsError && (
        <BackButtonWrapper
          disabled={false}
          className={`buttonWrapper ${isDisabled ? 'disabled' : ''}`}
          text={SignupTexts.ToLogIn}
          callback={() => {
            navigate(EAppRoutes.LOGIN)
          }}
        />
      )}
    </>
  )
}
