/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ChangeEvent } from 'react'
import { Error } from '../error/Error'
import { IInputProps } from '../../../types/interfaces/IInputProps'
import { IdInputContainer, Input, Label } from './style'
import { ErrorTexts, SignupTexts } from '../../../constants/signup-texts'
import { isIsraeliIdNumber } from '../../helpers/regex'
import { HeightPlaceHolderForError } from '../email-confirm-input/style'

export const IdInput: React.FC<IInputProps> = ({
  callback,
  hasError,
  errorText,
  className,
  value,
  disabled,
  color,
  bgColor,
  disabledOpacityStyle,
  userExistsError,
}) => {
  const handleChange = (e: any) => {
    e.preventDefault()
    const error = !isIsraeliIdNumber(e.target.value)
    callback(e.target.value, error)
  }

  return (
    <IdInputContainer className={className ?? 'id'}>
      <Label htmlFor="id" color={color}>
        {SignupTexts.MisparZehutNumber}
      </Label>
      <Input
        bgColor={bgColor}
        title={disabled ? 'לא ניתן לערוך שדה זה' : ''}
        disabled={disabled ?? false}
        value={value}
        type="text"
        name="id"
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
        color={color}
        disabledOpacityStyle={disabledOpacityStyle}
      ></Input>
      <HeightPlaceHolderForError>
        {userExistsError ? (
          <Error hasError={true} errorText={ErrorTexts.IdIsAlreadyInSystem} />
        ) : (
          <Error hasError={hasError} errorText={errorText} />
        )}
      </HeightPlaceHolderForError>
    </IdInputContainer>
  )
}
