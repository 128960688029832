import { device } from 'constants/media-query'
import { FunctionComponent } from 'react'
import styled from 'styled-components/macro'
import { Image } from 'styles/generics'

interface Props {
  src: string
  alt: string
  desktopWidth?: string
  width?: string
}

const ImageWrapper: FunctionComponent<Props> = ({
  width,
  desktopWidth,
  alt,
  src,
}) => {
  return (
    <ImageContainer width={width} desktopWidth={desktopWidth}>
      <Image src={src} alt={alt} />
    </ImageContainer>
  )
}

const ImageContainer = styled.div<{ width?: string; desktopWidth?: string }>`
  ${({ width }) => width && `width: ${width};`}
  display: flex;
  justify-content: center;
  align-items: center;

  @media ${device.desktop} {
    ${({ desktopWidth }) => desktopWidth && `width: ${desktopWidth};`}
  }
`

export { ImageWrapper }
