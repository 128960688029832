/* eslint-disable @typescript-eslint/no-explicit-any */
import { IAddress } from 'types/interfaces/IAddress'
import { IPersonalDetails } from 'types/interfaces/IPersonalDetails'
import { ISFUser } from 'types/interfaces/ISFUser'

const updateOrAddAddress = (
  formData: IPersonalDetails,
  selectedAddress: IAddress,
  field: string,
  value: any,
) => {
  if (selectedAddress.id === undefined) {
    const existingAddressIndex = formData.addresses?.findIndex(
      (address) => address.id === undefined,
    )
    if (existingAddressIndex !== -1 && existingAddressIndex !== undefined) {
      return formData.addresses?.map((address, index) => {
        if (index === existingAddressIndex) {
          return { ...address, [field]: value }
        }
        return address
      })
    } else {
      return formData.addresses
        ? [...formData.addresses, { ...selectedAddress, [field]: value }]
        : [{ ...selectedAddress, [field]: value }]
    }
  } else {
    return formData.addresses?.map((address) => {
      if (address.id === selectedAddress.id) {
        return { ...address, [field]: value }
      }
      return address
    })
  }
}

const isUserMissingDetails = (user: ISFUser | undefined) => {
  return (
    !user?.personalDetails?.id ||
    user?.personalDetails?.id?.length !== 9 ||
    !user?.personalDetails?.addresses?.length ||
    !user?.personalDetails?.addresses?.[0].city ||
    !user?.personalDetails?.addresses?.[0].street ||
    !user?.personalDetails?.addresses?.[0].postalCode ||
    !user?.personalDetails?.addresses?.[0].buildingNumber
  )
}

export const userService = { updateOrAddAddress, isUserMissingDetails }
