import { FunctionComponent } from 'react'
import profileIcon from 'assets/icons/logged-in-profile.svg'
import profileIconCircle from 'assets/icons/logged-in-profile-circle.svg'
import { Image } from 'styles/generics'
import styled from 'styled-components/macro'
import { device } from 'constants/media-query'

interface Props {
  userInitials: string
}

const ProfileIcon: FunctionComponent<Props> = ({ userInitials }) => {
  return (
    <ProfileIconContainer>
      <Image src={profileIcon} width="100%" />
      <IconCircleContainer>
        <Image src={profileIconCircle} width="100%" />
        <UserInitialsText>{userInitials}</UserInitialsText>
      </IconCircleContainer>
    </ProfileIconContainer>
  )
}

const ProfileIconContainer = styled.div`
  position: relative;
  width: 20px;

  @media ${device.desktop} {
    width: 30px;
  }
`

const IconCircleContainer = styled.div`
  position: absolute;
  right: -7px;
  bottom: -8px;
  width: 15px;

  @media ${device.desktop} {
    width: 19px;
    bottom: -11px;
    right: -10px;
  }
`

const UserInitialsText = styled.span`
  position: absolute;
  top: 40%;
  left: 53%;
  transform: translate(-50%, -50%);
  font-size: 9px;
  line-height: normal;
  letter-spacing: 0.7px;
  font-weight: 400;
  color: ${({ theme }) => theme.white};
`

export { ProfileIcon }
