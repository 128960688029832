import React from 'react'
import './App.css'
import { AppRouter } from './router'
import { store } from './store/store'
import { Provider } from 'react-redux'
import * as Amplify from 'aws-amplify'
import { AwsConfig } from './constants/aws-exports'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import heIL from 'antd/lib/locale/he_IL'
import { ThemeProvider } from 'styled-components'
import { theme } from 'styles/theme'

Amplify.Amplify.configure(AwsConfig)

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider direction="rtl" locale={heIL}>
        <Provider store={store}>
          <BrowserRouter>
            <AppRouter />
          </BrowserRouter>
        </Provider>
      </ConfigProvider>
    </ThemeProvider>
  )
}

export default App
